import { Chip } from '@mui/material'
import {
  RiFacebookFill,
  RiLinkedinFill,
  RiLinksFill,
  RiTwitterFill,
  RiYoutubeFill,
} from '@remixicon/react'

import { socialIconStyle } from 'src/components/Sidebar/Sidebar'

import Row from '../../Row/Row'

/*
type CoreCompany {
    id: String!
    name: String
    domain: String!
    description: String
    aiDescription: String
    promises: [String]
    industry: String
    employeeCount: Int
    annualRevenue: Float
    funding: Float
    address: String
    city: String
    state: String
    country: String
    postalCode: String
    colorVibrant: String
    colorDarkVibrant: String
    colorLightVibrant: String
    colorMuted: String
    colorDarkMuted: String
    colorLightMuted: String
    photoSquare: String
    photoIcon: String
    photoBanner: String
    stockTicker: String
    socialTwitter: String
    socialLinkedIn: String
    socialFacebook: String
    createdAt: DateTime!
    updatedAt: DateTime!
    edgarCik: String
    crunchbaseEntityId: String
    similarDomains: JSON
  }
  */

const OrganizationSocialLinks = ({ coreCompany, iconSize = 14 }) => {
  return (
    <Row gap={1}>
      <Chip
        icon={<RiLinksFill size={iconSize} />}
        variant="filled"
        onClick={(e) => {
          const url =
            coreCompany.resolvedUrl &&
            coreCompany.resolvedUrl.startsWith('http:')
              ? coreCompany.resolvedUrl
              : `https://${coreCompany.domain}`
          e.stopPropagation()
          window.open(url, '_blank')
        }}
        sx={{ ...socialIconStyle, backgroundColor: 'secondary.main' }}
      />
      {coreCompany?.socialTwitter && (
        <Chip
          icon={<RiTwitterFill size={iconSize} />}
          onClick={(e) => {
            e.stopPropagation()

            const url = coreCompany.socialTwitter.startsWith('http:')
              ? coreCompany.socialTwitter
              : `https://x.com/${coreCompany.socialTwitter}`
            window.open(url, '_blank')
          }}
          sx={{ ...socialIconStyle, backgroundColor: '#1DA1F2' }}
        />
      )}

      {coreCompany?.socialLinkedIn && (
        <Chip
          icon={<RiLinkedinFill size={iconSize} />}
          onClick={(e) => {
            e.stopPropagation()
            window.open(coreCompany.socialLinkedIn, '_blank')
          }}
          sx={{ ...socialIconStyle, backgroundColor: '#2867B2' }}
        />
      )}

      {coreCompany?.socialYouTube && (
        <Chip
          icon={<RiYoutubeFill size={iconSize} />}
          onClick={(e) => {
            e.stopPropagation()
            window.open(coreCompany.socialYouTube, '_blank')
          }}
          sx={{ ...socialIconStyle, backgroundColor: '#FF0000' }}
        />
      )}
      {coreCompany?.socialFacebook && (
        <Chip
          icon={<RiFacebookFill size={iconSize} />}
          onClick={(e) => {
            e.stopPropagation()
            window.open(coreCompany.socialFacebook, '_blank')
          }}
          sx={{ ...socialIconStyle, backgroundColor: '#1877F2' }}
        />
      )}
    </Row>
  )
}

export default OrganizationSocialLinks
