import { memo, useState, useEffect, useContext, useMemo } from 'react'

import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  RiContactsBook2Line,
  RiUserLine,
  RiVerifiedBadgeFill,
  RiVerifiedBadgeLine,
  RiWindow2Line,
} from '@remixicon/react'
import gql from 'graphql-tag'
import toast from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { isEmailCalendar } from 'src/lib/contactFormatting'
import {
  getCoreContactVerificationStates,
  getCoreContactVerificationState,
} from 'src/lib/coreContacts'
import { DayContext } from 'src/lib/dayContext'
import { getCompanyLogoUrl } from 'src/lib/formatters'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import LinkedInFinder from '../LinkedIn/LinkedInAccount/LinkedInFinder/LinkedInFinder'
import Row from '../Row/Row'
import ContactChip from '../Sidebar/ContactChip/ContactChip'

function emailToDefaultPhoto(str) {
  return `${process.env.HOST}/people/profile_blank.png`
}

function emailToBackgroundColor(str) {
  if (str && str.length === 0) return '#f0f0f0'
  const firstChar = str ? str.charAt(3) : 'M'
  const charCode = firstChar.charCodeAt(0)
  const colors = [
    '#5AB298',
    '#F9A826',
    '#F26D85',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
  ]
  return colors[charCode % colors.length]
}

const GET_CONTACT_BY_EMAIL_FOR_AVATAR = gql`
  query GetCoreContactByEmailForAvatar($email: String!) {
    coreContact(email: $email) {
      id
      photo
      firstName
      lastName
      companyName
      flaggedBy
      manuallyVerified
      timezone
      linkedInUrl
      title
    }
  }
`

const GET_CORE_COMPANY_FOR_CONTACT_AVATAR = gql`
  query GetCoreCompanyForContactAvatar($domain: String!) {
    coreCompany(domain: $domain) {
      id
      name
      domain
      photoSquare
    }
  }
`

const FLAG_CORE_CONTACT_FROM_AVATAR = gql`
  mutation flagCoreContactFromAvatar($email: String!, $flaggedBy: String!) {
    flagCoreContact(email: $email, flaggedBy: $flaggedBy) {
      flaggedBy
    }
  }
`

const UPDATE_CORE_CONTACT_MUTATION_FROM_AVATAR = gql`
  mutation UpdateCoreContactMutationFromAvatar(
    $email: String!
    $input: UpdateCoreContactInput!
  ) {
    updateCoreContact(email: $email, input: $input) {
      id
      email
      firstName
      lastName
      title
      domain
      industry
      photo
      department
      description
      unsubscribed
      lastSeen
      emailDead
      updatedAt
      createdAt
      manuallyVerified
      flaggedBy
    }
  }
`

const ENRICH_CORE_CONTACT_FROM_AVATAR = gql`
  mutation enrichCoreContactFromAvatar($email: String!) {
    enrichCoreContact(email: $email) {
      email
      firstName
      lastName
      photo
      title
      domain
      industry
      department
      description
      unsubscribed
      lastSeen
      emailDead
      linkedInUrl
      twitterUrl
      facebookUrl
      githubUrl
      personalEmail
      summary
      companyName
    }
  }
`

const ContactAvatar = memo(
  ({
    email,
    size,
    showCompany = true,
    border,
    borderRadius = 100,
    style,
    flaggedBy = [],
    showVerification = false,
    showSidebar = false,
  }: {
    email?: string
    size?: number
    showCompany?: boolean
    border?: number
    borderRadius?: number
    style?: React.CSSProperties
    manuallyVerified?: boolean
    flaggedBy?: string[]
    isUser?: boolean
    showVerification?: boolean
    showSidebar?: boolean
  }) => {
    const theme = useTheme()
    const { people, refetchPeople } = useContext(DayContext)

    const { data, error, loading } = useQuery(GET_CONTACT_BY_EMAIL_FOR_AVATAR, {
      variables: { email },
      skip: !email || email === 'assistant@day.ai',
    })

    const personObject = useMemo(() => {
      let person =
        people.find((p) => p.objectId === email?.toLowerCase().trim()) || {}
      if (!person?.objectId && data)
        person = {
          properties: { ...data },
          objectType: NativeObjectTypes.Contact,
          objectId: email,
        }

      return person
    }, [people, email, data])

    let photoUrl = personObject?.properties?.photo

    const verificationStates = getCoreContactVerificationStates({ theme })

    const { currentUser } = useAuth()
    const { setSidebarObject } = useContext(DayContext)
    const [verifyOpen, setVerifyOpen] = useState(false)
    const [verificationState, setVerificationState] = useState(
      email === 'assistant@day.ai' ? verificationStates.IS_DAY_AI : null
    )
    const [activeReviewStep, setActiveReviewStep] = useState(0)
    const [coreContactNames, setCoreContactNames] = useState({
      firstName: '',
      lastName: '',
      companyName: '',
    })

    const [updateCoreContact] = useMutation(
      UPDATE_CORE_CONTACT_MUTATION_FROM_AVATAR,
      {
        onCompleted: (_) => {
          refetchPeople()
        },
        onError: (error) => {
          toast.error(error.message)
        },
      }
    )

    const verifyContact = async () => {
      const input = {
        manuallyVerified: new Date().toISOString(),
        flaggedBy: [],
        manualInterventionWasRequired: true,
        verifiedBy: currentUser?.email,
        verificationNotes: 'Verified by user in webapp',
      }
      await updateCoreContact({ variables: { email, input } })
      setVerifyOpen(false)
    }

    const domain = extractEmailDomain(email)
    const { data: companyData } = useQuery(
      GET_CORE_COMPANY_FOR_CONTACT_AVATAR,
      {
        variables: { domain },
        skip:
          !domain || isEmailCalendar(email) || (!verifyOpen && !showCompany),
      }
    )

    useEffect(() => {
      const state = getCoreContactVerificationState({
        coreContact: data?.coreContact,
        crmObject: personObject,
        verificationStates,
      })

      setVerificationState(state)
      if (data || personObject || companyData) {
        setCoreContactNames({
          firstName:
            personObject?.properties?.firstName || data?.coreContact?.firstName,
          lastName:
            personObject?.properties?.lastName || data?.coreContact?.lastName,
          companyName:
            companyData?.coreCompany?.name ||
            personObject?.properties?.companyName ||
            data?.coreContact?.companyName,
        })
      }
    }, [data, personObject, companyData])

    const [flagCoreContact] = useMutation(FLAG_CORE_CONTACT_FROM_AVATAR, {
      variables: {
        email,
        flaggedBy: currentUser?.email,
      },
      onCompleted: (_) => {
        refetchPeople()
      },
    })

    const [enrichCoreContact] = useMutation(ENRICH_CORE_CONTACT_FROM_AVATAR, {
      variables: { email },
    })

    const handleNameUpdate = async () => {
      await updateCoreContact({ variables: { email, input: coreContactNames } })
      setActiveReviewStep(1)
    }

    const handleChooseLinkedIn = async (url) => {
      const input = {
        linkedInUrl: url,
        photo: null,
        photoSource: null,
        facebookUrl: null,
        twitterUrl: null,
        githubUrl: null,
        personalEmail: null,
        summary: null,
        title: null,
        description: null,
        department: null,
      }

      await updateCoreContact({ variables: { email, input } })

      await toast.promise(enrichCoreContact({ variables: { email } }), {
        loading: 'Researching person... this can take a minute.',
        success: (result) =>
          `${
            result?.data?.enrichCoreContact?.firstName || email
          } researched successfully`,
        error: 'Failed to enrich contact',
      })

      await refetchPeople()

      setActiveReviewStep(2)
    }

    const fontSize: string = size ? `${Math.floor(0.4 * size)}px` : '48px'
    const isCalendar: boolean = isEmailCalendar(email)
    if (!border) border = 0

    const fallbackPhoto = emailToDefaultPhoto(email)

    // Hardcode assistant photo
    if (email === 'assistant@day.ai')
      photoUrl = `${process.env.HOST}/logos/Day 1.png`

    // Use coreContact photo
    if (data && !error && !loading && !photoUrl) {
      photoUrl = data?.coreContact?.photo
    }

    // Use company photo
    if (!photoUrl && showCompany && !isCalendar && !loading) {
      photoUrl = getCompanyLogoUrl({
        domain: extractEmailDomain(email),
        photoUrl: companyData?.coreCompany?.photoSquare,
      })
    }

    // End with fallback
    if (!photoUrl) {
      photoUrl = fallbackPhoto
    }

    const finalBorderRadius = borderRadius.toString() + 'px'

    const handleFlagContact = () => {
      toast.promise(flagCoreContact(), {
        loading: 'Flagging contact...',
        success: () => {
          try {
            setVerificationState(verificationStates.FLAGGED)
          } catch (e) {
            logger.error('Error flagging person', e)
          }
          return 'Contact flagged successfully'
        },
        error: 'Failed to flag contact',
      })
    }

    const readyToDisplay =
      email && (!showVerification || verificationState) && photoUrl

    return readyToDisplay ? (
      <Box
        className="personAvatarBox"
        sx={{
          cursor: showSidebar || showVerification ? 'pointer' : 'default',
          position: 'relative',
          height: `${size}px`,
          width: `${size}px`,
        }}
        onClick={(e) => {
          if (showVerification) {
            e.stopPropagation()
            if (
              (data?.coreContact?.linkedInUrl ||
                personObject?.properties?.linkedInUrl) &&
              !verifyOpen
            )
              setActiveReviewStep(2)
            setVerifyOpen(true)
          } else if (showSidebar && (personObject || data)) {
            e.stopPropagation()
            setSidebarObject(personObject)
          }
        }}
      >
        <Tooltip
          title={showVerification ? verificationState?.tooltipText : null}
          placement="top"
          arrow={true}
        >
          <Box>
            <Avatar
              src={photoUrl}
              sx={{
                height: `${size}px`,
                width: `${size}px`,
                background: emailToBackgroundColor(email),
                border: (theme) =>
                  `${border}px solid ${theme.palette.background.paper} !important`,
                color: (theme) => theme.palette.primary.contrastText,
                fontSize,
                objectFit: 'cover',
                fontWeight: 600,
                borderRadius: finalBorderRadius,
                textDecoration: 'none',
                '&:not(a)': {
                  textDecoration: 'none',
                },
                '& svg': {
                  height: `${size / 2}px`,
                  width: `${size / 2}px`,
                },
                '& .MuiAvatar-img': {
                  opacity: showVerification ? verificationState?.opacity : 1.0,
                },
                '& .MuiAvatar-fallback': {
                  display: 'none',
                },
                ...style,
              }}
            />

            <Box
              sx={{
                display:
                  size > 24 &&
                  showVerification &&
                  (verificationState?.verifiable || borderRadius < 10)
                    ? 'flex'
                    : 'none',
                opacity: loading ? 0 : 1,
                transition: 'all 0.4s ease-in-out',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                top: `-${size / (verificationState?.verifiable ? 1 : 3)}px`,
                zIndex: 100,
                height: `${size / (verificationState?.verifiable ? 1 : 3)}px`,
                width: `${size / (verificationState?.verifiable ? 1 : 3)}px`,
                '& .remixicon': {
                  height: `${size / (verificationState?.verifiable ? 2 : 4)}px`,
                  width: `${size / (verificationState?.verifiable ? 2 : 4)}px`,
                  color: (theme) => theme.palette.background.paper,
                },
              }}
            >
              {verificationState?.icon}
            </Box>
          </Box>
        </Tooltip>
        {showVerification && (
          <Dialog
            open={verifyOpen}
            onClose={(e: React.MouseEvent) => {
              e.stopPropagation()
              setVerifyOpen(false)
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Row>
                <RiUserLine style={{ height: '22px', marginRight: '8px' }} />
                <span>{`${email}`}</span>
              </Row>
              <Alert
                severity={verificationState?.alertSeverity as AlertColor}
                icon={verificationState?.alertIcon}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette[verificationState?.alertSeverity].dark,

                  px: 1,
                  py: 0,
                  fontSize: '0.7rem',
                  '& .MuiAlert-message': { p: 0 },
                  '& .MuiAlert-icon': {
                    mr: '6px',
                  },
                  '& .remixicon': {
                    height: '14px',
                    width: '14px',
                    color: theme.palette[verificationState?.alertSeverity].main,
                  },
                }}
              >
                {verificationState?.label}
              </Alert>
            </DialogTitle>
            <Divider />
            {verificationState?.verifiable && (
              <Box sx={{ px: 3, pt: 3 }}>
                <Stepper
                  activeStep={activeReviewStep}
                  sx={{
                    '& .MuiStep-root': {
                      py: '4px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      color: theme.palette.action.disabled,
                      '& .remixicon': {
                        color: theme.palette.action.disabled,
                      },

                      '& .MuiStepLabel-iconContainer.Mui-active .remixicon, .MuiStepLabel-iconContainer.Mui-completed .remixicon':
                        {
                          color: theme.palette.text.primary,
                        },
                      '&.MuiStep-completed': {
                        color: theme.palette.text.primary,
                      },
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    },

                    '& .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active .MuiStepConnector-line.MuiStepConnector-lineHorizontal, .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed  .MuiStepConnector-line.MuiStepConnector-lineHorizontal':
                      {
                        borderColor: theme.palette.text.primary,
                        borderWidth: '2px',
                      },
                  }}
                >
                  <Step
                    key={0}
                    onClick={(e) => {
                      e.stopPropagation()
                      setActiveReviewStep(0)
                    }}
                  >
                    <StepLabel StepIconComponent={RiContactsBook2Line}>
                      Name
                    </StepLabel>
                  </Step>
                  <Step
                    key={1}
                    onClick={(e) => {
                      e.stopPropagation()
                      setActiveReviewStep(1)
                    }}
                  >
                    <StepLabel StepIconComponent={RiWindow2Line}>
                      On the web
                    </StepLabel>
                  </Step>
                  <Step
                    key={2}
                    onClick={(e) => {
                      e.stopPropagation()
                      setActiveReviewStep(2)
                    }}
                  >
                    <StepLabel StepIconComponent={RiVerifiedBadgeLine}>
                      Review & Confirm
                    </StepLabel>
                  </Step>
                </Stepper>
              </Box>
            )}
            <DialogContent sx={{ width: '600px', pt: 0 }}>
              {verificationState?.verifiable ? (
                <>
                  {activeReviewStep === 0 && (
                    <Row sx={{ height: '360px', alignItems: 'center' }}>
                      <Box sx={{ height: '260px', width: '100%' }}>
                        <TextField
                          label="First Name"
                          variant="standard"
                          fullWidth={true}
                          value={coreContactNames.firstName}
                          onChange={(e) =>
                            setCoreContactNames((prev) => {
                              return {
                                ...prev,
                                firstName: e.target.value,
                              }
                            })
                          }
                          sx={{ mb: 6 }}
                        />
                        <TextField
                          label="Last Name"
                          variant="standard"
                          fullWidth={true}
                          value={coreContactNames.lastName}
                          onChange={(e) =>
                            setCoreContactNames((prev) => {
                              return {
                                ...prev,
                                lastName: e.target.value,
                              }
                            })
                          }
                          sx={{ mb: 6 }}
                        />
                        <TextField
                          label="Company Name"
                          variant="standard"
                          fullWidth={true}
                          value={coreContactNames.companyName}
                          onChange={(e) =>
                            setCoreContactNames((prev) => {
                              return {
                                ...prev,
                                companyName: e.target.value,
                              }
                            })
                          }
                          sx={{ mb: 6 }}
                        />
                      </Box>
                    </Row>
                  )}
                  {activeReviewStep === 1 && (
                    <LinkedInFinder
                      email={email}
                      setter={handleChooseLinkedIn}
                      linkedInUrl={
                        data?.coreContact?.linkedInUrl ||
                        personObject?.properties?.linkedInUrl
                      }
                    />
                  )}
                  {activeReviewStep === 2 && (
                    <Row sx={{ height: '360px', justifyContent: 'center' }}>
                      {data?.coreContact?.linkedInUrl ||
                      personObject?.properties?.linkedInUrl ? (
                        <Box sx={{ width: '80%' }}>
                          <Card sx={{ width: '100%', borderRadius: '6px' }}>
                            <ContactChip
                              inSidebar={false}
                              rawPhoto={true}
                              contactObject={{
                                objectType: 'native_contact',
                                objectId: email,
                                properties: {
                                  ...data?.coreContact,
                                  ...personObject?.properties,
                                },
                              }}
                            />
                          </Card>
                        </Box>
                      ) : (
                        <>
                          <Typography>
                            Please verify this person's identity on the web to
                            verify their contact & career info.
                          </Typography>
                        </>
                      )}
                    </Row>
                  )}
                </>
              ) : (
                <>
                  <Alert
                    severity={verificationState?.alertSeverity}
                    icon={verificationState?.alertIcon}
                    sx={{ mt: 4, mb: 1 }}
                  >
                    {verificationState?.explanation}
                  </Alert>
                </>
              )}
            </DialogContent>
            {verificationState?.verifiable ? (
              <DialogActions sx={{ p: 3, pt: 0 }}>
                {activeReviewStep === 0 && (
                  <>
                    <Button
                      fullWidth={true}
                      onClick={handleNameUpdate}
                      variant="outlined"
                    >
                      This looks correct
                    </Button>
                  </>
                )}
                {activeReviewStep === 1 && <></>}
                {activeReviewStep === 2 && (
                  <>
                    {data?.coreContact?.linkedInUrl ||
                    personObject?.properties?.linkedInUrl ? (
                      <>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          onClick={verifyContact}
                          startIcon={<RiVerifiedBadgeFill />}
                        >
                          Verify {coreContactNames.firstName}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          fullWidth={true}
                          onClick={() => setActiveReviewStep(1)}
                        >
                          Search on web
                        </Button>
                      </>
                    )}
                  </>
                )}
              </DialogActions>
            ) : (
              <DialogActions sx={{ p: 3, pt: 0 }}>
                {!flaggedBy.includes(currentUser?.email as string) && (
                  <>
                    <Button
                      variant="outlined"
                      fullWidth={true}
                      onClick={handleFlagContact}
                    >
                      This person's information is incorrect
                    </Button>
                  </>
                )}
              </DialogActions>
            )}
          </Dialog>
        )}
      </Box>
    ) : (
      <Avatar
        sx={{
          height: `${size}px`,
          width: `${size}px`,
          borderRadius: finalBorderRadius,
          backgroundColor: (theme) => theme.palette.background.paper,
          overflow: 'hidden',
        }}
      />
    )
  }
)

export default ContactAvatar
