import { useState } from 'react'

const FIND_LINKED_IN_ACCOUNT = gql`
  query findLinkedInAccount($email: String!) {
    findLinkedInAccount(email: $email) {
      firstName
      lastName
      companyName
      query
      results {
        title
        link
        displayedLink
        description
        photo
      }
    }
  }
`

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  RiGoogleLine,
  RiLinkedinBoxLine,
  RiLinkedinLine,
} from '@remixicon/react'

import { useQuery } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'

const LinkedInFinder = ({
  email,
  setter,
  linkedInUrl = null,
  showExpand = false,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [linkedInUrlManual, setLinkedInUrlManual] = useState(linkedInUrl || '')
  const [forceManual, setForceManual] = useState(false)
  const { data, loading } = useQuery(FIND_LINKED_IN_ACCOUNT, {
    variables: { email },
  })

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: '360px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={22} />
          <Typography
            sx={{
              ml: 3,
              fontWeight: 500,
              fontSize: '1.0rem',
              letterSpacing: '-0.6px',
            }}
          >
            Finding possible matches on the web ...
          </Typography>
        </Box>
      ) : (
        <>
          {data?.findLinkedInAccount?.results?.length > 0 && !forceManual ? (
            <Box sx={{ height: expanded ? '100%' : '360px' }}>
              <Box
                sx={{
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '42px',
                    backgroundImage:
                      'linear-gradient(transparent, rgba(255, 255, 255, 1))',
                    pointerEvents: 'none',
                    zIndex: 1,
                  },
                }}
              >
                <List
                  sx={{
                    height: expanded ? '100%' : '360px',
                    overflowY: 'auto',
                  }}
                >
                  {process.env.HOST.includes('localhost') && (
                    <ListItemButton
                      onClick={() => {
                        window.open(
                          `https://www.google.com/search?q=${data?.findLinkedInAccount?.query}`,
                          '_blank'
                        )
                      }}
                    >
                      <ListItemText
                        primary={data?.findLinkedInAccount?.query}
                      />
                    </ListItemButton>
                  )}
                  <ListItemButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setForceManual(true)
                    }}
                    sx={{
                      ml: 2,
                      mb: 2,
                      px: 3,
                      py: 2,
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      border: (theme) =>
                        `1px solid ${theme.palette.secondary.main}`,
                      borderRadius: '6px',
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    <RiLinkedinBoxLine
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                    Add manually
                  </ListItemButton>
                  {data?.findLinkedInAccount?.results?.map((result) => (
                    <Tooltip
                      key={result.link}
                      title={`${email} is ${result.title}`}
                      placement="left"
                      arrow={true}
                    >
                      <ListItemButton
                        onClick={() => setter(result.link)}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mb: 2,
                          justifyContent: 'left',
                        }}
                      >
                        <Row>
                          <Avatar
                            sx={{ width: 48, height: 48, mr: 1 }}
                            src={result?.photo}
                          />
                          <Box>
                            <Typography
                              sx={{
                                fontSize: '0.9rem',
                                fontWeight: 500,
                                width: '460px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {result.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '0.7rem',
                                width: '460px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {result.description}
                            </Typography>
                          </Box>
                        </Row>
                      </ListItemButton>
                    </Tooltip>
                  ))}
                </List>
              </Box>
              {showExpand && (
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth={true}
                  onClick={() => setExpanded(true)}
                >
                  See all
                </Button>
              )}
            </Box>
          ) : (
            <Box sx={{ m: 6 }}>
              <Typography
                sx={{
                  mt: 0,
                  mb: 3,
                  fontSize: '1.0rem',
                  fontWeight: 500,
                  textAlign: 'center',
                }}
              >
                {`Add their Linkedin Profile Url below.`}
              </Typography>

              <TextField
                label="LinkedIn URL"
                variant="standard"
                fullWidth={true}
                sx={{ mb: 3 }}
                value={linkedInUrlManual}
                onChange={(e) => setLinkedInUrlManual(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setter(linkedInUrlManual)
                  }
                }}
              />
              <Row
                sx={{ justifyContent: 'center' }}
                gap={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    window.open(
                      `https://www.google.com/search?q=${
                        data?.findLinkedInAccount.firstName || ''
                      }+${data?.findLinkedInAccount.lastName || ''}+${
                        data?.findLinkedInAccount.companyName || ''
                      }+linkedin`
                    )
                  }
                  startIcon={<RiGoogleLine size={18} />}
                >
                  Search Google
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    window.open(
                      `https://www.linkedin.com/search/results/all/?keywords=${
                        data?.findLinkedInAccount.firstName || ''
                      }%20${data?.findLinkedInAccount.lastName || ''}%20${
                        data?.findLinkedInAccount.companyName || ''
                      }`
                    )
                  }
                  startIcon={<RiLinkedinLine size={18} />}
                >
                  Search LinkedIn
                </Button>
              </Row>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default LinkedInFinder
