import { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RiCloseLine, RiCurrencyLine, RiDeleteBinLine } from '@remixicon/react'
import dayjs from 'dayjs'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'

import { useMutation } from '@redwoodjs/web'

import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import PersonFinder from 'src/components/PersonFinder/PersonFinder'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { ungatedForCrm3 } from 'src/lib/gates'
import { logger } from 'src/lib/logger'

/*
  input OpportunityUpdateInput {
    id: String!
    stageId: String
    position: Int
    title: String
    type: String
    ownerId: String
    ownerEmail: String
    expectedCloseDate: DateTime
    primaryPerson: String
    currentStatus: String
    expectedRevenue: Float
    domain: String
  }

  */

// mutation to updateOpportunity:
const UPDATE_OPPORTUNITY_FROM_SIDEBAR = gql`
  mutation sidebarUpdateOpportunity($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`

const DELETE_OPPORTUNITY = gql`
  mutation oppEditDeleteOpportunity(
    $id: String!
    $workspaceId: String!
    $pipelineId: String!
  ) {
    deleteOpportunity(
      id: $id
      workspaceId: $workspaceId
      pipelineId: $pipelineId
    ) {
      id
      objectType
    }
  }
`

const OpportunityEdit = ({ crmObject, setOpen }) => {
  const { selectedWorkspace, workAccounts } = useContext(DayContext)

  const confirm = useConfirm()

  const passedOpportunity = {
    id: crmObject.objectId,
    title: crmObject.properties.title,
    primaryPerson: crmObject.properties.primaryPerson?.objectId,
    type: crmObject.properties.type,
    ownerEmail: crmObject.properties.ownerEmail,
    expectedCloseDate: crmObject.properties.expectedCloseDate,
    currentStatus: crmObject.properties.currentStatus,
    expectedRevenue: crmObject.properties.expectedRevenue,
    domain: crmObject.properties.domain,
    hasRevenue: crmObject.properties.hasRevenue,
    pipelineId: crmObject.properties.pipelineId,
    workspaceId: crmObject.properties.workspaceId,
  }

  const [opportunity, setOpportunity] = useState(passedOpportunity)
  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY_FROM_SIDEBAR)
  const [deleteOpportunity] = useMutation(DELETE_OPPORTUNITY)

  const closeAndRefetch = () => {
    if (crmObject?.properties?.refetch) crmObject?.properties?.refetch()
    setOpen(false)
  }

  const handleUpdateOpportunity = async () => {
    toast.promise(updateOpportunity({ variables: { input: opportunity } }), {
      loading: 'Updating Opportunity...',
      success: () => {
        closeAndRefetch()
        return 'Opportunity Updated!'
      },
      error: 'Error Updating Opportunity',
    })
  }

  const handleDeleteOpportunity = async () => {
    try {
      await confirm({
        description: 'Are you sure you want to delete this opportunity?',
      })

      await toast.promise(
        deleteOpportunity({
          variables: {
            id: crmObject.objectId,
            workspaceId: crmObject.properties.workspaceId,
            pipelineId: crmObject.properties.pipelineId,
          },
        }),
        {
          loading: 'Deleting Opportunity...',
          success: () => {
            closeAndRefetch()
            return 'Opportunity Deleted!'
          },
          error: 'Error Deleting Opportunity',
        }
      )
    } catch (error) {
      logger.info('Failed to delete the opportunity (user likely cancelled)')
    }
  }

  if (passedOpportunity.id !== opportunity.id) {
    setOpportunity(passedOpportunity)
  }

  useEffect(() => {
    logger.dev({ opportunity })
  }, [opportunity])

  return (
    <Grid
      container={true}
      spacing={3}
    >
      <Grid
        item={true}
        xs={6}
      >
        <TextField
          label="Title"
          value={opportunity.title}
          fullWidth={true}
          onChange={(e) =>
            setOpportunity({ ...opportunity, title: e.target.value })
          }
          sx={{ mb: 3 }}
        />
        <TextField
          label="Type"
          value={opportunity.type}
          fullWidth={true}
          onChange={(e) =>
            setOpportunity({ ...opportunity, type: e.target.value })
          }
          sx={{ mb: 3 }}
        />
        <TextField
          label="Owner (email)"
          value={opportunity.ownerEmail}
          fullWidth={true}
          onChange={(e) =>
            setOpportunity({ ...opportunity, ownerEmail: e.target.value })
          }
          sx={{ mb: 3 }}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Expected Close Date"
            value={dayjs(opportunity.expectedCloseDate)}
            onChange={(e) => {
              logger.dev({ e })
              setOpportunity({
                ...opportunity,
                expectedCloseDate: e.toISOString(),
              })
            }}
            sx={{ width: '100%', mb: 3 }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item={true}
        xs={6}
      >
        <Row sx={{ mb: 3 }}>
          <Box
            sx={{
              mr: 2,
              height: '52px',
              width: '52px',
              background: (theme) => theme.palette.divider,
              borderRadius: '50%',
              flexShrink: 0,
            }}
          >
            {opportunity?.primaryPerson && (
              <ContactAvatar
                email={opportunity?.primaryPerson}
                size={52}
                borderRadius={50}
              />
            )}
          </Box>
          <PersonFinder
            key={`personFinder_opp_${opportunity?.id}`}
            label="Primary point of contact"
            selected={[opportunity?.primaryPerson]}
            onSelect={(person) => {
              setOpportunity({
                ...opportunity,
                primaryPerson: person?.objectId || null,
              })
            }}
          />
        </Row>
        {!ungatedForCrm3({ selectedWorkspace, workAccounts }) && (
          <TextField
            label="Current Status"
            value={opportunity.currentStatus}
            fullWidth={true}
            multiline={true}
            rows={7}
            onChange={(e) =>
              setOpportunity({ ...opportunity, currentStatus: e.target.value })
            }
            sx={{ mb: 3 }}
          />
        )}

        {opportunity.hasRevenue ? (
          <Row sx={{ mb: 3 }}>
            <TextField
              id="expectedRevenue"
              label="Expected Amount"
              variant="outlined"
              type="number"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={opportunity.expectedRevenue}
              onChange={(e) => {
                const expectedRevenue = parseFloat(e.target.value)
                setOpportunity({
                  ...opportunity,
                  expectedRevenue,
                })
              }}
            />
            <Tooltip
              title="Remove revenue from pipeline"
              placement="top"
              arrow={true}
            >
              <IconButton
                sx={{ p: '4px', borderRadius: '2px', ml: 1 }}
                onClick={() =>
                  setOpportunity({ ...opportunity, hasRevenue: false })
                }
              >
                <RiCloseLine />
              </IconButton>
            </Tooltip>
          </Row>
        ) : (
          <Tooltip
            title="Add revenue to pipeline"
            placement="top"
            arrow={true}
          >
            <Button
              onClick={() =>
                setOpportunity({ ...opportunity, hasRevenue: true })
              }
              fullWidth={true}
              sx={{ mb: 3 }}
            >
              <RiCurrencyLine style={{ marginRight: '8px' }} />
              Add revenue
            </Button>
          </Tooltip>
        )}
        <TextField
          label="Domain"
          value={opportunity.domain}
          fullWidth={true}
          onChange={(e) =>
            setOpportunity({ ...opportunity, domain: e.target.value })
          }
          sx={{ mb: 3 }}
        />
        <Row>
          <Button
            onClick={handleUpdateOpportunity}
            color="primary"
            variant="contained"
            fullWidth={true}
            disableElevation
          >
            Update Opportunity
          </Button>

          <Tooltip
            title="Delete Opportunity"
            placement="top"
            arrow={true}
          >
            <IconButton
              onClick={handleDeleteOpportunity}
              sx={{ ml: 2, p: '4px', borderRadius: '2px' }}
            >
              <RiDeleteBinLine />
            </IconButton>
          </Tooltip>
        </Row>
      </Grid>
    </Grid>
  )
}

export default OpportunityEdit
