import {
  RiBrainLine,
  RiMicLine,
  RiUser3Line,
  RiCustomerService2Line,
  RiGroupLine,
  RiMoneyDollarBoxLine,
  RiServiceLine,
  RiShoppingCartLine,
  RiUserAddLine,
  RiMoneyDollarBoxFill,
  RiMapPinUserFill,
  RiCustomerServiceFill,
  RiLineChartFill,
} from '@remixicon/react'

interface RelationshipSummaryMetadata {
  aliases: string[]
  label: string
  key: string
  oppType: string
  valueInSearch: number
  icon: any
  emoji: string
  variant: 'outlined' | 'contained'
  color: 'primary' | 'secondary'
}

type NativeRelationshipSummaryMetadataType = {
  [key: string]: RelationshipSummaryMetadata
}

export const NativeRelationshipSummaryMetadata: NativeRelationshipSummaryMetadataType =
  {
    PROSPECTIVE_CUSTOMER: {
      key: 'PROSPECTIVE_CUSTOMER',
      aliases: ['prospective customer'],
      label: 'Prospective Customer',
      oppType: 'New Business',
      valueInSearch: 3,
      icon: <RiServiceLine />,
      emoji: '🔍',
      variant: 'outlined',
      color: 'primary',
    },
    CUSTOMER_EXISTING: {
      key: 'CUSTOMER_EXISTING',
      aliases: ['current customer', 'CURRENT_CUSTOMER'],
      label: 'Current Customer',
      valueInSearch: 3,
      oppType: 'Repeat business',
      icon: <RiServiceLine />,
      emoji: '🛒',
      variant: 'contained',
      color: 'primary',
    },
    PROSPECTIVE_INVESTOR: {
      key: 'PROSPECTIVE_INVESTOR',
      aliases: ['prospective investor'],
      label: 'Prospective Investor',
      oppType: 'New investment',
      valueInSearch: 3,
      icon: <RiMoneyDollarBoxLine />,
      emoji: '💰',
      variant: 'outlined',
      color: 'primary',
    },
    INVESTOR_EXISTING: {
      key: 'INVESTOR_EXISTING',
      aliases: ['current investor', 'investor', 'CURRENT_INVESTOR'],
      label: 'Current Investor',
      oppType: 'Follow-on investment',
      valueInSearch: 2,
      icon: <RiMoneyDollarBoxLine />,
      emoji: '💰',
      variant: 'contained',
      color: 'primary',
    },
    PROSPECTIVE_VENDOR: {
      key: 'PROSPECTIVE_VENDOR',
      aliases: ['prospective vendor'],
      label: 'Prospective Vendor',
      oppType: 'New vendor',
      valueInSearch: 1,
      icon: <RiShoppingCartLine />,
      emoji: '🔍',
      variant: 'outlined',
      color: 'secondary',
    },
    VENDOR_EXISTING: {
      key: 'VENDOR_EXISTING',
      aliases: ['current vendor', 'vendor', 'CURRENT_VENDOR'],
      label: 'Current Vendor',
      oppType: 'Repeat business',
      valueInSearch: 1,
      icon: <RiCustomerService2Line />,
      emoji: '🛒',
      variant: 'outlined',
      color: 'secondary',
    },
    MENTOR: {
      key: 'MENTOR',
      aliases: ['mentor'],
      label: 'Mentor',
      valueInSearch: 3,
      oppType: 'Mentorship collaboration',
      icon: <RiBrainLine />,
      emoji: '🧠',
      variant: 'outlined',
      color: 'secondary',
    },
    MENTEE: {
      key: 'MENTEE',
      aliases: ['mentee'],
      label: 'Mentee',
      valueInSearch: 1,
      oppType: 'Mentorship collaboration',
      icon: <RiBrainLine />,
      emoji: '🧠',
      variant: 'outlined',
      color: 'secondary',
    },
    INTERVIEWER: {
      key: 'INTERVIEWER',
      aliases: ['interviewer'],
      label: 'Interviewer',
      oppType: 'Recruiting',
      valueInSearch: 1,
      icon: <RiMicLine />,
      emoji: '🎤',
      variant: 'outlined',
      color: 'secondary',
    },
    PROSPECTIVE_CANDIDATE: {
      key: 'PROSPECTIVE_CANDIDATE',
      aliases: ['interviewee', 'candidate'],
      label: 'Prospective Candidate',
      oppType: 'Recruiting',
      valueInSearch: 2,
      icon: <RiUserAddLine />,
      emoji: '🔍',
      variant: 'outlined',
      color: 'secondary',
    },
    FUNDRAISER: {
      key: 'FUNDRAISER',
      aliases: ['fundraiser'],
      label: 'Fundraiser',
      oppType: 'Fundraising',
      valueInSearch: 1,
      icon: <RiMoneyDollarBoxLine />,
      emoji: '💰',
      variant: 'outlined',
      color: 'secondary',
    },
    INTERNAL: {
      key: 'INTERNAL',
      aliases: ['internal'],
      label: 'Colleague',
      oppType: 'Internal collaboration',
      valueInSearch: 1,
      icon: <RiGroupLine />,
      emoji: '🏢',
      variant: 'outlined',
      color: 'secondary',
    },
    OTHER: {
      key: 'OTHER',
      aliases: ['other'],
      oppType: 'New Business',
      label: 'Person',
      valueInSearch: 1,
      icon: <RiUser3Line />,
      emoji: '❓',
      variant: 'outlined',
      color: 'secondary',
    },
    INSUFFICIENT_DATA: {
      key: 'INSUFFICIENT_DATA',
      aliases: [],
      label: 'Person',
      oppType: 'New Business',
      valueInSearch: 1,
      icon: <RiUser3Line />,
      emoji: '❓',
      variant: 'outlined',
      color: 'secondary',
    },
  }

/*
    ['RECRUITING', 'NEW_CUSTOMER', 'EXISTING_CUSTOMER', 'FINANCING_INVESTMENT', 'VENDOR_PROCUREMENT', 'NONE', 'OTHER']
  */
export const NativeSuggestedPipelineTypes = {
  NEW_CUSTOMER: {
    label: 'New Customer',
    icon: <RiMoneyDollarBoxLine />,
    relationshipTypes: ['PROSPECTIVE_CUSTOMER'],
    showInChooser: true,
    informalLabel: 'Get new customers',
  },
  FINANCING_INVESTMENT: {
    label: 'Financing Investment',
    icon: <RiLineChartFill />,
    relationshipTypes: ['INVESTOR_EXISTING', 'PROSPECTIVE_INVESTOR'],
    showInChooser: true,
    informalLabel: 'Raise your next round',
  },
  EXISTING_CUSTOMER: {
    label: 'Existing Customer Sale',
    icon: <RiMoneyDollarBoxFill />,
    relationshipTypes: [],
    showInChooser: true,
    informalLabel: 'Expand current customers',
  },
  VENDOR_PROCUREMENT: {
    label: 'Vendor Procurement',
    icon: <RiCustomerServiceFill />,
    relationshipTypes: ['VENDOR_EXISTING', 'PROSPECTIVE_VENDOR'],
    showInChooser: true,
    informalLabel: 'Buy new products',
  },
  RECRUITING: {
    label: 'Recruiting',
    icon: <RiMapPinUserFill />,
    relationshipTypes: ['PROSPECTIVE_CANDIDATE'],
    showInChooser: false,
    informalLabel: 'Hire new candidates',
  },
  RESEARCH: {
    label: 'Research',
    icon: <RiMapPinUserFill />,
    relationshipTypes: [],
    showInChooser: false,
    informalLabel: 'Learn new things through research',
  },
  NONE: {
    label: 'New Customer',
    icon: <RiMapPinUserFill />,
    relationshipTypes: [],
    showInChooser: false,
    informalLabel: 'No particular purpose',
  },
  OTHER: {
    label: 'Other',
    icon: <RiMapPinUserFill />,
    relationshipTypes: ['OTHER'],
    showInChooser: true,
    informalLabel: 'Do your own thing',
  },
}

/*
  - AWARENESS: One or both parties become aware of each other.
  - CONNECTION: Initial contact and communication are established.
  - NEEDS IDENTIFICATION: Understanding and defining the needs and requirements of both parties.
  - PROPOSAL: Presenting a solution or offer that addresses the identified needs.
  - CONSIDERATION/NEGOTIATION: Evaluating the proposal and negotiating terms.
  - DECISION: Finalizing the decision leading to a closed-won or closed-lost outcome.`,
*/

export const NativeSuggestedPipelineStages = {
  AWARENESS: {
    label: 'Awareness',
    nextStepPage: { label: 'Draft Email' },
    showInChooser: true,
  },
  CONNECTION: {
    label: 'Connection',
    nextStepPage: { label: 'Initial Discovery' },
    showInChooser: true,
  },
  ['NEEDS IDENTIFICATION']: {
    label: 'Needs Identification',
    nextStepPage: { label: 'Business Case Creation' },
    showInChooser: false,
  },
  NEEDS_IDENTIFICATION: {
    label: 'Needs Identification',
    nextStepPage: { label: 'Business Case Creation' },
    showInChooser: true,
  },
  PROPOSAL: {
    label: 'Proposal',
    nextStepPage: { label: 'Proposal Development' },
    showInChooser: true,
  },
  ['CONSIDERATION/NEGOTIATION']: {
    label: 'Consideration',
    nextStepPage: { label: 'Objection Handling' },
    showInChooser: false,
  },
  CONSIDERATION_NEGOTIATION: {
    label: 'Consideration/Negotiation',
    nextStepPage: { label: 'Objection Handling' },
    showInChooser: true,
  },
  DECISION: {
    label: 'Decision',
    nextStepPage: { label: 'Drive to Decision' },
    showInChooser: true,
  },
  CUSTOMER_SUCCESS: {
    label: 'Customer Success',
    nextStepPage: { label: 'Customer Success' },
    showInChooser: false,
  },
  CLOSED_WON: {
    label: 'Closed Won',
    nextStepPage: { label: 'Closed Won' },
    showInChooser: true,
  },
  CLOSED_LOST: {
    label: 'Closed Lost',
    nextStepPage: { label: 'Closed Lost' },
    showInChooser: true,
  },
}

export const getAllRelationshipSummaryTypes = (keys) => {
  if (!keys) return []
  return keys.flatMap((key) => [
    key,
    ...(NativeRelationshipSummaryMetadata[key]?.aliases || []), // Ensure fallback to empty array if aliases are undefined
  ])
}

export const getRelationshipSummaryTypeKey = (relationshipSummaryType) => {
  const finalKey = Object.keys(NativeRelationshipSummaryMetadata).find(
    (key) =>
      relationshipSummaryType === key ||
      NativeRelationshipSummaryMetadata[key]?.aliases.includes(
        relationshipSummaryType
      ) ||
      relationshipSummaryType === NativeRelationshipSummaryMetadata[key].label
  )
  return finalKey
}

export const getOppType = (relationshipSummaryType) => {
  const key = getRelationshipSummaryTypeKey(relationshipSummaryType)
  return NativeRelationshipSummaryMetadata[key]?.oppType
}

export const getScoreForSearchFromCrmObject = (crmObject) => {
  const relType = crmObject?.properties?.['relationshipSummary/type']
  if (!relType || typeof relType != 'string') return 0

  if (relType in NativeRelationshipSummaryMetadata) {
    return NativeRelationshipSummaryMetadata[relType].valueInSearch
  }

  Object.keys(NativeRelationshipSummaryMetadata).map((key) => {
    const alias = NativeRelationshipSummaryMetadata[key]?.aliases.find(
      (alias) => alias === relType
    )
    if (alias) {
      return NativeRelationshipSummaryMetadata[key].valueInSearch
    }
    return 0
  })

  return 0
}

export const getRelationshipSummaryTypeLabel = (
  crmObject,
  relevantTypes = null,
  keysOrLabels = 'labels',
  userEmail = null
) => {
  let label = ''

  if (crmObject.objectId == userEmail) {
    return 'You'
  }

  const type = crmObject?.properties?.['relationshipSummary/type']
  if (type) {
    const key = getRelationshipSummaryTypeKey(type)

    if (keysOrLabels === 'keys') {
      if (relevantTypes) {
        label = relevantTypes.includes(key) ? key : ''
      } else {
        label = key
      }
    } else {
      label = NativeRelationshipSummaryMetadata[key]?.label || ''
      if (relevantTypes) {
        label = relevantTypes.includes(key) ? label : ''
      }
    }
  }

  return label
}

export const getRelationshipSummaryTypeMetadata = (
  crmObject,
  relevantTypes = []
) => {
  const type = crmObject?.properties?.['relationshipSummary/type']
  if (type) {
    const key = getRelationshipSummaryTypeKey(type)
    if (relevantTypes.length > 0 && !relevantTypes.includes(key)) return {}
    return NativeRelationshipSummaryMetadata[key] || {}
  }

  return {}
}

export const getRelationshipSummaryTypeMetadataFromKey = (key) => {
  const finalKey = key ? getRelationshipSummaryTypeKey(key) : 'OTHER'
  return NativeRelationshipSummaryMetadata[finalKey] || {}
}
