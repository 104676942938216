import { useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import {
  RiAddLine,
  RiCloseLine,
  RiKanbanView,
  RiQuestionAnswerLine,
} from '@remixicon/react'
import toast from 'react-hot-toast'
import {
  PipelineUpdateInput,
  StageCreateInput,
  StageUpdateInput,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'
import { NativeSuggestedPipelineTypes } from 'src/lib/relationshipSummary'

import PipelineEditStage from '../PipelineEditStage/PipelineEditStage'

const GET_PIPELINE_FOR_EDIT = gql`
  query GetPipelineForEdit($id: String!, $workspaceId: String!) {
    pipeline(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      title
      description
      type
      instructions
      automationActive
      hasRevenue
      stages {
        id
        title
        description
        type
        position
        entranceCriteria
        activities
        likelihoodToClose
      }
    }
  }
`

const UPDATE_PIPELINE_FROM_EDIT = gql`
  mutation UpdatePipelineFromEdit($id: String!, $input: PipelineUpdateInput!) {
    updatePipeline(id: $id, input: $input) {
      id
    }
  }
`

const CREATE_STAGE_FROM_EDIT = gql`
  mutation createStage($input: StageCreateInput!) {
    createStage(input: $input) {
      id
      title
      workspaceId
      pipelineId
      position
      entranceCriteria
      likelihoodToClose
      type
      description
      color
      activities
    }
  }
`

// gql for UPDATE_STAGE_FROM_BOARD:
const UPDATE_STAGE_FROM_EDIT = gql`
  mutation updateStage($input: StageUpdateInput!) {
    updateStage(input: $input) {
      id
      title
      workspaceId
      pipelineId
      position
      entranceCriteria
      likelihoodToClose
      type
      description
      color
      activities
    }
  }
`

const PipelineEdit = ({
  pipelineId,
  workspaceId,
  stageId,
  refetch,
}: {
  pipelineId: string
  workspaceId: string
  stageId: string | null
  refetch: () => void
}) => {
  const [pipeline, setPipeline] = useState<PipelineUpdateInput>()
  const [tab, setTab] = useState<'about' | 'stages'>(
    stageId != 'pipeline' ? 'stages' : 'about'
  )
  const [stagesWithEdits, setStagesWithEdits] = useState<string[]>([])
  const [saveNeeded, setSaveNeeded] = useState(false)
  const { loading } = useQuery(GET_PIPELINE_FOR_EDIT, {
    variables: { id: pipelineId, workspaceId },
    skip: !pipelineId || !workspaceId,
    onCompleted: (data) => {
      if (!data?.pipeline) {
        return
      }
      const pipeline = { ...data.pipeline }

      delete pipeline.createdAt
      delete pipeline.updatedAt
      delete pipeline.__typename

      const stagesCopy = []
      for (const stage of pipeline.stages) {
        const stageCopy = { ...stage }
        delete stageCopy.createdAt
        delete stageCopy.updatedAt
        delete stageCopy.__typename
        stagesCopy.push(stageCopy)
      }
      pipeline.stages = stagesCopy

      if (!pipeline.type) {
        pipeline.type = 'NEW_CUSTOMER'
      }
      if (!pipeline.description) {
        pipeline.description = ''
      }
      if (!pipeline.automationActive) {
        pipeline.automationActive = false
      }
      if (!pipeline.title) {
        pipeline.title = ''
      }
      if (!pipeline.hasRevenue) {
        pipeline.hasRevenue = false
      }
      if (!pipeline.automationActive) {
        pipeline.automationActive = false
      }
      if ((pipeline.instructions?.length ?? 0) === 0) {
        pipeline.instructions = ['']
      }

      setPipeline(pipeline)
    },
  })

  const [updatePipelineFromEdit] = useMutation(UPDATE_PIPELINE_FROM_EDIT)
  const [createStage, { loading: createStageLoading }] = useMutation(
    CREATE_STAGE_FROM_EDIT
  )
  const [updateStage, { loading: updateStageLoading }] = useMutation(
    UPDATE_STAGE_FROM_EDIT
  )

  const handleCreateStage = async (stage: StageCreateInput) => {
    const result = await createStage({
      variables: { input: stage },
    })
    setSaveNeeded(true)
  }

  const handleSavePipeline = async () => {
    if (saveNeeded) {
      await toast.promise(
        updatePipelineFromEdit({
          variables: {
            id: pipelineId,
            input: pipeline,
          },
        }),
        {
          loading: 'Saving pipeline...',
          success: 'Pipeline saved!',
          error: 'Error saving pipeline',
        }
      )
    }
    for (const stageId of stagesWithEdits) {
      const stage = pipeline.stages.find((s) => s.id === stageId)
      if (stage) {
        await toast.promise(updateStage({ variables: { input: stage } }), {
          loading: 'Saving stage...',
          success: 'Stage saved!',
          error: 'Error saving stage',
        })
      }
    }
    refetch()
    setSaveNeeded(false)
    setStagesWithEdits([])
  }

  const handleAddInstruction = () => {
    setPipeline({ ...pipeline, instructions: [...pipeline.instructions, ''] })
    setSaveNeeded(true)
  }

  const handleUpdateInstruction = (index: number, value: string) => {
    setPipeline((prev) => ({
      ...prev,
      instructions: prev.instructions.map((instruction, i) =>
        i === index ? value : instruction
      ),
    }))
    setSaveNeeded(true)
  }

  const handleRemoveInstruction = (index: number) => {
    setPipeline((prev) => ({
      ...prev,
      instructions: prev.instructions.filter((_, i) => i !== index),
    }))
    setSaveNeeded(true)
  }

  const handleUpdateFromStageEdit = (stage: StageUpdateInput) => {
    setPipeline((prev) => ({
      ...prev,
      stages: prev.stages.map((s) => (s.id === stage.id ? stage : s)),
    }))
    setStagesWithEdits((prev) => {
      const updatedStages = new Set([...prev, stage.id])
      return Array.from(updatedStages)
    })
  }

  return pipeline ? (
    <Box sx={{ height: 'calc(100vh - 72px)', overflowY: 'auto' }}>
      <Row
        sx={{
          justifyContent: 'space-between',
          height: '56px',
          mb: 1,
        }}
      >
        <Tabs
          value={tab}
          onChange={(e, newValue) => setTab(newValue)}
          sx={{ m: 0, py: 0 }}
        >
          <Tab
            value="about"
            label="About"
            icon={<RiQuestionAnswerLine size={16} />}
            iconPosition="start"
          />
          <Tab
            icon={<RiKanbanView size={16} />}
            iconPosition="start"
            value="stages"
            label="Stages"
          />
        </Tabs>
        <Button
          variant={
            saveNeeded || stagesWithEdits.length > 0 ? 'contained' : 'outlined'
          }
          color={
            saveNeeded || stagesWithEdits.length > 0 ? 'secondary' : 'primary'
          }
          disableElevation={true}
          onClick={handleSavePipeline}
          sx={{ maxHeight: '36px' }}
        >
          Save
        </Button>
      </Row>
      <Box sx={{ height: 'calc(100vh - 128px - 24px)', overflowY: 'auto' }}>
        {tab === 'about' && (
          <Row
            sx={{ mt: 3, flexDirection: 'column', width: '100%' }}
            gap={2}
          >
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Title
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Describe the pipeline in a few words
                </Typography>
              </Box>
              <TextField
                fullWidth={true}
                value={pipeline?.title}
                onChange={(e) => {
                  setPipeline({ ...pipeline, title: e.target.value })
                  setSaveNeeded(true)
                }}
              />
            </Row>
            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Goal & Purpose
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Explain the goal and purpose of this pipeline. This helps
                  Day.ai better automate actions, tailor documents, and provide
                  recommendations aligned with your specific pipeline
                  objectives.
                </Typography>
              </Box>
              <TextField
                fullWidth={true}
                value={pipeline?.description}
                multiline={true}
                rows={4}
                onChange={(e) => {
                  setPipeline({ ...pipeline, description: e.target.value })
                  setSaveNeeded(true)
                }}
              />
            </Row>
            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Pipeline Type
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Select the type of pipeline you want to create.
                </Typography>
              </Box>
              <Select
                fullWidth={true}
                label="Pipeline Type"
                value={pipeline?.type}
                onChange={(e) => {
                  setPipeline({ ...pipeline, type: e.target.value })
                  setSaveNeeded(true)
                }}
              >
                {Object.entries(NativeSuggestedPipelineTypes).map(
                  ([key, value]) =>
                    key != 'NONE' && (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {value?.label}
                      </MenuItem>
                    )
                )}
              </Select>
            </Row>
            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Automation
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Automate opportunity movement between stages, based on
                  conversation (meeting/email) history and the entrance criteria
                  of the stages.
                </Typography>
              </Box>
              <Switch
                checked={pipeline.automationActive}
                onChange={(e) => {
                  setPipeline({
                    ...pipeline,
                    automationActive: e.target.checked,
                  })
                  setSaveNeeded(true)
                }}
              />
            </Row>
            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Track Revenue
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Track revenue opportunities in this pipeline.
                </Typography>
              </Box>
              <Switch
                checked={pipeline.hasRevenue}
                onChange={(e) => {
                  setPipeline({ ...pipeline, hasRevenue: e.target.checked })
                  setSaveNeeded(true)
                }}
              />
            </Row>
            <Row
              sx={{ width: '100%', mb: 3, alignItems: 'top' }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Row sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="h3">Instructions</Typography>

                  <Button
                    variant="text"
                    size="small"
                    disableElevation={true}
                    startIcon={<RiAddLine size={16} />}
                    onClick={handleAddInstruction}
                  >
                    Add
                  </Button>
                </Row>
                <Typography sx={{ color: 'text.secondary' }}>
                  Add training & behavior notes for people working this
                  pipeline, and for Day.ai Assistant to create better documents
                  & recommendations.
                </Typography>
              </Box>
              <Row
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                gap={2}
              >
                {pipeline?.instructions?.length > 0 ? (
                  <>
                    {pipeline.instructions.map((instruction, index) => (
                      <Row
                        key={`instruction-${index}`}
                        sx={{
                          justifyContent: 'space-between',
                          width: '100%',
                          mb: 2,
                        }}
                        gap={2}
                      >
                        <TextField
                          fullWidth={true}
                          key={`instruction-${index}`}
                          value={instruction}
                          onChange={(e) =>
                            handleUpdateInstruction(index, e.target.value)
                          }
                        />
                        <IconButton
                          onClick={() => handleRemoveInstruction(index)}
                          sx={{ borderRadius: '3px' }}
                        >
                          <RiCloseLine size={24} />
                        </IconButton>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Typography></Typography>
                )}
              </Row>
            </Row>
          </Row>
        )}
        {tab === 'stages' && (
          <>
            <Row sx={{ justifyContent: 'space-between', my: 3 }}>
              <Typography variant="h1">Stages</Typography>
              <Typography>To re-order stages, use the board view.</Typography>
            </Row>
            {pipeline?.stages?.length > 0 ? (
              <>
                {pipeline.stages.map((stage, index) => (
                  <PipelineEditStage
                    key={stage.id}
                    stage={stage}
                    previousStage={pipeline.stages?.[index - 1]}
                    onUpdate={handleUpdateFromStageEdit}
                    openOnLoad={stage.id === stageId}
                  />
                ))}
              </>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  ) : loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <CircularProgress
        size={48}
        color="secondary"
      />
    </Box>
  ) : null
}

export default PipelineEdit
