import { useContext, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { Box, Chip, CircularProgress, Tooltip, Typography } from '@mui/material'
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiPlayFill,
  RiStickyNoteLine,
} from '@remixicon/react'
import dayjs from 'dayjs'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import MeetingRecordingDialog from '../../MeetingRecordingDialog/MeetingRecordingDialog'
import { buildTimelineItems } from '../organizations'

const GET_ORGANIZATION_HISTORY_FOR_TIMELINE = gql`
  query getOrganizationHistoryForTimeline(
    $workspaceId: String!
    $orgId: String!
  ) {
    organizationHistory(orgId: $orgId, workspaceId: $workspaceId) {
      domain
      domainGmailThreadSummaries
      meetingRecordings
      events
      notes {
        content
        createdAt
        person {
          firstName
          lastName
          email
          photo
        }
      }
    }
  }
`

const TimelineDescription = ({ short, long }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <TimelineContent sx={{ pt: 3, fontSize: '0.7rem' }}>
      <Typography>{expanded ? long : short}</Typography>
      <Chip
        variant="outlined"
        size="small"
        sx={{ border: 'none', fontWeight: 600, fontSize: '0.7rem' }}
        icon={
          expanded ? (
            <RiArrowDropUpLine
              size={16}
              style={{ flexShrink: 0 }}
            />
          ) : (
            <RiArrowDropDownLine
              size={16}
              style={{ flexShrink: 0 }}
            />
          )
        }
        onClick={() => {
          setExpanded((prev) => !prev)
        }}
        clickable={true}
        label={expanded ? 'less' : 'more'}
      />
    </TimelineContent>
  )
}

const OrganizationTimeline = ({
  org,
  timelineItems = null,
  showHeader = false,
  loading = false,
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const [recordingToWatch, setRecordingToWatch] = useState(null)

  const { data: organizationHistoryData, loading: queryLoading } = useQuery(
    GET_ORGANIZATION_HISTORY_FOR_TIMELINE,
    {
      variables: { workspaceId: selectedWorkspace, orgId: org?.domain },
      skip: !org?.domain || !selectedWorkspace || timelineItems?.length > 0,
      onCompleted: ({ organizationHistory }) => {
        logger.dev({ organizationHistory })
      },
    }
  )
  const history = organizationHistoryData?.organizationHistory

  const timelineItemsFinal = timelineItems || buildTimelineItems(history)

  return (
    <>
      {showHeader && <Typography variant="h2">Timeline</Typography>}
      {loading || queryLoading ? (
        <CircularProgress
          color="secondary"
          size={24}
        />
      ) : (
        <Timeline position="alternate">
          {timelineItemsFinal.map((item, index) => (
            <TimelineItem key={`timelineItem_${index}_${item.id}`}>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{
                  pt: '26px',
                  fontWeight: 600,
                  fontSize: '0.7rem',
                  letterSpacing: '-0.5px',
                }}
              >
                {dayjs(item.timestamp).format('M/D/YY')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  onClick={() => {
                    if (item.type === 'meetingRecording') {
                      setRecordingToWatch(item.id)
                    }
                  }}
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    p: 1,
                    color: 'text.primary',
                    cursor:
                      item.type === 'meetingRecording' ? 'pointer' : 'default',
                  }}
                >
                  {item.type === 'email' && (
                    <Box
                      component="img"
                      src={'/gmail-icon.svg'}
                      sx={{
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  )}
                  {item.type === 'meetingRecording' && (
                    <Tooltip
                      title="View meeting recording & clips"
                      placement="top"
                      arrow={true}
                    >
                      <span>
                        <RiPlayFill />
                      </span>
                    </Tooltip>
                  )}
                  {item.type === 'note' && (
                    <Tooltip
                      title="View note"
                      placement="top"
                      arrow={true}
                    >
                      <span>
                        <RiStickyNoteLine />
                      </span>
                    </Tooltip>
                  )}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineDescription
                short={item.label}
                long={item.expandedText}
              />
            </TimelineItem>
          ))}
        </Timeline>
      )}
      {recordingToWatch && (
        <MeetingRecordingDialog
          id={recordingToWatch}
          open={!!recordingToWatch}
          onClose={() => setRecordingToWatch(null)}
        />
      )}
    </>
  )
}

export default OrganizationTimeline
