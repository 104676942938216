import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { LicenseInfo } from '@mui/x-license-pro'
LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE_KEY)

import { RiFlashlightLine, RiSquareFill, RiSquareLine } from '@remixicon/react'
import dayjs from 'dayjs'
import SortedDescendingIcon from 'remixicon-react/ArrowDownSLineIcon'
import SortedAscendingIcon from 'remixicon-react/ArrowUpSLineIcon'
import MoreActionsIcon from 'remixicon-react/More2LineIcon'

import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import ContactTile from 'src/components/ContactTile/ContactTile'
import { DataGridToolbar } from 'src/components/DataGrid/util'

import {
  Autocomplete,
  Box,
  Chip,
  lighten,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import Row from 'src/components/Row/Row'

import { useContext, useEffect, useMemo } from 'react'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import { getStageStyle, shouldShowOpp } from '../Pipeline'

const UnsortedIcon = null

const cellStyle = {
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

const PipelineTable = ({
  pipeline,
  opportunities,
  owners,
  filters,
  setTableFilters,
  onUpdate,
  refetch,
}) => {
  const theme = useTheme()
  const { setSidebarObject, workAccounts } = useContext(DayContext)

  const apiRef = useGridApiRef()

  const isUserNextSteps = (nextSteps) => {
    return workAccounts
      ?.map((wa) => wa?.email)
      ?.some((email) => nextSteps?.some((ns) => ns?.person === email))
  }

  const openOppInSidebar = (params) => {
    const crmObject = {
      objectType: NativeObjectTypes.Opportunity,
      objectId: params.row.id,
      properties: {
        ...params.row,
        opportunityTypes: pipeline?.opportunityTypes,
        refetch: refetch,
      },
    }
    setSidebarObject(crmObject)
  }

  const processRowUpdate = (params) => {
    const input = {
      id: params.id,
      expectedCloseDate: params.expectedCloseDate,
      expectedRevenue: params.expectedRevenue,
      type: params.type,
    }
    onUpdate(input)
    return params
  }

  const processRowUpdateError = (params) => {
    logger.dev({ params })
  }

  const ownerColumn = {
    field: 'ownerEmail',
    headerName: 'Owner',
    width: 196,
    renderCell: (params) => {
      const coreContact = owners.find(
        (owner) => owner.email === params.row.ownerEmail
      )
      return (
        <Chip
          variant="outlined"
          sx={{
            fontSize: '0.7rem',
            fontWeight: 600,
            letterSpacing: '-0.2px',
            width: '100%',
            justifyContent: 'flex-start',
            '& .MuiChip-label': {
              pl: '4px',
            },
          }}
          label={
            <ContactTile
              email={params.row.ownerEmail}
              crmObject={{
                objectId: params.row.ownerEmail,
                objectType: NativeObjectTypes.Contact,
                properties: coreContact,
              }}
              subtitle={false}
              size={24}
            />
          }
        />
      )
    },
  }

  const revenueColumn = {
    field: 'expectedRevenue',
    headerName: 'Amount',
    width: 124,
    editable: true,
    type: 'number',
    valueGetter: (params) => params.row.expectedRevenue,
    renderCell: (params) => (
      <Tooltip
        title="Double click to update"
        arrow={true}
      >
        <Box sx={{ fontWeight: 600, cursor: 'pointer', fontSize: '0.7rem' }}>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(params.row.expectedRevenue)}
        </Box>
      </Tooltip>
    ),
  }

  const columns = [
    {
      field: 'expectedCloseDate',
      headerName: 'Close Date',
      width: 104,
      editable: true,
      type: 'date',
      valueGetter: (params) => dayjs(params.row.expectedCloseDate).toDate(),
      renderCell: (params) => (
        <Tooltip
          title="Double click to update"
          arrow={true}
        >
          <Box sx={{ fontWeight: 600, cursor: 'pointer', fontSize: '0.7rem' }}>
            {dayjs(params.row.expectedCloseDate).format('M/D/YY')}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 190,
      renderCell: (params) => (
        <Tooltip title={params.row.organization?.name || params.row.title}>
          <Chip
            variant="outlined"
            icon={
              params.row.domain ? (
                <DomainAvatar
                  domain={params.row.domain}
                  photoUrl={params.row.organization?.properties?.photoSquare}
                  size={24}
                  borderRadius={50}
                />
              ) : params.row?.primaryPerson ? (
                <ContactAvatar
                  email={params.row?.primaryPerson.objectId}
                  size={24}
                />
              ) : (
                <> </>
              )
            }
            label={params.row.title}
            sx={{
              fontSize: '0.7rem',
              fontWeight: 600,
              letterSpacing: '-0.2px',
              width: '100%',
              px: '4px',
              justifyContent: 'flex-start',
            }}
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
          />
        </Tooltip>
      ),
    },
    {
      field: 'currentStatus',
      headerName: 'Status',
      flex: 1,
      minWidth: 512,
      renderCell: (params) => (
        <Box
          onClick={(e) => {
            e.stopPropagation()
            openOppInSidebar(params)
          }}
          sx={{
            cursor: 'pointer',
            width: '100%',
            overflow: 'hidden',
            height: '56px',
            textWrap: 'wrap',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '0.7rem',
              fontWeight: 400,
              letterSpacing: '-0.2px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              WebkitLineClamp: 2,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              height: '30px',
              textWrap: 'wrap',
              lineHeight: '140%',
            }}
          >
            {params.row.currentStatus}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'stage',
      headerName: 'Stage',
      width: 172,
      valueGetter: (params) => {
        return params.row.stagePosition
      },
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.stageTitle}
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
            sx={{
              ...getStageStyle(params.row.stagePosition),
              fontSize: '0.7rem',
            }}
          />
        )
      },
    },
    {
      field: 'warmth',
      headerName: 'Recency',
      width: 112,

      valueGetter: (params) => {
        return params.row.overview?.['status/warmth'] || 0
      },
      renderCell: (params) => (
        <Row>
          {[0, 1, 2, 3].map((index) => {
            const iconSize = 18
            const warmth =
              params.row.organization?.overview?.['status/warmth'] || 0
            const color = (theme) => {
              if (warmth === 0) return theme.palette.grey[300]
              if (warmth === 1) return theme.palette.info.light
              if (warmth === 2) return theme.palette.warning.light
              if (warmth === 3) return theme.palette.success.light
              return theme.palette.primary.main
            }
            return warmth >= index ? (
              <RiSquareFill
                key={`warmth${params.row.domain}-${index}`}
                size={iconSize}
                style={{ color: color(theme) }}
              />
            ) : (
              <RiSquareLine
                key={`warmth${params.row.domain}-${index}`}
                size={iconSize}
                style={{ color: color(theme) }}
              />
            )
          })}
        </Row>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 188,
      editable: true,
      renderEditCell: (params) => {
        return (
          <Autocomplete
            value={params.value}
            fullWidth={true}
            options={pipeline?.opportunityTypes}
            freeSolo={true}
            onChange={(e, newValue) => {
              const { id, field } = params
              apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue,
                debounceMs: 200,
              })
            }}
            renderInput={(autoCompleteParams) => (
              <TextField
                fullWidth={true}
                {...autoCompleteParams}
              />
            )}
          />
        )
      },
      renderCell: (params) =>
        params.row.type && (
          <Chip
            variant="outlined"
            sx={{
              ...cellStyle,
              fontSize: '0.7rem',
              width: '100%',
              justifyContent: 'flex-start',
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
            label={params.row.type}
          />
        ),
    },
    {
      field: 'nextMeeting',
      headerName: 'Next Meeting',
      width: 128,
      valueGetter: (params) => {
        const startTime =
          params.row.organization?.overview?.['upcomingEvents']?.[0]?.start_time

        if (startTime) {
          const startDt = dayjs(startTime)
          if (startDt.isAfter(dayjs())) {
            return startDt.toDate().getTime()
          }
        }

        return null
      },
      renderCell: ({ row, value }) => {
        if (!value) {
          return null
        }

        const startTime = dayjs(value)
        const title =
          row?.organization?.overview?.['upcomingEvents']?.[0]?.title
        const minutesUntil = dayjs().diff(startTime, 'minutes') * -1

        let color
        if (minutesUntil <= 60 * 4) {
          color = 'success'
        } else if (minutesUntil <= 60 * 24) {
          color = 'info'
        } else if (minutesUntil <= 60 * 24 * 7) {
          color = 'warning'
        } else {
          color = 'primary'
        }
        return (
          startTime && (
            <Tooltip
              title={`"${title}" ${dayjs(startTime).format('M/D/YY hh:mm A')}`}
              arrow={true}
            >
              <Chip
                sx={{
                  fontSize: '0.7rem',
                  fontWeight: 600,
                  background: (theme) =>
                    lighten(theme.palette[color].main, 0.7),
                  color: (theme) => theme.palette[color].dark,
                }}
                label={startTime ? dayjs(startTime).fromNow() : null}
              />
            </Tooltip>
          )
        )
      },
    },
    {
      field: 'nextStepCount',
      headerName: 'Next Steps',
      width: 96,
      valueGetter: (params) =>
        params.row?.organization?.overview?.['status/nextSteps']
          ?.immediateNextSteps?.length,
      renderCell: (params) =>
        params.row?.organization?.overview?.['status/nextSteps']
          ?.immediateNextSteps?.length > 0 && (
          <Chip
            clickable={true}
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
            sx={{ px: '4px', fontSize: '0.7rem' }}
            icon={<RiFlashlightLine size={16} />}
            variant={
              isUserNextSteps(
                params.row?.organization?.overview?.['status/nextSteps']
                  ?.immediateNextSteps
              )
                ? 'filled'
                : 'outlined'
            }
            color={
              isUserNextSteps(
                params.row?.organization?.overview?.['status/nextSteps']
                  ?.immediateNextSteps
              )
                ? 'secondary'
                : 'default'
            }
            label={
              params.row?.organization?.overview?.['status/nextSteps']
                ?.immediateNextSteps?.length
            }
          />
        ),
    },
    {
      field: 'nextStepsText',
      headerName: 'Next Steps Details',
      width: 564,
      flex: 0,
      renderCell: (params) =>
        params.row?.organization?.overview?.['status/nextSteps']
          ?.immediateNextSteps?.length > 0 && (
          <Box
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
            sx={{
              cursor: 'pointer',
              width: '100%',
              overflow: 'hidden',
              height: '56px',
              textWrap: 'wrap',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.7rem',
                fontWeight: 400,
                letterSpacing: '-0.2px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                WebkitLineClamp: 2,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                height: '30px',
                textWrap: 'wrap',
                lineHeight: '140%',
              }}
            >
              {params.row?.organization?.overview?.[
                'status/nextSteps'
              ]?.immediateNextSteps
                ?.map((ns) => `${ns?.description} (${ns?.person})`)
                .join(', ')}
            </Typography>
          </Box>
        ),
    },
    {
      field: 'summaryText',
      headerName: 'Summary',
      width: 150,
      renderCell: (params) =>
        params.row?.organization?.overview?.['status/highLevelSummary']
          ?.length > 0 && (
          <Box
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
            sx={{ cursor: 'pointer' }}
          >
            {params.row?.organization?.overview?.[
              'status/highLevelSummary'
            ].join(' ')}
          </Box>
        ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 80,
      renderCell: (params) => (
        <Box
          sx={cellStyle}
          onClick={(e) => {
            e.stopPropagation()
            openOppInSidebar(params)
          }}
        >
          {dayjs(params.row.createdAt).format('M/D/YY')}
        </Box>
      ),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 80,
      renderCell: (params) => (
        <Box
          sx={cellStyle}
          onClick={(e) => {
            e.stopPropagation()
            openOppInSidebar(params)
          }}
        >
          {dayjs(params.row.updatedAt).format('M/D/YY')}
        </Box>
      ),
    },
  ]

  const filteredOpportunities = useMemo(
    () =>
      opportunities?.filter((opportunity) =>
        shouldShowOpp(opportunity, filters)
      ),
    [opportunities, filters, pipeline]
  )

  logger.dev({ current: apiRef?.current })

  const initialColumnOrder = useMemo(() => {
    return filters?.table?.columns?.orderedFields || []
  }, [filters?.table?.columns?.orderedFields])

  useEffect(() => {
    if (Array.isArray(filters?.table?.columns?.orderedFields)) {
      logger.dev({ columnOrder: filters?.table?.columns?.orderedFields })
    }
  }, [filters?.table?.columns?.orderedFields])

  const finalColumns = useMemo(() => {
    const cols = []
    if (owners?.length > 0) {
      cols.push(ownerColumn)
    }
    if (pipeline?.hasRevenue) {
      cols.push(revenueColumn)
    }
    return [...cols, ...columns]
  }, [columns, owners, pipeline?.hasRevenue])

  return (
    <Box
      sx={{
        height: 'calc(100vh - 104px - 16px)',
        width: '100%',
        overflowY: 'auto',
        px: 3,
      }}
    >
      <DataGridPremium
        rows={filteredOpportunities}
        columns={finalColumns}
        apiRef={apiRef}
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          moreActionsIcon: MoreActionsIcon,
          toolbar: DataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            onUpdate: setTableFilters,
            showQuickFilter: true,
            showColumnFilter: true,
            showFilter: true,
          },
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        onColumnVisibilityModelChange={(model) => {
          setTableFilters({
            columns: {
              visibility: model,
            },
          })
        }}
        onColumnResize={(model) => {
          logger.dev({ model })
        }}
        onColumnOrderChange={(params, event, details) => {
          logger.dev({ params, event, details })
          const state = apiRef?.current?.exportState()
          const orderedFields = state?.columns?.orderedFields
          logger.dev({ orderedFields })
          if (false && Array.isArray(orderedFields)) {
            setTableFilters({
              columns: {
                orderedFields,
              },
            })
          }
        }}
        onSortModelChange={(model) => {
          setTableFilters({
            sorting: model,
          })
        }}
        onPinnedColumnsChange={(pinnedColumns) => {
          setTableFilters({
            pinnedColumns,
          })
        }}
        onFilterModelChange={(filterModel) => {
          logger.dev({ filterModel })
          setTableFilters({
            filters: filterModel,
          })
        }}
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: filters?.table?.sorting || [
              { field: 'expectedCloseDate', sort: 'desc' },
            ],
          },
          filter: filters?.table?.filters
            ? { filterModel: filters?.table?.filters }
            : {},
          columns: {
            columnVisibilityModel: filters?.table?.columns?.visibility
              ? {
                  ...filters?.table?.columns?.visibility,
                  expectedRevenue: pipeline?.hasRevenue,
                }
              : {
                  type: false,
                  createdAt: false,
                  updatedAt: false,
                  summaryText: false,
                  nextStepsText: false,
                  progress: false,
                  expectedRevenue: pipeline?.hasRevenue,
                },
          },
          pinnedColumns: filters?.table?.pinnedColumns || [],
        }}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        columnHeaderHeight={48}
        rowHeight={48}
        hideFooter={true}
        sx={{
          '& .MuiDataGrid-toolbarContainer': {
            borderBottom: 'none',
            height: '48px',
          },
          '& .MuiDataGrid-main': {
            width: '100%',
            px: 0,
            mt: 0,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            background: (theme) => theme.palette.background.paper,
          },

          '& .MuiDataGrid-columnHeader': {
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            pb: '12px',
            borderRight: 'none',
            pt: '20px',
            '.MuiDataGrid-columnHeaderTitle': {
              fontSize: '12px',
              fontWeight: 600,
            },
          },
        }}
      />
    </Box>
  )
}

export default PipelineTable
