import { useContext, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import {
  RiAddLine,
  RiArrowRightSLine,
  RiCalendarEventLine,
  RiCommunityFill,
  RiGroupLine,
  RiLinkM,
  RiLinkUnlinkM,
  RiLockFill,
  RiMailLine,
} from '@remixicon/react'
import type { DocumentNode } from 'graphql'
import toast from 'react-hot-toast'
import type {
  Workspace,
  WorkAccount,
  WorkAccountWorkspaceConnection,
  WorkAccountSharingRule,
  DisconnectWorkAccountFromWorkspace,
  WorkAccountDataAuthorizationType,
} from 'types/graphql'
import {
  ConnectWorkAccountToWorkspace,
  UpdateWorkAccountWorkspaceSettings,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'

const CONNECT_TO_WORKSPACE = gql`
  mutation ConnectWorkAccountToWorkspace(
    $input: ConnectWorkAccountToWorkspaceInput!
  ) {
    connectWorkAccountToWorkspace(input: $input)
  }
`

const UPDATE_SHARING_SETTINGS = gql`
  mutation UpdateWorkAccountWorkspaceSettings(
    $input: UpdateWorkAccountSharingInput!
  ) {
    updateWorkAccountSharingRules(input: $input)
  }
`

const DISCONNECT_FROM_WORKSPACE = gql`
  mutation DisconnectWorkAccountFromWorkspace(
    $input: DisconnectWorkAccountFromWorkspaceInput!
  ) {
    disconnectWorkAccountFromWorkspace(input: $input)
  }
`

const MEETING_TYPE_DISPLAY_METADATA = {
  INTERNAL_ONE_ON_ONE: {
    order: 1,
    displayName: 'Internal 1:1s',
  },
  INTERNAL_GROUP: {
    order: 2,
    displayName: 'Internal group meetings',
  },
  EXTERNAL_ONE_ON_ONE: {
    order: 3,
    displayName: 'External 1:1s',
  },
  EXTERNAL_GROUP: {
    order: 4,
    displayName: 'External group meetings',
  },
}

const CardButton = ({
  onClick,
  primaryText,
  actionText,
  disabled = false,
}: {
  onClick: () => void
  primaryText: string
  actionText: string
  disabled?: boolean
}) => {
  const theme = useTheme()
  return (
    <Button
      size="small"
      color="primary"
      variant="outlined"
      sx={{
        mt: 1,
        px: 2.5,
        py: 1.5,
        pl: 3.4,
        fontSize: '0.8rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& .actionLabel': {
          transition: 'opacity 0.1s ease-in-out',
          opacity: 0,
        },
        '&:hover .actionLabel': {
          opacity: 0.8,
        },
      }}
      disabled={disabled}
      onClick={() => {
        onClick()
      }}
    >
      {primaryText}
      <Typography
        variant="subtitle1"
        color="text.secondary"
        className="actionLabel"
        sx={{
          ml: 'auto',
          fontSize: '0.7rem',
          fontWeight: 400,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {actionText}
        <RiArrowRightSLine
          style={{
            height: '16px',
            width: '18px',
            color: theme.palette.text.secondary,
          }}
        />
      </Typography>
    </Button>
  )
}

// Nb. we use this in WelcomePage as well
export const WorkspaceConnectionForm = ({
  emailSharingRule,
  setEmailSharingRule,
  rulesByMeetingType,
  setRulesByMeetingType,
  mode,
  disabled = false,
}: {
  emailSharingRule: WorkAccountDataAuthorizationType
  setEmailSharingRule: (rule: WorkAccountDataAuthorizationType) => void
  rulesByMeetingType: Record<string, string>
  setRulesByMeetingType: (rules: Record<string, string>) => void
  mode: 'CONNECT' | 'CONFIGURE'
  disabled?: boolean
}) => {
  return (
    <>
      <Row
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,
          mb: 2,
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
          <RiGroupLine style={{ width: '18px', height: '18px' }} />

          <Typography sx={{ fontSize: '0.8rem', fontWeight: 600, ml: 1 }}>
            People & Organizations
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              maxWidth: '550px',
              fontSize: '0.8rem',
              fontWeight: 400,
              ml: 3.3,
              textWrap: 'pretty',
            }}
          >
            Names, email addresses, and other contact info from your
            conversations {mode === 'CONNECT' ? 'will be' : 'are'} shared with
            the workspace
          </Typography>
        </Box>
      </Row>
      <Row
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,
          mb: 2,
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
          <RiMailLine style={{ width: '18px', height: '18px' }} />

          <Typography sx={{ fontSize: '0.8rem', fontWeight: 600, ml: 1 }}>
            Emails
          </Typography>
        </Box>
        <Box sx={{ pl: 3.3, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 1,
              width: '100%',
            }}
          >
            All emails
            <Select
              variant="outlined"
              size="small"
              value={emailSharingRule}
              disabled={disabled}
              renderValue={(value) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '85px',
                  }}
                >
                  {value === 'PRIVATE' ? (
                    <RiLockFill style={{ width: '12px', height: '12px' }} />
                  ) : (
                    <RiCommunityFill
                      style={{ width: '12px', height: '12px' }}
                    />
                  )}
                  <Box sx={{ ml: 0.8 }}>
                    {value === 'PRIVATE' ? 'Invite only' : 'Workspace'}
                  </Box>
                </Box>
              )}
              onChange={(event) => {
                setEmailSharingRule(
                  event.target.value as 'WORKSPACE' | 'PRIVATE'
                )
              }}
              sx={{
                '& .MuiSelect-outlined': { py: '6px' },
                '& .MuiSelect-select': { fontSize: '0.7rem' },
                ml: 'auto',
              }}
            >
              {['PRIVATE', 'WORKSPACE'].map((rule) => (
                <MenuItem
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  key={rule}
                  value={rule}
                >
                  {rule === 'PRIVATE' ? (
                    <RiLockFill style={{ width: '18px', height: '18px' }} />
                  ) : (
                    <RiCommunityFill
                      style={{ width: '18px', height: '18px' }}
                    />
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 500,
                      }}
                    >
                      {rule === 'PRIVATE' ? 'Invite only' : 'Workspace'}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        color: (theme) => theme.palette.text.secondary,
                        maxWidth: '260px',
                        textWrap: 'pretty',
                      }}
                    >
                      {rule === 'PRIVATE'
                        ? `Private to you by default`
                        : `Shared with the workspace by default`}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Row>
      <Row
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2,

          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
          <RiCalendarEventLine style={{ width: '18px', height: '18px' }} />

          <Typography sx={{ fontSize: '0.8rem', fontWeight: 600, ml: 1 }}>
            Meetings
          </Typography>
        </Box>
        <Box sx={{ pl: 3.3, width: '100%' }}>
          {Object.entries(rulesByMeetingType)
            .sort(
              (a, b) =>
                MEETING_TYPE_DISPLAY_METADATA[a[0]].order -
                MEETING_TYPE_DISPLAY_METADATA[b[0]].order
            )
            .map(([meetingType, initialRule]) => (
              <Box
                key={meetingType}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  width: '100%',
                }}
              >
                {MEETING_TYPE_DISPLAY_METADATA[meetingType].displayName}

                <Select
                  variant="outlined"
                  size="small"
                  value={initialRule}
                  disabled={disabled}
                  renderValue={(value) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        minWidth: '85px',
                      }}
                    >
                      {value === 'PRIVATE' ? (
                        <RiLockFill style={{ width: '12px', height: '12px' }} />
                      ) : (
                        <RiCommunityFill
                          style={{ width: '12px', height: '12px' }}
                        />
                      )}
                      <Box sx={{ ml: 0.8 }}>
                        {value === 'PRIVATE' ? 'Invite only' : 'Workspace'}
                      </Box>
                    </Box>
                  )}
                  onChange={(event) => {
                    setRulesByMeetingType({
                      ...rulesByMeetingType,
                      [meetingType]: event.target.value,
                    })
                  }}
                  sx={{
                    '& .MuiSelect-outlined': { py: '6px' },
                    '& .MuiSelect-select': { fontSize: '0.7rem' },
                    ml: 'auto',
                  }}
                >
                  {['PRIVATE', 'WORKSPACE'].map((rule) => (
                    <MenuItem
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      key={rule}
                      value={rule}
                    >
                      {rule === 'PRIVATE' ? (
                        <RiLockFill style={{ width: '18px', height: '18px' }} />
                      ) : (
                        <RiCommunityFill
                          style={{ width: '18px', height: '18px' }}
                        />
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          ml: 1.5,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            fontWeight: 500,
                          }}
                        >
                          {rule === 'PRIVATE' ? 'Invite only' : 'Workspace'}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '0.7rem',
                            color: (theme) => theme.palette.text.secondary,
                            maxWidth: '260px',
                            textWrap: 'pretty',
                          }}
                        >
                          {rule === 'PRIVATE'
                            ? `Private to you by default`
                            : `Shared with the workspace by default`}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ))}
        </Box>
      </Row>
    </>
  )
}

const ConfigureConnectedWorkspaceSubDialog = ({
  workAccount,
  workspace,
  existingRules,
  onBack,
  workAccountsQuery,
}: {
  workAccount: WorkAccount
  workspace: Workspace
  existingRules: WorkAccountSharingRule[]
  onBack: () => any
  workAccountsQuery: DocumentNode
}) => {
  const { currentUser } = useAuth()
  const { refetchWorkAccountWorkspaceConnections } = useContext(DayContext)

  const [updateConfiguration] = useMutation<UpdateWorkAccountWorkspaceSettings>(
    UPDATE_SHARING_SETTINGS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: workAccountsQuery,
          variables: { ownerEmail: currentUser.email as string },
        },
      ],
    }
  )

  const [disconnectFromWorkspace] =
    useMutation<DisconnectWorkAccountFromWorkspace>(DISCONNECT_FROM_WORKSPACE, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: workAccountsQuery,
          variables: { ownerEmail: currentUser.email as string },
        },
      ],
    })

  const [confirmingDisconnect, setConfirmingDisconnect] = useState(false)
  const [savingDisconnect, setSavingDisconnect] = useState(false)

  const existingEmailRule = existingRules.find(
    (rule) => rule.category === 'EMAILS' && rule.selector.type === 'ALL'
  ).authorization.type

  const existingMeetingTypeRules = existingRules.reduce((acc, rule) => {
    if (rule.category === 'MEETINGS' && rule.selector.type === 'MEETING_TYPE') {
      acc[rule.selector.expression] = rule.authorization.type
    }
    return acc
  }, {})

  const [savingConfiguration, setSavingConfiguration] = useState(false)

  const [newEmailSharingRule, setNewEmailSharingRule] =
    useState<WorkAccountDataAuthorizationType>(existingEmailRule)

  const [newRulesByMeetingType, setNewRulesByMeetingType] = useState<
    Record<string, string>
  >(existingMeetingTypeRules)

  const hasChangedMeetingSettings = Object.entries(
    existingMeetingTypeRules
  ).some(([meetingType, rule]) => rule !== newRulesByMeetingType[meetingType])

  const hasChangedEmailSettings = newEmailSharingRule !== existingEmailRule

  const hasChangedSettings =
    hasChangedMeetingSettings || hasChangedEmailSettings

  if (confirmingDisconnect) {
    return (
      <>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: (theme) => theme.palette.error.main,
            }}
          >
            <RiLinkUnlinkM
              style={{ height: '20px', width: '20px', marginRight: '8px' }}
            />
            Disconnect {workspace.name}
          </Box>
        </DialogTitle>

        <DialogContent sx={{ width: '500px' }}>
          <Typography
            sx={{ textWrap: 'pretty', fontSize: '0.8rem', fontWeight: 500 }}
          >
            Are you sure you want to disconnect {workspace.name}?
            <br />
            <br />
            <b>No future conversations</b>{' '}
            {`will be sent to ${workspace.name}. Past conversations will remain available.`}
            <br />
            <br />
            You can remove past conversations by finding them in your Library.
          </Typography>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={() => {
              setConfirmingDisconnect(false)
            }}
            disabled={savingDisconnect}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            onClick={async () => {
              setSavingDisconnect(true)
              try {
                await toast.promise(
                  disconnectFromWorkspace({
                    variables: {
                      input: {
                        workAccountUuid: workAccount.uuid,
                        workspaceId: workspace.id,
                      },
                    },
                  }).then(() => refetchWorkAccountWorkspaceConnections()),
                  {
                    loading: 'Disconnecting...',
                    success: 'Disconnected',
                    error: 'Error disconnecting workspace',
                  }
                )
              } catch (_) {
                /* empty */
              }

              onBack()
            }}
            color="error"
            variant="contained"
            disabled={savingDisconnect}
          >
            Disconnect
          </Button>
        </DialogActions>
      </>
    )
  } else {
    return (
      <>
        <DialogTitle>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <RiLinkM
              style={{ height: '20px', width: '20px', marginRight: '8px' }}
            />
            {workspace.name}
          </Box>
        </DialogTitle>

        <DialogContent sx={{ width: '500px' }}>
          <WorkspaceConnectionForm
            emailSharingRule={newEmailSharingRule}
            setEmailSharingRule={(rule) => setNewEmailSharingRule(rule)}
            rulesByMeetingType={newRulesByMeetingType}
            setRulesByMeetingType={(rules) => setNewRulesByMeetingType(rules)}
            mode="CONFIGURE"
            disabled={savingConfiguration}
          />

          <Typography
            sx={{
              pl: 0.2,
              mt: 1.2,
              fontSize: '0.7rem',
              fontWeight: 400,
              textWrap: 'pretty',
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            Changes you make here only apply to future conversations
          </Typography>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={() => {
              setConfirmingDisconnect(true)
            }}
            disabled={savingConfiguration}
            startIcon={
              <RiLinkUnlinkM style={{ height: '18px', width: '18px' }} />
            }
            variant="outlined"
            color="error"
            sx={{ mr: 'auto' }}
          >
            Disconnect
          </Button>
          <Button
            onClick={() => {
              onBack()
            }}
            disabled={savingConfiguration}
            color="primary"
          >
            Back
          </Button>

          <Button
            onClick={async () => {
              setSavingConfiguration(true)
              try {
                await toast.promise(
                  updateConfiguration({
                    variables: {
                      input: {
                        workAccountUuid: workAccount.uuid,
                        workspaceId: workspace.id,
                        sharingRules: [
                          ...(hasChangedEmailSettings
                            ? [
                                {
                                  category: 'EMAILS',
                                  selector: { type: 'ALL', expression: '*' },
                                  authorization: { type: newEmailSharingRule },
                                },
                              ]
                            : []),
                          ...(hasChangedMeetingSettings
                            ? Object.entries(newRulesByMeetingType).map(
                                ([meetingType, rule]) => ({
                                  category: 'MEETINGS',
                                  selector: {
                                    type: 'MEETING_TYPE',
                                    expression: meetingType,
                                  },
                                  authorization:
                                    rule === 'PRIVATE'
                                      ? { type: 'PRIVATE' }
                                      : { type: 'WORKSPACE' },
                                })
                              )
                            : []),
                        ],
                      },
                    },
                  }).then(() => refetchWorkAccountWorkspaceConnections()),
                  {
                    loading: 'Saving...',
                    success: 'Saved',
                    error: 'Error saving configuration',
                  }
                )
              } catch (_) {
                /* empty */
              }

              onBack()
            }}
            color="primary"
            variant="contained"
            disabled={savingConfiguration || !hasChangedSettings}
          >
            Save
          </Button>
        </DialogActions>
      </>
    )
  }
}

const ConnectNewWorkspaceSubDialog = ({
  workAccount,
  workspace,
  onBack,
  workAccountsQuery,
}: {
  workAccount: WorkAccount
  workspace: Workspace
  onBack: () => any
  workAccountsQuery: DocumentNode
}) => {
  const { currentUser } = useAuth()
  const { refetchWorkAccountWorkspaceConnections } = useContext(DayContext)
  const [connectToWorkspace] = useMutation<ConnectWorkAccountToWorkspace>(
    CONNECT_TO_WORKSPACE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: workAccountsQuery,
          variables: { ownerEmail: currentUser.email as string },
        },
      ],
    }
  )

  const [savingConnection, setSavingConnection] = useState(false)
  const [initialEmailSharingRule, setInitialEmailSharingRule] =
    useState<WorkAccountDataAuthorizationType>('PRIVATE')
  const [initialRulesByMeetingType, setInitialRulesByMeetingType] = useState<
    Record<string, string>
  >({
    INTERNAL_ONE_ON_ONE: 'PRIVATE',
    INTERNAL_GROUP: 'WORKSPACE',
    EXTERNAL_ONE_ON_ONE: 'WORKSPACE',
    EXTERNAL_GROUP: 'WORKSPACE',
  })

  return (
    <>
      <DialogTitle>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <RiAddLine
            style={{ height: '20px', width: '20px', marginRight: '8px' }}
          />{' '}
          Connect to {workspace.name}
        </Box>
      </DialogTitle>

      <DialogContent sx={{ width: '500px' }}>
        <WorkspaceConnectionForm
          emailSharingRule={initialEmailSharingRule}
          setEmailSharingRule={(rule) => setInitialEmailSharingRule(rule)}
          rulesByMeetingType={initialRulesByMeetingType}
          setRulesByMeetingType={(rules) => setInitialRulesByMeetingType(rules)}
          mode="CONNECT"
          disabled={savingConnection}
        />

        <Typography
          sx={{
            pl: 0.2,
            mt: 1.2,
            fontSize: '0.7rem',
            fontWeight: 400,
            textWrap: 'pretty',
            color: (theme) => theme.palette.text.disabled,
          }}
        >
          You can come back and adjust these settings at any time
        </Typography>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button
          onClick={() => {
            onBack()
          }}
          disabled={savingConnection}
          color="primary"
        >
          Back
        </Button>

        <Button
          onClick={async () => {
            setSavingConnection(true)
            try {
              await toast.promise(
                connectToWorkspace({
                  variables: {
                    input: {
                      workAccountUuid: workAccount.uuid,
                      workspaceId: workspace.id,
                      sharingRules: Object.entries(initialRulesByMeetingType)
                        .map(([meetingType, rule]) => ({
                          category: 'MEETINGS',
                          selector: {
                            type: 'MEETING_TYPE',
                            expression: meetingType,
                          },
                          authorization:
                            rule === 'PRIVATE'
                              ? { type: 'PRIVATE' }
                              : { type: 'WORKSPACE' },
                        }))
                        .concat({
                          category: 'EMAILS',
                          selector: { type: 'ALL', expression: '*' },
                          authorization: { type: initialEmailSharingRule },
                        }),
                    },
                  },
                }).then(() => refetchWorkAccountWorkspaceConnections()),
                {
                  loading: 'Connecting...',
                  success: 'Connected',
                  error: 'Error connecting to workspace',
                }
              )
            } catch (_) {
              /* empty */
            }

            onBack()
          }}
          color="primary"
          variant="contained"
          disabled={savingConnection}
        >
          Connect
        </Button>
      </DialogActions>
    </>
  )
}

const ConnectedWorkspacesDialog = ({
  workAccount,
  workAccountWorkspaceConnections,
  open,
  onClose,
  workAccountsQuery,
}: {
  workAccount: WorkAccount
  workAccountWorkspaceConnections: WorkAccountWorkspaceConnection
  open: boolean
  onClose: () => any
  workAccountsQuery: DocumentNode
}) => {
  const { workspaces } = useContext(DayContext)

  const [configuringWorkspaceId, setConfiguringWorkspaceId] = useState(null)
  const [connectingWorkspaceId, setConnectingWorkspaceId] = useState(null)

  const connectedWorkspaceIds =
    workAccountWorkspaceConnections?.connectedWorkspaces?.map(
      ({ workspaceId }) => workspaceId
    )
  const connectedWorkspaces = workspaces.filter(({ id }) =>
    connectedWorkspaceIds?.includes(id)
  )
  const connectableWorkspaces = workspaces.filter(
    ({ id, status }) =>
      status === 'ACTIVE' && !connectedWorkspaceIds?.includes(id)
  )

  const connectingWorkspace =
    connectingWorkspaceId &&
    connectableWorkspaces?.find(({ id }) => id === connectingWorkspaceId)

  const configuringWorkspace =
    configuringWorkspaceId &&
    connectedWorkspaces.find(({ id }) => id === configuringWorkspaceId)

  return (
    <Dialog
      TransitionProps={{
        onExited: () => {
          setConnectingWorkspaceId(null)
          setConfiguringWorkspaceId(null)
        },
      }}
      open={open}
      onClose={() => {
        onClose()
      }}
    >
      {!configuringWorkspace && !connectingWorkspace && (
        <>
          <DialogContent sx={{ width: '500px' }}>
            {connectedWorkspaces.length > 0 && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <RiLinkM
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '8px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '1.1rem',
                    }}
                  >
                    Configure a connected workspace
                  </Typography>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
                  {connectedWorkspaces.map((connectedWorkspace) => (
                    <CardButton
                      key={connectedWorkspace.id}
                      primaryText={connectedWorkspace.name}
                      actionText="Configure"
                      onClick={() => {
                        setConfiguringWorkspaceId(connectedWorkspace.id)
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
            {connectableWorkspaces.length > 0 && (
              <>
                <Box
                  sx={{
                    mt: connectedWorkspaces.length > 0 ? 3 : 0,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <RiAddLine
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '8px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '1.1rem',
                    }}
                  >
                    Add a workspace connection
                  </Typography>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
                  {connectableWorkspaces.map((connectableWorkspace) => (
                    <CardButton
                      key={connectableWorkspace.id}
                      primaryText={connectableWorkspace.name}
                      actionText="Connect"
                      onClick={() => {
                        setConnectingWorkspaceId(connectableWorkspace.id)
                      }}
                    />
                  ))}
                </Box>
              </>
            )}

            {/* {JSON.stringify(workAccountWorkspaceConnections, null, 2)} */}
          </DialogContent>

          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button
              onClick={() => {
                onClose()
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
      {configuringWorkspace && (
        <ConfigureConnectedWorkspaceSubDialog
          workAccount={workAccount}
          workspace={configuringWorkspace}
          existingRules={
            workAccountWorkspaceConnections.connectedWorkspaces.find(
              ({ workspaceId }) => workspaceId === configuringWorkspace.id
            ).sharingRules
          }
          onBack={() => setConfiguringWorkspaceId(null)}
          workAccountsQuery={workAccountsQuery}
        />
      )}
      {connectingWorkspace && (
        <ConnectNewWorkspaceSubDialog
          workAccount={workAccount}
          workspace={connectingWorkspace}
          onBack={() => setConnectingWorkspaceId(null)}
          workAccountsQuery={workAccountsQuery}
        />
      )}
    </Dialog>
  )
}

export default ConnectedWorkspacesDialog
