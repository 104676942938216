import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  lighten,
} from '@mui/material'
import { RiAddLine, RiArrowDownSLine, RiCloseLine } from '@remixicon/react'
import { StageUpdateInput } from 'types/graphql'

import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'
import { NativeSuggestedPipelineStages } from 'src/lib/relationshipSummary'

import { getStageColor } from '../Pipeline'

/*
  input StageUpdateInput {
    id: String!
    workspaceId: String
    pipelineId: String
    position: Int
    description: String
    color: String
    activities: [String!]
    title: String
    entranceCriteria: [String!]
    likelihoodToClose: Float
    type: StageType
  }
*/

const PipelineEditStage = ({
  stage,
  previousStage,
  onUpdate,
  openOnLoad,
}: {
  stage: StageUpdateInput
  previousStage?: StageUpdateInput
  onUpdate: (stage: StageUpdateInput) => void
  openOnLoad?: boolean
}) => {
  const previousLikelihoodToClose = previousStage?.likelihoodToClose || 0

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...stage, title: e.target.value })
  }

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...stage, description: e.target.value })
  }
  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...stage, color: e.target.value })
  }
  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...stage, type: e.target.value })
  }

  const isValidLikelihoodToClose = (value: number) => {
    return value >= 0 && value <= 1 && value >= previousLikelihoodToClose
  }
  const handleLikelihoodToCloseChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value) || 0

    const floatValue = (value <= 100 ? value : 100) / 100
    onUpdate({ ...stage, likelihoodToClose: floatValue })
  }
  const handleAddActivity = () => {
    onUpdate({ ...stage, activities: [...stage.activities, ''] })
  }
  const handleRemoveActivity = (index: number) => {
    onUpdate({
      ...stage,
      activities: stage.activities.filter((_, i) => i !== index),
    })
  }
  const handleUpdateActivity = (index: number, value: string) => {
    onUpdate({
      ...stage,
      activities: stage.activities.map((activity, i) =>
        i === index ? value : activity
      ),
    })
  }
  const handleRemoveEntranceCriteria = (index: number) => {
    onUpdate({
      ...stage,
      entranceCriteria: stage.entranceCriteria.filter((_, i) => i !== index),
    })
  }
  const handleUpdateEntranceCriteria = (index: number, value: string) => {
    onUpdate({
      ...stage,
      entranceCriteria: stage.entranceCriteria.map((criteria, i) =>
        i === index ? value : criteria
      ),
    })
  }

  const handleAddEntranceCriteria = () => {
    onUpdate({ ...stage, entranceCriteria: [...stage.entranceCriteria, ''] })
  }

  logger.dev({ openOnLoad, stageId: stage.id })

  return (
    stage && (
      <Accordion
        elevation={0}
        sx={{
          '&:before': {
            display: stage?.position === 0 ? 'none' : 'block',
          },
        }}
        defaultExpanded={openOnLoad}
      >
        <AccordionSummary expandIcon={<RiArrowDownSLine size={20} />}>
          <Typography variant="h2">{stage.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Row
            sx={{ flexDirection: 'column', width: '100%' }}
            gap={2}
          >
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Title
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Describe the stage in a word or two (or three)
                </Typography>
              </Box>
              <TextField
                value={stage.title}
                fullWidth={true}
                onChange={handleTitleChange}
              />
            </Row>
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography variant="h3">Description</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Describe the state of the deal in this stage. Include any
                  details that may help Day.ai Assistant tailor recommendations
                  for opportunities in this stage.
                </Typography>
              </Box>
              <TextField
                value={stage.description}
                fullWidth={true}
                onChange={handleDescriptionChange}
                multiline={true}
                rows={4}
              />
            </Row>

            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Stage Type
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Setting the general stage of the process will help Day.ai
                  Assistant make better decisions and recommendations, as well
                  as accurately identify current paying customers.
                </Typography>
              </Box>
              <Select
                fullWidth={true}
                value={stage?.type || 'NONE'}
                onChange={handleTypeChange}
                error={!stage?.type}
                placeholder="Select a stage type"
              >
                {!stage?.type && (
                  <MenuItem value="NONE">Please select stage type ...</MenuItem>
                )}
                {Object.entries(NativeSuggestedPipelineStages)
                  .filter(([key, value]) => value.showInChooser)
                  .map(
                    ([key, value]) =>
                      key != 'NONE' && (
                        <MenuItem
                          key={key}
                          value={key}
                        >
                          {value?.label}
                        </MenuItem>
                      )
                  )}
              </Select>
            </Row>
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography variant="h3">Likelihood to Close</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  The likelihood that an opportunity will close in this stage.
                  Used in forecasting pipeline performance. Must be between 0
                  and 100, and greater than the previous stage.
                </Typography>
              </Box>
              <TextField
                value={parseInt(stage.likelihoodToClose * 100)}
                fullWidth={true}
                InputProps={{
                  endAdornment: '%',
                }}
                onChange={handleLikelihoodToCloseChange}
                error={!isValidLikelihoodToClose(stage.likelihoodToClose)}
                helperText={
                  !isValidLikelihoodToClose(stage.likelihoodToClose)
                    ? `Must be between 0 and 100, and greater than the previous stage (${parseInt(
                        previousLikelihoodToClose * 100
                      )}%)`
                    : ''
                }
              />
            </Row>
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography variant="h3">Color</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  (Not currently editable)
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: lighten(getStageColor(stage.position), 0.4),
                  width: '100%',
                  height: '16px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Row>

            <Row
              sx={{ width: '100%', mb: 3, alignItems: 'top' }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Row sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="h3">Entrance Criteria</Typography>

                  <Button
                    variant="text"
                    size="small"
                    disableElevation={true}
                    startIcon={<RiAddLine size={16} />}
                    onClick={handleAddEntranceCriteria}
                  >
                    Add
                  </Button>
                </Row>
                <Typography sx={{ color: 'text.secondary' }}>
                  Add criteria (gates) for facts that need to be true in order
                  for an Opportunity to enter this stage. This will help team
                  members qualify stage progression properly, as well as enhance
                  Day.ai's ability to automate opportunity progression.
                </Typography>
              </Box>
              <Row
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                gap={2}
              >
                {stage?.entranceCriteria?.length > 0 ? (
                  <>
                    {stage.entranceCriteria.map((activity, index) => (
                      <Row
                        key={`stage-${stage.id}-entrance-criteria-${index}`}
                        sx={{
                          justifyContent: 'space-between',
                          width: '100%',
                          mb: 2,
                        }}
                        gap={2}
                      >
                        <TextField
                          fullWidth={true}
                          key={`entrance-criteria-${index}`}
                          value={activity}
                          onChange={(e) =>
                            handleUpdateEntranceCriteria(index, e.target.value)
                          }
                        />
                        <IconButton
                          onClick={() => handleRemoveEntranceCriteria(index)}
                          sx={{ borderRadius: '3px' }}
                        >
                          <RiCloseLine size={24} />
                        </IconButton>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Typography></Typography>
                )}
              </Row>
            </Row>
            <Row
              sx={{ width: '100%', mb: 3, alignItems: 'top' }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Row sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="h3">Activities</Typography>

                  <Button
                    variant="text"
                    size="small"
                    disableElevation={true}
                    startIcon={<RiAddLine size={16} />}
                    onClick={handleAddActivity}
                  >
                    Add
                  </Button>
                </Row>
                <Typography sx={{ color: 'text.secondary' }}>
                  Add activities for people working this pipeline, including
                  questions you need answers for, documents that need to be
                  created, etc. This also helps Day.ai Assistant create better
                  documents & recommendations.
                </Typography>
              </Box>
              <Row
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                gap={2}
              >
                {stage?.activities?.length > 0 ? (
                  <>
                    {stage.activities.map((activity, index) => (
                      <Row
                        key={`stage-${stage.id}-instruction-${index}`}
                        sx={{
                          justifyContent: 'space-between',
                          width: '100%',
                          mb: 2,
                        }}
                        gap={2}
                      >
                        <TextField
                          fullWidth={true}
                          key={`instruction-${index}`}
                          value={activity}
                          onChange={(e) =>
                            handleUpdateActivity(index, e.target.value)
                          }
                        />
                        <IconButton
                          onClick={() => handleRemoveActivity(index)}
                          sx={{ borderRadius: '3px' }}
                        >
                          <RiCloseLine size={24} />
                        </IconButton>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Typography></Typography>
                )}
              </Row>
            </Row>
          </Row>
        </AccordionDetails>
      </Accordion>
    )
  )
}

export default PipelineEditStage
