import { useContext } from 'react'

import { TextField, Button, Grid, Box } from '@mui/material'
import { RiSidebarUnfoldLine } from '@remixicon/react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

import LogoLoader from '../../LogoLoader/LogoLoader'

const GET_CONTACT = gql`
  query GetCoreContact($email: String!) {
    coreContact(email: $email) {
      firstName
      lastName
      username
      title
      description
      department
      industry
      linkedInUrl
      photo
      summary
      timezone
    }
  }
`

const GET_CONTACT_BY_EMAIL_PROFILE = gql`
  query getContactByEmail($ownerEmail: String!, $contactEmail: String!) {
    getContactByEmail(ownerEmail: $ownerEmail, contactEmail: $contactEmail) {
      objectType
      objectId
      properties
    }
  }
`

const UPDATE_CONTACT = gql`
  mutation UpdateSelfCoreContact(
    $email: String!
    $input: UpdateCoreContactInput!
  ) {
    updateCoreContact(email: $email, input: $input) {
      id
    }
  }
`

const CoreContactSelf = ({
  email,
  showDescription = false,
  showPreview = false,
  autoUpdateTimezone = true,
  callback = () => {},
}) => {
  const { setSidebarObject } = useContext(DayContext)

  const { data: ccData, loading } = useQuery(GET_CONTACT, {
    variables: { email },
  })

  const { data: contactByEmailData } = useQuery(GET_CONTACT_BY_EMAIL_PROFILE, {
    variables: { ownerEmail: email, contactEmail: email },
  })

  const [updateContact, { error }] = useMutation(UPDATE_CONTACT)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleFormSubmit = async (data) => {
    if (data.username) {
      data.username = data.username.toLowerCase()
    }

    if (
      data.linkedInUrl &&
      (!data.photo || !data.firstName || !data.lastName)
    ) {
      const liData = null

      if (liData) {
        data.photo = liData.photo_url ? liData.photo_url : data.photo
        data.description = data.description
          ? data.description
          : liData.description
        data.firstName = data.firstName ? data.firstName : liData.first_name
        data.lastName = data.lastName ? data.lastName : liData.last_name
        data.linkedInUrl = liData.linkedInUrl
      }
    }

    if (data.careerHistory) {
      data.summary = {
        ...(data.summary || {}),
        careerHistory: data.careerHistory,
      }
    }

    delete data.careerHistory

    data.manuallyVerified = new Date().toISOString()
    data.verifiedBy = email
    data.verificationNotes = 'Self-verification by Day.ai user'

    if (autoUpdateTimezone) {
      data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    await toast.promise(updateContact({ variables: { email, input: data } }), {
      loading: 'Updating contact...',
      success: () => {
        callback()
        return 'Contact updated successfully!'
      },
      error: 'Error updating contact.',
    })
  }

  if (loading) {
    return <LogoLoader />
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    !loading &&
    ccData && (
      <>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {showPreview && (
            <TextField
              {...register('email')}
              defaultValue={email}
              label="Email"
              fullWidth
              disabled
              sx={{ mb: 3 }}
            />
          )}
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <TextField
                {...register('firstName')}
                defaultValue={ccData.coreContact.firstName}
                label="First Name"
                fullWidth
                sx={{ mb: 3 }}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                {...register('lastName')}
                defaultValue={ccData.coreContact.lastName}
                label="Last Name"
                fullWidth
                sx={{ mb: 3 }}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                {...register('title')}
                defaultValue={ccData.coreContact.title}
                label="Title"
                fullWidth
                sx={{ mb: 3 }}
              />
            </Grid>

            <Grid
              item
              xs={6}
            >
              <TextField
                {...register('linkedInUrl')}
                defaultValue={ccData.coreContact.linkedInUrl}
                label="LinkedIn Profile"
                required={true}
                fullWidth
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: ccData.coreContact.linkedInUrl && (
                    <Box
                      component="img"
                      src={ccData.coreContact.photo}
                      sx={{
                        width: '36px',
                        height: '36px',
                        borderRadius: 1,
                        mr: 1,
                      }}
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
          {showDescription && (
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >
                <TextField
                  {...register('description')}
                  defaultValue={ccData.coreContact.description}
                  label="Bio"
                  multiline={true}
                  rows={10}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  {...register('careerHistory')}
                  defaultValue={ccData.coreContact.summary?.careerHistory}
                  label="Career History"
                  multiline={true}
                  rows={10}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </Grid>
            </Grid>
          )}
          <TextField
            {...register('department')}
            defaultValue={ccData.coreContact.department}
            label="Department"
            fullWidth
            sx={{ mb: 3, display: 'none' }}
          />
          <TextField
            {...register('industry')}
            defaultValue={ccData.coreContact.industry}
            label="Industry"
            fullWidth
            sx={{ display: 'none' }}
          />
          <TextField
            {...register('photo')}
            defaultValue={ccData.coreContact.photo}
            fullWidth
            sx={{ display: 'none' }}
          />
          <Button
            type="submit"
            variant={'contained'}
            color="secondary"
            fullWidth={true}
            disableElevation={true}
            sx={{ height: '54px' }}
          >
            Update
          </Button>
        </form>
        {showPreview && (
          <Button
            size="small"
            fullWidth={true}
            startIcon={<RiSidebarUnfoldLine />}
            onClick={() =>
              setSidebarObject(contactByEmailData.getContactByEmail)
            }
            sx={{ mt: 3 }}
          >
            preview your contact in the Day.ai sidebar
          </Button>
        )}
      </>
    )
  )
}

export default CoreContactSelf
