import { useEffect, useRef, useState } from 'react'

import { Box, Card, Typography } from '@mui/material'
import { BarChart } from '@mui/x-charts/BarChart'
import { RiFileChartLine } from '@remixicon/react'

import { BoxResize } from 'src/components/BoxResize/BoxResize'
import Row from 'src/components/Row/Row'
import { toCurrencyDisplay } from 'src/lib/formatters'

import { preparePipelineChartData } from '../pipelineData'

const PipelineSeriesIds = {
  Won: 'opportunity-forecast-series-won',
  Forecast: 'opportunity-forecast-series-forecast',
  Pipeline: 'opportunity-forecast-series-pipeline',
}

export const PipelineSeriesTimePeriods = {
  Month: 'month',
  Quarter: 'quarter',
}

const PipelineChartTooltip = (item) => {
  const xIndex = item?.axisData?.x?.index
  if (xIndex === undefined) return null
  const pipelineSeries = item.series.find(
    (serie) => serie.id === PipelineSeriesIds.Pipeline
  )
  const wonSeries = item.series.find(
    (serie) => serie.id === PipelineSeriesIds.Won
  )
  const forecastSeries = item.series.find(
    (serie) => serie.id === PipelineSeriesIds.Forecast
  )

  const statLabelStyle = {
    fontWeight: 300,
    fontSize: '0.7rem',
    pr: 5,
  }
  const statStyle = {
    fontWeight: 300,
    fontSize: '0.7rem',
  }
  const rowStyle = {
    height: '16px',
    justifyContent: 'space-between',
    mb: '14px',
  }
  const iconBoxStyle = {
    height: '14px',
    width: '14px',
    borderRadius: '2px',
    ml: '2px',
    mr: '11px',
  }
  return (
    <Card
      sx={{
        background: (theme) => theme.palette.background.paper,
        position: 'relative',
        top: '-32px',
        left: '24px',
        p: 0,
      }}
    >
      <Row
        sx={{
          py: 1,
          px: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <RiFileChartLine style={{ width: '18px', marginRight: '8px' }} />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.9rem',
          }}
        >
          {item.axisValue}
        </Typography>
      </Row>
      <Box
        sx={{
          pt: 2,
          px: 2,
          pb: 1,
        }}
      >
        <Row sx={rowStyle}>
          <Row>
            <Box sx={{ ...iconBoxStyle, background: pipelineSeries.color }} />
            <Typography sx={statLabelStyle}>Total Pipeline</Typography>
          </Row>
          <Typography sx={statStyle}>
            {toCurrencyDisplay(
              pipelineSeries.data[xIndex] +
                wonSeries.data[xIndex] +
                forecastSeries.data[xIndex]
            )}
          </Typography>
        </Row>
        <Row sx={rowStyle}>
          <Row>
            <Box sx={{ ...iconBoxStyle, background: forecastSeries.color }} />
            <Typography
              sx={{ ...statLabelStyle, fontWeight: 600, fontSize: '0.75rem' }}
            >
              Current forecast
            </Typography>
          </Row>
          <Typography
            sx={{ ...statStyle, fontWeight: 600, fontSize: '0.75rem' }}
          >
            {toCurrencyDisplay(
              wonSeries.data[xIndex] + forecastSeries.data[xIndex]
            )}
          </Typography>
        </Row>
        <Row sx={rowStyle}>
          <Row>
            <Box sx={{ ...iconBoxStyle, background: wonSeries.color }} />
            <Typography sx={statLabelStyle}>Won so far</Typography>
          </Row>
          <Typography sx={statStyle}>
            {toCurrencyDisplay(wonSeries.data[xIndex])}
          </Typography>
        </Row>
      </Box>
    </Card>
  )
}

const PipelineForecastChart = ({
  stages,
  timePeriod = PipelineSeriesTimePeriods.Month,
  brandColor = '#02B2AF',
  maxHeight = 600,
}) => {
  const chartRef = useRef(null)
  const { data, xAxisLabels } = preparePipelineChartData({
    stages,
    timePeriod,
  })

  const [dimensions, setDimensions] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: chartRef.current.offsetWidth,
        height:
          chartRef.current.offsetHeight < maxHeight
            ? chartRef.current.offsetHeight
            : maxHeight,
      })
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chartRef])

  return (
    <Box
      ref={chartRef}
      sx={{ height: '320px', width: '100%', overflow: 'visible' }}
    >
      <BoxResize>
        {(dimens) => (
          <BarChart
            {...dimens}
            sx={{ pl: 0 }}
            series={[
              {
                data: data[PipelineSeriesIds.Won],
                label: 'Won',
                id: PipelineSeriesIds.Won,
                stack: 'total',
              },
              {
                data: data[PipelineSeriesIds.Forecast],
                label: 'Forecast',
                id: PipelineSeriesIds.Forecast,
                stack: 'total',
              },
              {
                data: data[PipelineSeriesIds.Pipeline],
                label: 'Pipeline',
                id: PipelineSeriesIds.Pipeline,
                stack: 'total',
              },
            ]}
            xAxis={[{ data: xAxisLabels, scaleType: 'band' }]}
            yAxis={[
              {
                id: 'mainAxis',
                scaleType: 'linear',
                valueFormatter: toCurrencyDisplay,
              },
            ]}
            leftAxis="mainAxis"
            colors={['#37352f', brandColor, '#EEEEEE']}
            slots={{
              axisContent: PipelineChartTooltip,
            }}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0,
                hidden: true,
              },
            }}
          />
        )}
      </BoxResize>
    </Box>
  )
}

export default PipelineForecastChart
