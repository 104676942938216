import { useMemo } from 'react'

import { Chip } from '@mui/material'
import { RiCalendarLine } from '@remixicon/react'
import { Organization } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'
import { ellipsize } from 'src/lib/formatters'

const OrganizationNextMeetingChip = ({ org }: { org: Organization }) => {
  const nextMeeting = useMemo(() => {
    const nm = org?.overview?.['upcomingEvents']?.[0]
    if (nm) {
      const startDt = dayjs(nm.start_time)
      if (startDt.isAfter(dayjs())) {
        return nm
      }
    }
    return null
  }, [org])

  return nextMeeting ? (
    <Chip
      size="small"
      variant="outlined"
      icon={<RiCalendarLine size={14} />}
      label={`Next meeting: "${ellipsize(nextMeeting.title, 20)}" on ${
        nextMeeting.start_time
      }`}
      sx={{
        fontSize: '0.7rem',
        fontWeight: 500,
      }}
    />
  ) : (
    <Chip
      size="small"
      variant="outlined"
      icon={<RiCalendarLine size={14} />}
      label={'No next meeting scheduled'}
      sx={{
        fontSize: '0.7rem',
        fontWeight: 500,
        pl: '4px',
      }}
    />
  )
}

export default OrganizationNextMeetingChip
