import { Box, Typography } from '@mui/material'
import { Organization } from 'types/graphql'

import Row from 'src/components/Row/Row'
import { sidebarBodyTextStyle } from 'src/components/Sidebar/Sidebar'

import OrganizationNextMeetingChip from '../OrganizationNextMeetingChip/OrganizationNextMeetingChip'

const OrganizationOneSentence = ({ org }: { org: Organization }) => {
  return (
    org?.overview?.['status/currentStatusOneSentence'] && (
      <Box sx={{ mb: 5 }}>
        <Row sx={{ justifyContent: 'space-between', pb: 1 }}>
          <Typography variant="h2">Status</Typography>
          <OrganizationNextMeetingChip org={org} />
        </Row>
        <Typography sx={sidebarBodyTextStyle}>
          {org?.overview?.['status/currentStatusOneSentence']}
        </Typography>
      </Box>
    )
  )
}

export default OrganizationOneSentence
