import { useContext, useEffect, useState } from 'react'

import { Box, GlobalStyles, List, ListItemButton } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { RiBarChart2Line, RiGlassesLine, RiSearch2Line } from '@remixicon/react'
import ReactGA from 'react-ga4'
import { Toaster } from 'react-hot-toast'
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon'
import SearchIcon from 'remixicon-react/MagicLineIcon'
import StackLineIcon from 'remixicon-react/StackLineIcon'
import { v4 as uuid } from 'uuid'

import { routes, Link, navigate } from '@redwoodjs/router'
import { useLocation } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router'

import ObjectFinder from 'src/components/ObjectFinder/ObjectFinder'
import Row from 'src/components/Row/Row'
import Sidebar from 'src/components/Sidebar/Sidebar'
import UserErrorRemediation from 'src/components/UserErrorRemediation/UserErrorRemediation'
import WorkspaceInviteBanner from 'src/components/WorkspaceInviteBanner/WorkspaceInviteBanner'
import ProfileController from 'src/layouts/ModernLayout/ProfileController/ProfileController'
import WorkspaceDropdown from 'src/layouts/ModernLayout/WorkspaceDropdown/WorkspaceDropdown'
import { AnalyticsActions, AnalyticsCategories } from 'src/lib/analytics'
import { DayContext, DayProvider } from 'src/lib/dayContext'
import { ungatedForCrm3, ungatedForSearch } from 'src/lib/gates'
import { logger } from 'src/lib/logger'

import PageTree from '../../components/Page/PageTree/PageTree'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
  nonce: uuid(),
})

type ModernLayoutProps = {
  children?: React.ReactNode
}

export const headerStyle = {
  fontWeight: 600,
  fontSize: '0.8rem',
  ml: '20px',
  mt: 2,
  cursor: 'pointer',
}

const DrawerNavLabel = ({ label, collapsed }) => (
  <Box
    component={'span'}
    sx={{
      opacity: collapsed ? 0 : 1,
      transition: 'all 0.22s ease',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '1.4rem',
      fontSize: '0.9rem',
      ml: '10px',
      color: (theme) =>
        collapsed ? theme.palette.divider : theme.palette.text.primary,
    }}
  >
    {label}
  </Box>
)

const ModernLayoutInner = ({ children }: ModernLayoutProps) => {
  const [collapsed, setCollapsed] = useState(true)

  const iconStyle = {
    height: '20px',
    width: '20px',
    color: 'text.primary',
    opacity: collapsed ? 0.5 : 1,
    transition: 'all 0.3s ease',
    flexShrink: 0,
  }

  const {
    userCoreContact,
    sidebarObject,
    setSidebarObject,
    selectedWorkspace,
    workAccounts,
    workspaces,
    workAccountWorkspaceConnections,
  } = useContext(DayContext)

  const drawerWidth = 280
  const drawerWidthCollapsed = 64
  const drawerMargin = '20px'
  const listItemHeight = '64px'

  useEffect(() => {
    const handleMouseLeaveWindow = (event) => {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setCollapsed(true)
      }
    }

    document.addEventListener('mouseout', handleMouseLeaveWindow)

    return () => {
      document.removeEventListener('mouseout', handleMouseLeaveWindow)
    }
  }, [])

  useEffect(() => {
    const label = sidebarObject?.objectType || 'Unknown objectType'
    const category = AnalyticsCategories.SIDEBAR
    const action = AnalyticsActions.OPEN
    const event = {
      category,
      action,
      label,
    }

    if (sidebarObject) {
      ReactGA.event(event)
    }

    const handleMouseMove = (e) => {
      const sidebar = document.getElementById('sidebar')
      const workspaceDropdown = document.getElementById('workspace-select-menu')
      if (
        sidebar &&
        !sidebar.contains(e.target) &&
        !workspaceDropdown?.contains(e.target)
      ) {
        setCollapsed(true)
      }
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [sidebarObject, setCollapsed])

  const workspaceInvites = workspaces?.filter(
    (workspace) => workspace.status === 'INVITED'
  )

  const handleSearch = (searchItem) => {
    if (searchItem.objectType === 'page') {
      navigate(routes.pageDetail({ id: searchItem.objectId }))
    } else {
      setSidebarObject(searchItem)
    }
  }

  return (
    userCoreContact && (
      <>
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: (theme) =>
                `${theme.palette.background.paper} !important`,
            },
          }}
        />
        <Toaster />
        <CssBaseline />

        <Sidebar
          key={sidebarObject?.objectId || 'empty'}
          crmObject={sidebarObject}
          onClose={() => {}}
        />
        <Box
          sx={{
            color: (theme) => theme.palette.text.primary,
            background: (theme) => theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'row',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <Box
            id="sidebar"
            sx={{
              color: (theme) => theme.palette.text.primary,
              background: (theme) =>
                collapsed
                  ? theme.palette.background.paper
                  : theme.palette.background.default,
              borderRight: (theme) =>
                collapsed ? `1px solid ${theme.palette.divider}` : 'none',
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '100vh',
              width: collapsed ? drawerWidthCollapsed : drawerWidth,
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 3,
              transition: 'all 0.22s ease',
              boxShadow: collapsed
                ? 'none'
                : `0 1px 1px rgba(0,0,0,0.12),
              0 2px 2px rgba(0,0,0,0.12),
              0 4px 4px rgba(0,0,0,0.12),
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12)`,
            }}
            component="div"
            onMouseOver={() => {
              setCollapsed(false)
            }}
            onMouseOut={() => {
              setCollapsed(true)
            }}
          >
            <WorkspaceDropdown collapsed={collapsed} />
            <Box>
              {collapsed ? (
                <Row sx={{ justifyContent: 'center', height: '64px' }}>
                  <Box>
                    <RiSearch2Line style={iconStyle} />
                  </Box>
                </Row>
              ) : (
                <ObjectFinder
                  onSelect={handleSearch}
                  placeholder="Search Day.ai"
                  sx={{
                    minHeight: '64px',
                    pt: 2,
                  }}
                  textFieldSx={{
                    px: '20px',
                    mx: 0,
                  }}
                  inputSx={{
                    fontWeight: 500,
                    fontSize: '0.9rem',
                  }}
                />
              )}
            </Box>
            <List
              disablePadding={true}
              sx={{ mt: 0 }}
              id="sidebar-nav-item-list"
            >
              {false &&
                userCoreContact &&
                ungatedForSearch.includes(userCoreContact.email) && (
                  <ListItemButton
                    disableGutters={true}
                    component={Link}
                    to={routes.search()}
                    selected={
                      window.location.pathname === routes.search() && !collapsed
                    }
                    sx={{
                      fontWeight: 500,
                      pl: drawerMargin,
                      height: listItemHeight,
                    }}
                  >
                    <SearchIcon style={iconStyle} />
                    <DrawerNavLabel
                      label="Search & Ask"
                      collapsed={collapsed}
                    />
                  </ListItemButton>
                )}
              <ListItemButton
                disableGutters={true}
                component={Link}
                to={routes.today()}
                selected={
                  window.location.pathname === routes.today() && !collapsed
                }
                sx={{
                  fontWeight: 500,
                  pl: drawerMargin,
                  height: listItemHeight,
                }}
              >
                <CalendarLineIcon style={iconStyle} />
                <DrawerNavLabel
                  label="Today"
                  collapsed={collapsed}
                />
              </ListItemButton>

              {ungatedForCrm3({ selectedWorkspace, workAccounts }) && (
                <>
                  <ListItemButton
                    disableGutters={true}
                    component={Link}
                    to={routes.prospects()}
                    selected={
                      window.location.pathname === routes.prospects() &&
                      !collapsed
                    }
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 500,
                      pl: drawerMargin,
                      height: listItemHeight,
                    }}
                  >
                    <RiGlassesLine style={iconStyle} />
                    <DrawerNavLabel
                      label="Prospects"
                      collapsed={collapsed}
                    />
                  </ListItemButton>
                  <ListItemButton
                    disableGutters={true}
                    component={Link}
                    to={routes.opportunities()}
                    selected={
                      window.location.pathname === routes.opportunities() &&
                      !collapsed
                    }
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 500,
                      pl: drawerMargin,
                      height: listItemHeight,
                    }}
                  >
                    <RiBarChart2Line style={iconStyle} />
                    <DrawerNavLabel
                      label="Opportunities"
                      collapsed={collapsed}
                    />
                  </ListItemButton>
                </>
              )}
              {workAccountWorkspaceConnections?.some(
                ({ connectedWorkspaces }) => connectedWorkspaces.length > 0
              ) ? (
                <ListItemButton
                  disableGutters={true}
                  component={Link}
                  to={routes.workspaceLibrary({
                    workspaceId: selectedWorkspace,
                  })}
                  selected={
                    window.location.pathname ===
                      routes.workspaceLibrary({
                        workspaceId: selectedWorkspace,
                      }) && !collapsed
                  }
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    pl: drawerMargin,
                    height: listItemHeight,
                  }}
                >
                  <StackLineIcon style={iconStyle} />
                  <DrawerNavLabel
                    label="Library"
                    collapsed={collapsed}
                  />
                </ListItemButton>
              ) : (
                <ListItemButton
                  disableGutters={true}
                  component={Link}
                  to={routes.library()}
                  selected={
                    window.location.pathname === routes.library() && !collapsed
                  }
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    pl: drawerMargin,
                    height: listItemHeight,
                  }}
                >
                  <StackLineIcon style={iconStyle} />
                  <DrawerNavLabel
                    label="Library"
                    collapsed={collapsed}
                  />
                </ListItemButton>
              )}
            </List>
            <PageTree collapsed={collapsed} />
            <ProfileController
              userCoreContact={userCoreContact}
              drawerWidth={drawerWidth}
              collapsed={collapsed}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              pl: '64px',
              pr: 0,
              py: 0,
              m: 0,
              pt: 0,
              overflow: 'hidden',
              zIndex: 2,
              background: (theme) => theme.palette.background.paper,
            }}
          >
            <UserErrorRemediation />
            <WorkspaceInviteBanner workspaceInvites={workspaceInvites} />
            {children}
          </Box>
        </Box>
      </>
    )
  )
}

const ModernLayout = ({ children, window }) => {
  const location = useLocation()
  const route = useRouteName()

  useEffect(() => {
    const path = location?.pathname
    if (path) {
      try {
        ReactGA.send({
          hitType: 'pageview',
          page: path,
          title: route,
        })
      } catch (e) {
        logger.error('GA tracking error', e as Error, {
          location: path,
        })
      }
    }
  }, [location?.pathname])

  return (
    <DayProvider>
      <ModernLayoutInner window={window}>{children}</ModernLayoutInner>
    </DayProvider>
  )
}

export default ModernLayout
