import { Box, Typography } from '@mui/material'

import { getDisplayCoreCompanyProperty } from 'src/lib/contactFormatting'

import RowSplit from '../../RowSplit/RowSplit'

const coreCompanyPropsToDisplay = [
  'industry',
  'employeeCount',
  'annualRevenue',
  'funding',
]

export const formatProperty = ({ key, value }) => {
  if (['annualRevenue', 'funding'].includes(key)) {
    return `$${value.toLocaleString()}`
  }

  return value
}

const header = { fontSize: '0.8rem', fontWeight: 500 }

const OrganizationProperties = ({ coreCompany }) => {
  return (
    <Box>
      {coreCompanyPropsToDisplay.map((prop) => (
        <Box key={prop}>
          {coreCompany?.[prop] && (
            <RowSplit
              sx={{ mb: 3 }}
              left={
                <Typography sx={header}>
                  {getDisplayCoreCompanyProperty(prop)}
                </Typography>
              }
              right={
                <Typography sx={{ fontWeight: 300, fontSize: '0.8rem' }}>
                  {formatProperty({
                    key: prop,
                    value: coreCompany[prop],
                  })}
                </Typography>
              }
            />
          )}
        </Box>
      ))}

      {coreCompany?.address && (
        <>
          <Box sx={{}}>
            <Typography sx={header}>Address</Typography>

            <Typography sx={{ fontWeight: 300, fontSize: '0.8rem', mb: 3 }}>
              {coreCompany.address}
              <br />
              {coreCompany.city} {coreCompany.state} {coreCompany.postalCode}
              <br />
              {coreCompany.country}
            </Typography>
          </Box>
          {/*
          {process.env.HOST.includes('localhost') && (
            <iframe
              width="450"
              height="250"
              frameBorder="0"
              style={{ border: 0 }}
              referrerPolicy="no-referrer-when-downgrade"
              src={mapsUrl}
              allowFullScreen={true}
            ></iframe>
          )}*/}
        </>
      )}
    </Box>
  )
}

export default OrganizationProperties
