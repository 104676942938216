import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  darken,
  lighten,
  useTheme,
} from '@mui/material'
import {
  RiSettingsLine,
  RiAddLine,
  RiSearchLine,
  RiStickyNoteLine,
  RiFolderAddLine,
  RiArrowRightSFill,
} from '@remixicon/react'
import clsx from 'clsx'
import { NodeRendererProps, Tree } from 'react-arborist'
import { OpenMap } from 'react-arborist/dist/module/state/open-slice'
import { v4 as uuidv4 } from 'uuid'

import { routes } from '@redwoodjs/router'
import { navigate, useLocation } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import PageManagementDialog from 'src/components/Page/PageManagementDialog/PageManagementDialog'
import PageFolderManagementDialog from 'src/components/PageFolderManagementDialog/PageFolderManagementDialog'
import { TreeParent } from 'src/components/TreeParent/TreeParent'
import { DayContext } from 'src/lib/dayContext'

import Row from '../../Row/Row'

import { TreeCursor, cursorBlue } from './TreeCursor/TreeCursor'
import { useTreeManager } from './useTreeManager'

const GET_WORKSPACE_FROM_TREE = gql`
  query GetWorkspaceFromTree($id: String!) {
    workspaceTree(id: $id) {
      tree
      updatedAt
      selectedWorkspace
    }
  }
`

const UPDATE_WORKSPACE_TREE = gql`
  mutation UpdateWorkspaceTree(
    $id: String!
    $treeState: JSON!
    $updatedAt: DateTime!
  ) {
    updateWorkspaceTree(id: $id, treeState: $treeState, updatedAt: $updatedAt) {
      tree
      updatedAt
      selectedWorkspace
    }
  }
`

const uniqueId = (prefix = '') => {
  return `${prefix}${uuidv4()}`
}

const doesNodeIdExistInTree = (treeNodes, nodeId) => {
  for (const node of treeNodes) {
    if (node.id === nodeId) {
      return true
    }

    if (node.children && doesNodeIdExistInTree(node.children, nodeId)) {
      return true
    }
  }
  return false
}

// function to get the must recent updatedAt value from the tree, recursively iterating through the tree:
const getMostRecentUpdatedAt = (tree) => {
  let mostRecent = null
  for (const node of tree) {
    if (node.updatedAt) {
      if (!mostRecent || mostRecent < node.updatedAt) {
        mostRecent = node.updatedAt
      }
    }
    if (node.children) {
      const childMostRecent = getMostRecentUpdatedAt(node.children)
      if (!mostRecent || mostRecent < childMostRecent) {
        mostRecent = childMostRecent
      }
    }
  }
  return mostRecent
}

const PageTree = ({ collapsed }) => {
  const location = useLocation()
  const currentPageId = useMemo(() => {
    if (location.pathname.includes('page/')) {
      const id = location.pathname.split('/page/')[1]
      if (id && id != 'new') {
        return id
      }
    } else {
      return null
    }
  }, [location.pathname])

  const theme = useTheme()
  const { selectedWorkspace } = useContext(DayContext)
  const componentRenderKey = `page-tree-${selectedWorkspace}-${currentPageId}`
  const [openMap, setOpenMap] = useState<OpenMap>(null)
  const [openMapKey, setOpenMapKey] = useState<string>()
  const [searchTerm, setSearchTerm] = useState('')
  const [managePageData, setManagePageData] = useState(null)
  const [manageFolderData, setManageFolderData] = useState(null)
  const [treeData, setTreeData] = useState({
    tree: [],
    updatedAt: null,
    selectedWorkspace: null,
  })

  const treeKey = `treeData-${selectedWorkspace}`
  const treeRef = useRef(null)
  const treeContainerRef = useRef(null)

  const [
    updateWorkspaceTree,
    { data: workspaceServerTreeUpdateData, loading: updateWorkspaceLoading },
  ] = useMutation(UPDATE_WORKSPACE_TREE, {
    onCompleted: ({ updateWorkspaceTree }) => {
      localStorage.setItem(treeKey, JSON.stringify(updateWorkspaceTree))
      if (updateWorkspaceTree.selectedWorkspace === selectedWorkspace) {
        setTreeData(updateWorkspaceTree)
      }
    },
  })

  const { data: workspaceServerTreeData, refetch } = useQuery(
    GET_WORKSPACE_FROM_TREE,
    {
      variables: { id: selectedWorkspace },
      skip: !selectedWorkspace || updateWorkspaceLoading,
      pollInterval: 5000,
      onCompleted: ({ workspaceTree }) => {
        // update localstorage with the latest tree data:
        localStorage.setItem(treeKey, JSON.stringify(workspaceTree))
        if (workspaceTree.selectedWorkspace === selectedWorkspace) {
          const lastPageUpdate = getMostRecentUpdatedAt(workspaceTree.tree)

          if (
            treeData.selectedWorkspace !== selectedWorkspace ||
            treeData.updatedAt !== workspaceTree.updatedAt ||
            lastPageUpdate > treeData.updatedAt
          ) {
            setTreeData(workspaceTree)
          }
        }
      },
    }
  )

  useEffect(() => {
    if (selectedWorkspace && treeData.selectedWorkspace !== selectedWorkspace) {
      const localTreeData = localStorage.getItem(treeKey)
      if (localTreeData) {
        setTreeData(JSON.parse(localTreeData))
      }
      refetch()
    }
  }, [selectedWorkspace])

  const simplifyTree = (node) => {
    const result = {
      id: node.id,
      name: node.name,
    }
    if (node.children && node.children.length > 0)
      result['children'] = node.children
        .filter((childNode) => !childNode.id.startsWith('NEW_'))
        .map((child) => simplifyTree(child))

    return result
  }

  const serializeTree = (tree) => {
    return tree.map((rootNode) => simplifyTree(rootNode))
  }

  const handleTreeUpdate = (updatedData) => {
    setTreeData({ tree: updatedData, updatedAt: new Date(), selectedWorkspace })
    const serialized = serializeTree(updatedData)
    updateWorkspaceTree({
      variables: {
        id: selectedWorkspace,
        treeState: serialized,
        updatedAt: new Date(),
      },
    })
  }

  const onToggle = (id) => {
    setOpenMap((prev) => ({ ...prev, [id]: !openMap[id] }))
  }

  const { onMove, onRename, onCreate, onDelete, findNode } = useTreeManager(
    treeData.tree,
    handleTreeUpdate
  )

  useEffect(() => {
    if (openMap) {
      localStorage.setItem(openMapKey, JSON.stringify(openMap))
    }
  }, [openMap])

  useEffect(() => {
    if (selectedWorkspace) {
      const key = `openMap-${selectedWorkspace}`
      setOpenMapKey(key)

      const openMapFromLocalStorage = localStorage.getItem(key)
      if (openMapFromLocalStorage) {
        setOpenMap(JSON.parse(openMapFromLocalStorage))
      } else {
        const initialOpenState = {
          USERS: true,
          ORGANIZATIONS: false,
        }
        localStorage.setItem(key, JSON.stringify(initialOpenState))
        setOpenMap(initialOpenState)

        refetch()
      }
    }
  }, [selectedWorkspace])

  const uuidv4Pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  const isPage = (str) => uuidv4Pattern.test(str.toLowerCase())
  const handleManagePage = (pageId, parentId) => {
    const getFolderNodes = (nodes) => {
      let result = []
      nodes.forEach((node) => {
        if (!isPage(node.id)) {
          result.push(node)
        }
        if (node.children && node.children.length > 0) {
          result = result.concat(getFolderNodes(node.children))
        }
      })
      return result
    }

    const folders = getFolderNodes(treeData.tree)
    setManagePageData({ pageId, parentId, folders })
  }

  const handleManageFolder = (folderId) => {
    setManageFolderData(findNode(folderId))
  }

  const handleAddFolder = async (event, parentId = 'root') => {
    const newFolder = {
      id: uniqueId(`FOLDER_`),
      name: 'New Folder',
      children: [],
    }
    onCreate({
      parentId,
      node: newFolder,
    })
  }

  const TreeNode: React.FC<
    NodeRendererProps<
      | {
          id: string
          name: string
          emoji: string
          children: { id: string; name: string }[]
        }
      | { id: string; name: string; emoji: string; children?: undefined }
    >
  > = ({ node, style, dragHandle, tree }) => {
    return isPage(node.data.id) ? (
      <Box
        component="div"
        className={clsx({ ...node.state, isPage: true })}
        sx={{
          ...style,
          fontSize: '0.8rem',
          fontWeight: 500,
          py: '3px',
          width: '256px',
          borderRadius: '4px',
          justifyContent: 'space-between',
          color: (theme) =>
            node.isSelected
              ? theme.palette.primary.contrastText
              : theme.palette.text.secondary,
          background: (theme) =>
            node.isSelected ? theme.palette.text.primary : 'transparent',
        }}
        ref={dragHandle}
        onClick={() => navigate(routes.pageDetail({ id: node.data.id }))}
      >
        <Row sx={{ pl: '8px' }}>
          {node.data.emoji ? (
            <Box
              component="span"
              sx={{ mr: 1 }}
            >
              {node.data.emoji}
            </Box>
          ) : (
            <RiStickyNoteLine className="pageNodeIcon" />
          )}
          <span className="pageNodeName">{node.data.name}</span>
        </Row>
        <IconButton
          className="pageActionButton"
          onClick={(e) => {
            e.stopPropagation()
            handleManagePage(node.data.id, node.parent.id)
          }}
        >
          <RiSettingsLine style={{ height: '14px', width: '14px' }} />
        </IconButton>
      </Box>
    ) : (
      <Row
        className={clsx({ ...node.state, isFolder: true })}
        sx={{
          ...style,
          width: `256px`,
          justifyContent: 'space-between',
        }}
      >
        <Box
          component="div"
          sx={{
            fontWeight: 600,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
          }}
          ref={dragHandle}
          onClick={() => {
            node.toggle()
          }}
        >
          <RiArrowRightSFill className="folderArrow" />
          {node.data.name}
        </Box>
        <Row>
          <Tooltip
            title="Create new page"
            arrow={true}
            placement="top"
          >
            <IconButton
              className="pageActionButton"
              onClick={() => handleManagePage('new', node.data.id)}
            >
              <RiAddLine />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Create new folder"
            arrow={true}
            placement="top"
          >
            <IconButton
              className="pageActionButton"
              onClick={(e) => {
                e.stopPropagation()
                handleAddFolder(e, node.data.id)
              }}
            >
              <RiFolderAddLine />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Manage folder"
            arrow={true}
            placement="top"
          >
            <IconButton
              className="pageActionButton"
              onClick={(e) => {
                e.stopPropagation()
                handleManageFolder(node.data.id)
              }}
            >
              <RiSettingsLine />
            </IconButton>
          </Tooltip>
        </Row>
      </Row>
    )
  }

  const nav = document.getElementById('sidebar-nav-item-list')
  const navItemCount = nav ? nav?.children.length + 3.5 : 4.5

  return (
    <Box
      key={componentRenderKey}
      sx={{
        opacity: collapsed ? 0 : 1,
        transition: `all 0.3 ease-in-out`,
        ml: '20px',
        width: '280px',
        '& .day-ai-tree-node > div': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          transition: 'all 0.3s ease',
          '&.isDragging': {
            opacity: 0.5,
          },
          '& span.pageNodeName': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '180px',
            ml: '5px',
          },
          '& .pageNodeIcon': {
            height: '14px',
            width: '14px',
            flexShrink: 0,
          },
        },
        '& .day-ai-tree-node:hover > div:not(.isPage.isSelected)': {
          color: darken(cursorBlue, 0.2),
          background: lighten(cursorBlue, 0.9),
          '& .pageActionButton': {
            opacity: 1,
            color: darken(cursorBlue, 0.2),
          },

          '& span.pageNodeName, .pageNodeIcon': {
            color: darken(cursorBlue, 0.2),
          },
        },
        '& .folderArrow': {
          height: '24px',
          width: '24px',
          transform: `rotate(0deg)`,
          transition: 'transform 0.3s ease !important',
        },
        '& .isOpen .folderArrow': {
          transform: `rotate(90deg)`,
        },

        '& .willReceiveDrop': {
          background: cursorBlue,
          color: theme.palette.primary.contrastText,
          borderRadius: '3px',
        },
        '& .pageActionButton': {
          p: '2px',
          borderRadius: '0px',
          mr: '6px',
          opacity: 0,
          '& .remixicon': {
            height: '14px',
            width: '14px',
            flexShrink: 0,
          },
        },
      }}
    >
      <Row
        sx={{
          justifyContent: 'space-between',
          mt: 1,
          mb: 1,
          pr: '12px',
          height: '36px',
        }}
      >
        <TextField
          placeholder="Search pages ..."
          variant="standard"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          InputProps={{
            startAdornment: (
              <RiSearchLine
                style={{
                  height: '18px',
                  width: '18px',
                  color: lighten(theme.palette.text.secondary, 0.5),
                  marginRight: '8px',
                }}
              />
            ),
            disableUnderline: true,
          }}
        />
        <Row sx={{ justifyContent: 'flex-end' }}>
          <Tooltip
            title="Create new folder"
            arrow={true}
            placement="top"
          >
            <IconButton
              sx={{ p: '4px', mr: 0, borderRadius: '2px' }}
              onClick={(e) => handleAddFolder(e, 'root')}
            >
              <RiFolderAddLine style={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Create new page"
            arrow={true}
            placement="top"
          >
            <IconButton
              sx={{ p: '4px', mr: '12px', borderRadius: '2px' }}
              onClick={() => handleManagePage('new', 'OTHER')}
            >
              <RiAddLine style={{ height: '18px', width: '18px' }} />
            </IconButton>
          </Tooltip>
        </Row>
      </Row>
      <Box
        sx={{
          ml: '-6px',
          height: `calc(100vh - ${navItemCount * 64}px)`,
          pb: 3,
        }}
        ref={treeContainerRef}
      >
        {openMap && (
          <TreeParent id="treeparent">
            {(dimens) => (
              <Tree
                {...dimens}
                ref={treeRef}
                data={treeData.tree}
                onMove={onMove}
                onToggle={onToggle}
                searchTerm={searchTerm}
                rowClassName="day-ai-tree-node"
                selection={currentPageId}
                rowHeight={26}
                indent={2}
                initialOpenState={openMap}
                renderCursor={TreeCursor}
                dndRootElement={treeContainerRef.current}
              >
                {TreeNode}
              </Tree>
            )}
          </TreeParent>
        )}
      </Box>
      {managePageData && selectedWorkspace && (
        <PageManagementDialog
          onClose={() => setManagePageData(null)}
          onRename={onRename}
          onCreate={onCreate}
          onDelete={onDelete}
          onMove={onMove}
          managePageData={managePageData}
          selectedWorkspace={selectedWorkspace}
        />
      )}
      {manageFolderData && selectedWorkspace && (
        <PageFolderManagementDialog
          folderData={manageFolderData}
          onClose={() => setManageFolderData(null)}
          onRename={onRename}
          onDelete={onDelete}
        />
      )}
    </Box>
  )
}

export default PageTree
