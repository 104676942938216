import GoogleButton from 'react-google-button'

import { useConfirm } from 'material-ui-confirm'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { RiArrowRightSLine, RiGoogleLine } from '@remixicon/react'
import Row from '../Row/Row'
import { requiredGoogleScopes } from '../WorkAccount/workAccountUtil'

const SignInWithGoogle = () => {
  const [open, setOpen] = useState(false)
  const [viewPermissions, setViewPermissions] = useState(false)
  const confirm = useConfirm()

  const handleClick = () => {
    confirm({
      confirmationButtonProps: {},
      content: (
        <Box
          sx={{
            height: '400px',
            overflowY: 'auto',
            '& p, li': { fontSize: '0.6rem' },
          }}
        >
          <PrivacyPolicy />
        </Box>
      ),
      title: 'Privacy & Security',
      confirmationText: 'I agree',
    }).then(() => {
      window.location.href = '/api/authRedirectGoogle/authRedirectGoogle'
    })
  }
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="small"
        color="primary"
        variant="outlined"
        startIcon={<RiGoogleLine style={{ height: '16px' }} />}
      >
        Sign in with Google
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Sign in with Google</DialogTitle>
        <DialogContent sx={{ width: '500px' }}>
          <Typography sx={{ pb: 3 }}>
            Day.ai uses Google Sign-In to authenticate your account. This allows
            you to sign in with your Google account, and we will use your Google
            account to authenticate you. We will not store your Google password.
            We will only store your Google email address and a unique identifier
            that Google provides to us. We will also store your Google profile
            picture, if you have one.
          </Typography>
          <Typography sx={{ pb: 3 }}>
            Google will ask you to grant Day.ai permissions, and you can revoke
            these at any time in the future.
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            For Day.ai to function, you will need to grant all of these
            permissions.
          </Typography>
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
              p: 1,
              mt: 2,
            }}
            onClick={() => setViewPermissions((prev) => !prev)}
          >
            <Row>
              <RiArrowRightSLine
                style={{
                  height: '16px',
                  transform: viewPermissions ? 'rotate(90deg)' : 'none',
                  transition: 'all 0.3s ease-in-out',
                }}
              />
              <Typography sx={{ fontWeight: 500 }}>
                Review permissions and how Day.ai uses them
              </Typography>
            </Row>
            <Box
              sx={{
                overflow: viewPermissions ? 'auto' : 'hidden',
                height: viewPermissions ? '400px' : 0,
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {Object.entries(requiredGoogleScopes).map(([scope, entry]) => (
                <Box
                  key={scope}
                  sx={{ mt: 2 }}
                >
                  <Box
                    component="a"
                    href={entry.googleDocLink}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: 'none', fontWeight: 500 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {entry.label}
                  </Box>
                  <Typography>{entry.reason}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'center', p: 3, pb: 6 }}
        >
          <GoogleButton
            type="light"
            onClick={handleClick}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignInWithGoogle
