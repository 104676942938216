import { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import OpportunityEdit from '../../Pipeline/OpportunityEdit/OpportunityEdit'
import OrganizationProperties from '../OrganizationProperties/OrganizationProperties'
import OrganizationSocialLinks from '../OrganizationSocialLinks/OrganizationSocialLinks'
import OrganizationTile from '../OrganizationTile/OrganizationTile'

const GET_ORG_FOR_DETAIL = gql`
  query getOrgForDetail($workspaceId: String!, $orgId: String!) {
    organization(id: $orgId, workspaceId: $workspaceId) {
      name
      domain
      subCompanyTag
      description
      aiDescription
      promises
      naicsCodes
      sicCodes
      industry
      employeeCount
      annualRevenue
      funding
      address
      city
      state
      country
      postalCode
      colorVibrant
      colorDarkVibrant
      colorLightVibrant
      colorMuted
      colorDarkMuted
      colorLightMuted
      photoSquare
      photoIcon
      photoBanner
      stockTicker
      socialTwitter
      socialLinkedIn
      socialFacebook
      socialYouTube
      markdown
      createdAt
      updatedAt
      edgarCik
      crunchbaseEntityId
      similarDomains
      resolvedUrl
      opportunities {
        id
        title
      }
      pipelines {
        id
        title
      }
      relationshipTypes
      overview
    }
  }
`

const OrganizationDetail = ({
  orgId,
  showOrgTile = false,
  opportunity = null,
}) => {
  const { selectedWorkspace: workspaceId } = useContext(DayContext)
  const { currentUser } = useAuth()
  const [tab, setTab] = useState('challenges')
  const {
    data: organizationData,
    error,
    loading,
  } = useQuery(GET_ORG_FOR_DETAIL, {
    variables: { workspaceId, orgId },
    skip: !orgId || !workspaceId,
  })

  const data = organizationData?.organization

  const challengeStyle = { fontWeight: 600, fontSize: '0.8rem', mb: 1 }
  const solutionStyle = {
    fontWeight: 400,
    fontSize: '0.8rem',
    fontStyle: 'italic',
    mb: 3,
  }

  const DESIGN_READY_FOR_ABOUT = false

  useEffect(() => {
    // look at the tab logic and select the first that would not be disabled:
    if (data) {
      if (data.overview?.['status/highLevelSummary']) {
        setTab('status')
      } else if (data.overview?.['status/nextSteps']) {
        setTab('nextSteps')
      } else if (data.overview?.['objective/challengesAndSolutions']) {
        setTab('challenges')
      } else if (data.overview?.quotes) {
        setTab('quotes')
      } else if (data.aiDescription && DESIGN_READY_FOR_ABOUT) {
        setTab('about')
      } else if (opportunity) {
        setTab('opportunity')
      }
    }
  }, [data])

  const showDetails =
    !!data?.overview?.['status/highLevelSummary'] ||
    (DESIGN_READY_FOR_ABOUT && !!data?.aiDescription) ||
    opportunity

  return (
    data && (
      <Box sx={{ width: '100%' }}>
        {showOrgTile && (
          <Row
            sx={{
              width: 'calc(100%)',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '200px' }}>
              <OrganizationTile domain={orgId} />
            </Box>
            <Button
              onClick={() =>
                navigate(
                  routes.objectDetail({
                    objectId: orgId,
                    objectTypeSlug: 'organizations',
                    workspaceId,
                  })
                )
              }
            >
              View more
            </Button>
          </Row>
        )}
        {showDetails && (
          <>
            <Tabs
              value={tab}
              onChange={(e, newValue) => setTab(newValue)}
              sx={{
                '& .MuiTab-root': {
                  fontSize: '0.8rem',
                },
              }}
            >
              <Tab
                label="Current Status"
                value="status"
                disabled={!data?.overview?.['status/highLevelSummary']}
              />
              <Tab
                label="Next Steps"
                value="nextSteps"
                disabled={!data?.overview?.['status/nextSteps']}
              />
              <Tab
                label="Challenges"
                value="challenges"
                disabled={!data?.overview?.['objective/challengesAndSolutions']}
              />
              <Tab
                label="In Their Words"
                value="quotes"
                disabled={!data?.overview?.quotes}
              />
              {DESIGN_READY_FOR_ABOUT && (
                <Tab
                  label="About"
                  value="about"
                  disabled={!data?.aiDescription}
                />
              )}
              {opportunity && (
                <Tab
                  label="Edit Deal Details"
                  value="opportunity"
                />
              )}
            </Tabs>
            {tab === 'challenges' && (
              <Box sx={{ pt: 3 }}>
                {data?.overview?.['objective/challengesAndSolutions']?.map(
                  (item, index) => (
                    <Box key={index}>
                      <Typography sx={challengeStyle}>
                        {item.challenge}
                      </Typography>
                      <Typography sx={solutionStyle}>
                        {item.solution}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            )}
            {tab === 'status' && (
              <Box>
                <List>
                  {data?.overview?.['status/highLevelSummary']?.map(
                    (item, index) => (
                      <ListItem key={`summaryItem_${index}`}>
                        <ListItemText primary={item} />
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            )}
            {tab === 'nextSteps' && (
              <Box>
                <List>
                  {data?.overview?.['status/nextSteps']?.immediateNextSteps.map(
                    (item, index) => (
                      <ListItem key={`nextStep_${index}`}>
                        <ListItemIcon>
                          <ContactAvatar email={item.person} />
                        </ListItemIcon>
                        <ListItemText primary={item.description} />
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            )}
            {tab === 'quotes' && (
              <Box>
                <List>
                  {data?.overview?.['quotes']?.map((item, index) => (
                    <ListItem key={`quote_${index}`}>
                      <ListItemIcon>
                        <ContactAvatar email={item.email} />
                      </ListItemIcon>
                      <ListItemText primary={`"${item.text}"`} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            {tab === 'about' && DESIGN_READY_FOR_ABOUT && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 2,
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '1.0rem', mb: 1 }}>
                  Links
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <OrganizationSocialLinks
                    coreCompany={data}
                    iconSize={24}
                  />
                </Box>
                <Typography sx={{ fontWeight: 500, fontSize: '1.0rem', mb: 1 }}>
                  Description
                </Typography>
                <Typography sx={{ fontSize: '0.9rem', mb: 3 }}>
                  {data?.aiDescription || data?.description}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '1.0rem' }}>
                  Organizational Goals
                </Typography>
                <List>
                  {data?.promises?.map((promise, index) => (
                    <ListItem key={`orgPromise_${index}`}>
                      <ListItemText primary={promise} />
                    </ListItem>
                  ))}
                </List>
                <Typography sx={{ fontWeight: 500, fontSize: '1.0rem', mb: 1 }}>
                  Other info
                </Typography>
                <OrganizationProperties coreCompany={data} />
              </Box>
            )}
            {tab === 'opportunity' && opportunity && (
              <Box sx={{ pt: 3 }}>
                <OpportunityEdit
                  crmObject={opportunity}
                  setOpen={() => {}}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    )
  )
}

export default OrganizationDetail
