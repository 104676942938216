import { useEffect, useState } from 'react'

import { GitHub } from '@mui/icons-material'
import { Alert, Box, Chip, IconButton, Typography } from '@mui/material'
import {
  RiAddLine,
  RiCloseLine,
  RiFacebookFill,
  RiLinkedinFill,
  RiStickyNoteAddLine,
  RiTwitterXFill,
} from '@remixicon/react'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import OrganizationChip from 'src/components/Organizations/OrganizationChip/OrganizationChip'
import OpportunityCreateDialog from 'src/components/Pipeline/OpportunityCreateDialog/OpportunityCreateDialog'
import PrivateToYou from 'src/components/PrivateToYou/PrivateToYou'
import Row from 'src/components/Row/Row'
import SidebarNotes from 'src/components/SidebarNotes/SidebarNotes'
import SidebarPages from 'src/components/SidebarPages/SidebarPages'
import WorkspaceUserContextDialog from 'src/components/WorkspaceUserContextDialog/WorkspaceUserContextDialog'
import {
  extractEmailDomain,
  NativeObjectFormatters,
} from 'src/lib/contactFormatting'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import {
  actionButtonStyle,
  sidebarBodyTextStyle,
  socialIconStyle,
} from '../Sidebar'
import SidebarContactAbout from '../SidebarContactAbout/SidebarContactAbout'
import SidebarEmailHistory from '../SidebarEmailHistory/SidebarEmailHistory'
import SidebarMeetingHistory from '../SidebarMeetingHistory/SidebarMeetingHistory'
import SidebarOrganization from '../SidebarOrganization/SidebarOrganization'
import SidebarSectionExpand from '../SidebarSectionExpand/SidebarSectionExpand'

const GET_CONTACT_BY_EMAIL_FOR_SIDEBAR = gql`
  query GetContactByEmailForSidebar(
    $contactEmail: String!
    $ownerEmail: String!
  ) {
    getContactByEmail(contactEmail: $contactEmail, ownerEmail: $ownerEmail) {
      objectId
      objectType
      properties
    }
  }
`

const GET_PAGES_FOR_PERSON = gql`
  query GetPagesForPerson($email: String!) {
    pagesWithPerson(email: $email) {
      id
      title
      emoji
      madeExternalAt
      updatedAt
    }
  }
`

const SidebarLayoutContact = ({ crmObject, setOpen, showAdmin = true }) => {
  const { currentUser } = useAuth()
  const [mergedCrmObject, setMergedCrmObject] = useState(crmObject)
  const [noteCreateEmail, setNoteCreateEmail] = useState(null)
  const [opportunityCreatePerson, setOpportunityCreatePerson] = useState(null)
  const [expandedSummary, setExpandedSummary] = useState(false)

  const { refetch } = useQuery(GET_CONTACT_BY_EMAIL_FOR_SIDEBAR, {
    variables: {
      contactEmail: crmObject?.objectId,
      ownerEmail: currentUser.email,
    },
    onCompleted: (data) => {
      // Merge the data from the query with the existing crmObject

      setMergedCrmObject((prevMergedCrmObject) => {
        const mergedProperties = {
          ...prevMergedCrmObject.properties,
          ...data.getContactByEmail.properties,
        }

        const finalCrmObject = {
          ...prevMergedCrmObject,
          ...data.getContactByEmail,
          properties: mergedProperties,
        }

        logger.dev({
          mergedProperties,
          prevMergedCrmObject,
          data,
          finalCrmObject,
        })

        return finalCrmObject
      })
    },
  })

  const {
    data: pagesData,
    loading: pagesLoading,
    error: pagesError,
  } = useQuery(GET_PAGES_FOR_PERSON, {
    variables: {
      email: crmObject?.objectId,
    },
  })

  useEffect(() => {
    if (crmObject && !mergedCrmObject) {
      setMergedCrmObject(crmObject)
    }
  }, [crmObject])

  const focus = crmObject?.properties?.sidebarFocus
  return (
    <Box key={`${crmObject?.objectId || 'personSidebar'}_sidebar`}>
      {showAdmin && (
        <Row
          sx={{
            justifyContent: 'space-between',
            alignItems: 'top',
            py: 1,
            px: 2,
          }}
        >
          <Row
            gap={2}
            sx={{ flexShrink: 1, pt: 2 }}
          >
            <ContactAvatar
              email={crmObject?.objectId}
              size={64}
              borderRadius={100}
              showVerification={true}
              showCompany={false}
            />
            <Box
              sx={{
                width: '100%',
                flexShrink: 1,
              }}
            >
              <Row>
                <Typography
                  variant="h1"
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    flexShrink: 1,
                    fontSize: '1.5rem',
                  }}
                >
                  {NativeObjectFormatters[NativeObjectTypes.Contact].label(
                    crmObject
                  )}
                </Typography>
                {(crmObject?.properties?.linkedInUrl ||
                  crmObject?.properties?.twitterUrl ||
                  crmObject?.properties?.facebookUrl ||
                  crmObject?.properties?.githubUrl) && (
                  <Row
                    gap={'4px'}
                    sx={{ ml: 1 }}
                  >
                    {crmObject?.properties?.linkedInUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        sx={{ ...socialIconStyle, background: '#0077B5' }}
                        icon={<RiLinkedinFill size={18} />}
                        clickable={true}
                        onClick={() => {
                          window.open(
                            crmObject?.properties?.linkedInUrl,
                            '_blank'
                          )
                        }}
                      />
                    )}
                    {crmObject?.properties?.twitterUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <RiTwitterXFill
                            style={{ color: 'white' }}
                            size={15}
                          />
                        }
                        clickable={true}
                        onClick={() => {
                          window.open(
                            crmObject?.properties?.twitterUrl,
                            '_blank'
                          )
                        }}
                        sx={{ ...socialIconStyle, background: '#000000' }}
                      />
                    )}
                    {crmObject?.properties?.facebookUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <RiFacebookFill
                            style={{ color: 'white' }}
                            size={15}
                          />
                        }
                        clickable={true}
                        onClick={() => {
                          window.open(
                            crmObject?.properties?.facebookUrl,
                            '_blank'
                          )
                        }}
                        sx={{ ...socialIconStyle, background: '#4267B2' }}
                      />
                    )}
                    {crmObject?.properties?.githubUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={<GitHub sx={{ color: 'white' }} />}
                        clickable={true}
                        onClick={() => {
                          window.open(
                            crmObject?.properties?.githubUrl,
                            '_blank'
                          )
                        }}
                        sx={{ ...socialIconStyle, background: '#333333' }}
                      />
                    )}
                  </Row>
                )}
              </Row>
              <Row>
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    flexShrink: 1,
                    fontWeight: 500,
                    fontSize: '1.0rem',
                    letterSpacing: '-0.6px',
                  }}
                >
                  {NativeObjectFormatters[NativeObjectTypes.Contact].subtitle(
                    crmObject
                  )}
                </Typography>
              </Row>
            </Box>
          </Row>
          <Row>
            <IconButton onClick={() => setOpen(false)}>
              <RiCloseLine />
            </IconButton>
          </Row>
        </Row>
      )}
      <Row
        gap={1}
        sx={{ pt: 1, pb: 2, pl: 2 }}
      >
        <Chip
          clickable={true}
          icon={<RiStickyNoteAddLine size={12} />}
          label="Note"
          variant="outlined"
          size="small"
          sx={actionButtonStyle}
          onClick={() => {
            setNoteCreateEmail(crmObject?.objectId)
          }}
        />
        <Chip
          clickable={true}
          size="small"
          icon={<RiAddLine size={14} />}
          label="Opportunity"
          variant="outlined"
          sx={actionButtonStyle}
          onClick={() => {
            setOpportunityCreatePerson(crmObject)
          }}
        />
        {opportunityCreatePerson?.objectId && (
          <OpportunityCreateDialog
            person={opportunityCreatePerson}
            onClose={() => {
              setOpportunityCreatePerson(null)
              refetch()
            }}
          />
        )}
        <OrganizationChip
          domain={extractEmailDomain(crmObject?.objectId)}
          size="small"
          sx={actionButtonStyle}
        />
        {noteCreateEmail && (
          <WorkspaceUserContextDialog
            title="Add Note"
            people={[noteCreateEmail]}
            onClose={() => {
              setNoteCreateEmail(null)
              refetch()
            }}
          />
        )}
      </Row>

      <Box sx={{ height: 'calc(100vh - 164px)', overflowY: 'auto' }}>
        {mergedCrmObject?.properties?.['relationshipSummary/oneSentence'] && (
          <SidebarSectionExpand
            title="Status"
            isOpen={
              !focus &&
              mergedCrmObject?.properties?.['relationshipSummary/oneSentence']
            }
          >
            <>
              <Box sx={{}}>
                <Typography
                  sx={{
                    ...sidebarBodyTextStyle,
                    display: 'inline',
                  }}
                >
                  {expandedSummary
                    ? mergedCrmObject?.properties?.[
                        'relationshipSummary/long'
                      ] ||
                      mergedCrmObject?.properties?.['relationshipSummary/short']
                    : mergedCrmObject?.properties?.[
                        'relationshipSummary/oneSentence'
                      ]}
                </Typography>
                <Box
                  sx={{
                    display: 'inline',
                    fontWeight: 600,
                    ml: 1,
                    color: (theme) => theme.palette.secondary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => setExpandedSummary((prev) => !prev)}
                >
                  {expandedSummary ? 'less' : 'more'}
                </Box>
              </Box>

              {expandedSummary && (
                <Alert
                  severity="success"
                  sx={{
                    mt: 2,
                    fontSize: '0.7rem',
                    fontWeight: 500,
                  }}
                  icon={
                    <PrivateToYou
                      filled={true}
                      iconSize={20}
                    />
                  }
                >
                  Personal summary is private to you and not visible to others
                  in the workspace.
                </Alert>
              )}
            </>
          </SidebarSectionExpand>
        )}
        <SidebarSectionExpand
          title="About"
          isOpen={
            !focus &&
            !!mergedCrmObject?.properties?.careerHistory &&
            !mergedCrmObject?.properties?.['relationshipSummary/oneSentence']
          }
        >
          <SidebarContactAbout contact={mergedCrmObject} />
        </SidebarSectionExpand>
        <SidebarSectionExpand
          title="Emails"
          isOpen={focus === 'EMAILS'}
        >
          <SidebarEmailHistory email={mergedCrmObject?.objectId} />
        </SidebarSectionExpand>

        <SidebarSectionExpand
          title="Meetings"
          isOpen={focus === 'MEETINGS'}
        >
          <SidebarMeetingHistory email={mergedCrmObject?.objectId} />
        </SidebarSectionExpand>
        <SidebarSectionExpand
          title="Notes"
          isOpen={focus === 'NOTES'}
        >
          <SidebarNotes email={mergedCrmObject?.objectId} />
        </SidebarSectionExpand>

        <SidebarSectionExpand
          title="Pages"
          isOpen={focus === 'PAGES'}
          count={pagesData?.pagesWithPerson?.length}
        >
          <SidebarPages
            pages={pagesData?.pagesWithPerson}
            setOpen={setOpen}
          />
        </SidebarSectionExpand>
        {extractEmailDomain(mergedCrmObject?.objectId) && (
          <SidebarSectionExpand
            title="Organization"
            isOpen={focus === 'ORGANIZATION'}
          >
            <SidebarOrganization
              domain={
                mergedCrmObject?.properties?.domain ||
                extractEmailDomain(mergedCrmObject?.objectId)
              }
            />
          </SidebarSectionExpand>
        )}
      </Box>
    </Box>
  )
}

export default SidebarLayoutContact
