import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import LogoLoader from '../LogoLoader/LogoLoader'
import Row from '../Row/Row'
import PlayerContainer from '../Video/PlayerContainer/PlayerContainer'

const GET_MEETING_RECORDING_FOR_DIALOG = gql`
  query GetMeetingRecordingForDialog($id: String!) {
    meetingRecording: meetingRecordingForPageBlock(id: $id) {
      id
      startedAt
      statusHistory {
        level
        status
        reason
        message
        createdAt
      }
      title
      startedAt
      endedAt
      summary {
        status
        output
      }
      video {
        private {
          thumbnail {
            status
            url
          }
          download {
            status
            urls {
              default
              quality
              url
            }
          }
          playbackId
          tokens {
            video
            thumbnail
            storyboard
          }
        }
      }
      clips {
        id
        title
        description
        video {
          private {
            thumbnail {
              status
              url
            }
            download {
              status
              urls {
                default
                quality
                url
              }
            }
            playbackId
            tokens {
              video
              thumbnail
              storyboard
            }
          }
        }
      }
      participants {
        email
        contact {
          objectType
          objectId
          properties
        }
      }
    }
  }
`

const MeetingRecordingDialog = ({
  id,
  open,
  onClose,
  prefetched = null,
  clipId = null,
}) => {
  logger.dev({ id })
  const { selectedWorkspace, workAccountWorkspaceConnections } =
    useContext(DayContext)
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const [selectedClipId, setSelectedClipId] = useState(clipId)
  const [playbackId, setPlaybackId] = useState(null)
  const [tokens, setTokens] = useState(null)
  const [title, setTitle] = useState('Loading ...')
  const [description, setDescription] = useState(null)

  const playerRef = useRef(null)

  const uniqueKey = useMemo(() => {
    const uuid = uuidv4()
    return `meeting-recording-dialog-${id}-${clipId}-${uuid}`
  }, [id, clipId])

  const { data, loading } = useQuery(GET_MEETING_RECORDING_FOR_DIALOG, {
    variables: {
      id: id,
    },
    skip: prefetched || !id,
  })

  const meetingRecording = useMemo(() => {
    return prefetched || data?.meetingRecording
  }, [data, prefetched])

  const handleOnClose = () => {
    setIsOpen(false)
    if (!clipId) setSelectedClipId(null)
    onClose()
  }

  useEffect(() => {
    const newTitle = selectedClipId
      ? meetingRecording?.clips.find((clip) => clip.id === selectedClipId)
          ?.title || 'Clip from meeting'
      : meetingRecording?.title || 'Meeting Recording'
    setTitle(newTitle)

    const newDescription = selectedClipId
      ? meetingRecording?.clips.find((clip) => clip.id === selectedClipId)
          ?.description || '(no description)'
      : meetingRecording?.summary?.output.Summary_Short || '(no description)'
    setDescription(newDescription)

    const newTokens = selectedClipId
      ? meetingRecording?.clips.find((clip) => clip.id === selectedClipId)
          ?.video?.private?.tokens
      : meetingRecording?.video?.private?.tokens
    setTokens(newTokens)

    const newPlaybackId = selectedClipId
      ? meetingRecording?.clips.find((clip) => clip.id === selectedClipId)
          ?.video?.private?.playbackId
      : meetingRecording?.video?.private?.playbackId
    setPlaybackId(newPlaybackId)
  }, [meetingRecording, selectedClipId])

  useEffect(() => {
    if (clipId) {
      setSelectedClipId(clipId)
    }
  }, [clipId])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    setTimeout(() => {
      if (open && data && selectedClipId) {
        const selectedClipElement = document.getElementById(
          `${uniqueKey}-clip-${selectedClipId}`
        )
        if (selectedClipElement) {
          selectedClipElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
          })
        }
      }
    }, 250)
  }, [open, data, selectedClipId, uniqueKey])

  useEffect(() => {
    if (selectedClipId) {
      setTimeout(playerRef?.current?.play, 1)
    }
  }, [selectedClipId])

  return (
    id && (
      <Dialog
        open={isOpen}
        onClose={handleOnClose}
        maxWidth={'md'}
        sx={{ '& .MuiDialog-paper': { borderRadius: '8px' } }}
      >
        <DialogContent sx={{ width: '720px', p: 1, borderRadius: '8px' }}>
          {loading && !prefetched && !meetingRecording ? (
            <Box
              sx={{
                width: '100%',
                height: 'calc(396px + 48px)',
                display: 'flex',
              }}
            >
              <LogoLoader />
            </Box>
          ) : (
            <Box>
              <Grid
                container={true}
                spacing={2}
                sx={{
                  height: '396px',
                  maxHeight: '396px',
                }}
              >
                <Grid
                  item={true}
                  xs={8}
                >
                  <Box
                    sx={{
                      width: '460px',
                      height: '380px',
                      aspectRatio: 3 / 2,
                      boxSizing: 'border-box',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    <PlayerContainer
                      ref={playerRef}
                      playbackId={playbackId}
                      tokens={tokens}
                      style={{
                        width: '460px',
                        height: '380px',
                        borderRadius: '8px',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item={true}
                  xs={4}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '1.2rem',
                      height: '62px',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '0.7rem',
                      lineHeight: '1.7',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 16,
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container={true}
                spacing={2}
              >
                <Grid
                  item={true}
                  xs={8}
                >
                  <Row
                    gap={1}
                    sx={{ pt: 1, overflowX: 'auto', scrollbarWidth: 'none' }}
                  >
                    <Box
                      key={'noSelectedClip'}
                      className={selectedClipId ? '' : 'selected'}
                      sx={{
                        borderRadius: '4px',
                        flexShrink: 0,
                        height: '36px',
                        width: '56px',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        border: (theme) => `2px solid ${theme.palette.divider}`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: (theme) => theme.palette.divider,
                        fontWeight: 600,
                        '&:hover, &.selected': {
                          border: (theme) =>
                            `2px solid ${theme.palette.secondary.dark}`,
                          background: (theme) => theme.palette.secondary.light,
                          color: (theme) =>
                            theme.palette.secondary.contrastText,
                        },
                      }}
                      onClick={() => {
                        setSelectedClipId(null)
                      }}
                    >
                      All
                    </Box>
                    {meetingRecording?.clips.map((clip) => (
                      <Box
                        key={clip.id}
                        id={`${uniqueKey}-clip-${clip.id}`}
                        component="img"
                        src={clip.video.private.thumbnail.url}
                        className={selectedClipId === clip.id ? 'selected' : ''}
                        sx={{
                          flexShrink: 0,
                          borderRadius: '4px',
                          overflow: 'hidden',
                          height: '36px',
                          width: '56px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                          border: (theme) =>
                            `2px solid ${theme.palette.divider}`,
                          '&:hover, &.selected': {
                            border: (theme) =>
                              `2px solid ${theme.palette.secondary.light}`,
                          },
                        }}
                        onClick={() => {
                          setSelectedClipId(clip.id)
                        }}
                      />
                    ))}
                  </Row>
                </Grid>
                <Grid
                  item={true}
                  xs={4}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mt: '8px' }}
                    onClick={() => {
                      if (
                        workAccountWorkspaceConnections?.some(
                          ({ connectedWorkspaces }) =>
                            connectedWorkspaces.length > 0
                        )
                      ) {
                        navigate(
                          routes.workspaceMeetingRecording({
                            workspaceId: selectedWorkspace,
                            recordingId: id,
                          })
                        )
                      } else {
                        navigate(
                          routes.reviewMeeting({
                            id,
                          })
                        )
                      }
                    }}
                  >
                    View more
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    )
  )
}

export default MeetingRecordingDialog
