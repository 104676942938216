import { useContext } from 'react'

import { Avatar, Box } from '@mui/material'

import { NativeObjectFormatters } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import ContactAvatar from '../ContactAvatar/ContactAvatar'
import DomainAvatar from '../DomainAvatar/DomainAvatar'
import MasonryAvatar from '../MasonryAvatar/MasonryAvatar'

/*
export const NativeObjectTypes = {
  Contact: 'native_contact',
  Organization: 'native_organization',
  Opportunity: 'native_opportunity',
  Pipeline: 'native_pipeline',
  Stage: 'native_stage',
  MeetingRecording: 'native_meetingrecording',
  MeetingRecordingClip: 'native_meetingrecordingclip',
  Page: 'native_page',
  Action: 'native_action',
}
*/

const ObjectAvatar = ({
  crmObject,
  size,
  borderRadius,
  showSidebar = false,
  sx,
}: {
  crmObject: any
  size?: number
  borderRadius?: number
  showSidebar?: boolean
  sx?: any
}) => {
  const { setSidebarObject } = useContext(DayContext)

  const sizeStyle = size ? { width: `${size}px`, height: `${size}px` } : {}

  const styles = {
    ...sizeStyle,
    ...sx,
    flexGrow: 0,
    flexShrink: 0,
    cursor: showSidebar ? 'pointer' : 'default',
  }

  const handleClick = () => {
    if (showSidebar) {
      setSidebarObject(crmObject)
    }
  }

  return (
    <Box sx={styles}>
      {crmObject.objectType === NativeObjectTypes.Contact && (
        <ContactAvatar
          email={crmObject?.objectId || crmObject?.properties?.email}
          crmObject={crmObject}
          size={size}
          borderRadius={borderRadius}
          showVerification={false}
          showSidebar={showSidebar}
        />
      )}
      {crmObject.objectType === NativeObjectTypes.Organization && (
        <DomainAvatar
          domain={crmObject?.properties?.domain}
          photoUrl={NativeObjectFormatters[
            NativeObjectTypes.Organization
          ].photoUrl(crmObject)}
          size={size}
          borderRadius={borderRadius}
          onClick={handleClick}
          sx={styles}
        />
      )}
      {crmObject.objectType === NativeObjectTypes.Opportunity && (
        <Avatar
          src={crmObject?.properties?.photoUrl}
          sx={styles}
        />
      )}
      {crmObject.objectType === NativeObjectTypes.Pipeline && (
        <MasonryAvatar
          domains={crmObject?.properties?.domains}
          size={size}
        />
      )}
      {crmObject.objectType === 'page' && (
        <>
          {crmObject.properties?.domains?.length > 0 ? (
            <DomainAvatar
              domain={crmObject?.properties?.domains[0]}
              photoUrl={NativeObjectFormatters[
                NativeObjectTypes.Organization
              ].photoUrl(crmObject)}
              size={size}
              borderRadius={borderRadius}
              onClick={handleClick}
              sx={styles}
            />
          ) : (
            <>
              {crmObject.properties?.people?.length > 0 && (
                <ContactAvatar
                  email={crmObject?.properties?.people[0]}
                  size={size}
                  borderRadius={borderRadius}
                  showVerification={false}
                  showSidebar={false}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default ObjectAvatar
