import { useState } from 'react'

import { Box, Dialog, DialogContent } from '@mui/material'

import OpportunityEdit from '../OpportunityEdit/OpportunityEdit'
import OrganizationDetail from '../../Organizations/OrganizationDetail/OrganizationDetail'

const OpportunityDialog = ({ pipeline, opportunity, open, onClose }) => {
  const [editing, setEditing] = useState(false)

  const handleCreatePage = () => {}

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ height: '600px', overflow: 'hidden' }}>
        <Box sx={{ height: '536px', overflowY: 'auto' }}>
          {editing ? (
            <OpportunityEdit
              crmObject={opportunity}
              setOpen={() => {}}
            />
          ) : (
            <Box sx={{}}>
              <OrganizationDetail
                orgId={opportunity.properties.domain}
                opportunity={opportunity}
                showOrgTile={true}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default OpportunityDialog
