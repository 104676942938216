import React, {
  useState,
  createContext,
  ReactNode,
  useMemo,
  useEffect,
} from 'react'

import type { GlobalWorkAccountWorkspaceConnectionsQuery } from 'types/graphql'
import { WorkAccountWorkspaceConnection, Workspace } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router/dist/useRouteName'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { onboardingStatus } from 'src/pages/WelcomePage/WelcomePage'

import { extractEmailDomain } from './contactFormatting'
import { ungatedForMeetingRecordingCache } from './gates'
import { logger } from './logger'

const USER_CORE_CONTACT_QUERY = gql`
  query UserCoreContactQuery($email: String!) {
    coreContact(email: $email) {
      id
      email
      username
      firstName
      lastName
      title
      domain
      industry
      photo
      department
      description
      unsubscribed
      linkedInUrl
      linkInBioJson
      timezone
      travelTimezone
    }
  }
`

const WORK_ACCOUNTS_QUERY = gql`
  query DayContextFindWorkAccountByOwnerEmail($ownerEmail: String!) {
    workAccounts(ownerEmail: $ownerEmail) {
      id
      createdAt
      uuid
      email
      name
      tokenError
      scopes
      crm3WorkspaceId
      googleSyncStatuses {
        type
        status
        reason
        statusChangedAt
        lastSeenSyncToken
        lastSyncedAt
      }
      calendarAutoRecordSettings {
        mode
        availableModes
      }
      notificationSubscriptionOverrides {
        notificationKey
        enabled
      }
      workspaces {
        id
        name
        domains {
          domain
        }
      }
    }
  }
`

const FETCH_WORKSPACES_AND_ROLES = gql`
  query FetchWorkspacesAndRoles($userEmail: String!) {
    workspaces(userEmail: $userEmail) {
      id
      name
      status
      domains {
        domain
        autoInvite
        autoInviteRoleId
      }
      claimableDomains
      isDefault
      treeState
      members {
        id
        email
        status
        isDefaultOwner
        roleId
        coreContact {
          firstName
          lastName
          photo
          title
        }
      }
      roles {
        id
        name
        description
        permissions {
          type
          permission
          scope
        }
      }
    }
  }
`

const WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY = gql`
  query GlobalWorkAccountWorkspaceConnectionsQuery {
    workAccountWorkspaceConnections {
      workAccountUuid
      connectedWorkspaces {
        workspaceId
        sharingRules {
          category
          selector {
            type
            expression
          }
          authorization {
            type
            id
          }
        }
      }
    }
  }
`

const GET_ALL_WORKSPACE_CONTACTS_FOR_CONTEXT = gql`
  query GetAllContactsForUserForContext($workspaceId: String!) {
    getAllContactsForUserForWorkspace(workspaceId: $workspaceId) {
      objectType
      objectId
      properties
    }
  }
`

const GET_ORGANIZATION_OVERVIEWS = gql`
  query GetOrganizationOverviews($workspaceId: String!, $excludeOpps: Boolean) {
    organizationOverviews(
      workspaceId: $workspaceId
      excludeOpps: $excludeOpps
    ) {
      domain
      properties
      overview
      actionContent
    }
  }
`

const MEETING_RECORDINGS_FOR_CONTEXT = gql`
  query MeetingRecordingsForContext(
    $workspaceId: String!
    $limit: Int!
    $page: Int!
  ) {
    workspaceMeetingRecordings(
      workspaceId: $workspaceId
      limit: $limit
      page: $page
    ) {
      pageInfo {
        limit
        page
        totalPages
      }
      recordings {
        id
        title
        summary {
          output
        }
        startedAt
        endedAt
        statusHistory {
          level
          status
          createdAt
          reason
          message
        }
        participants {
          email
        }
        clips {
          id
          startSeconds
          endSeconds
        }
        calendarEvents {
          id
          GoogleEvent {
            title
          }
        }
      }
    }
  }
`

interface DayContextProps {
  userCoreContact: any
  setUserCoreContact: (userCoreContact: any) => void
  refetchUserCoreContact: () => void
  workAccounts: any[]
  setWorkAccounts: (workAccounts: any[]) => void
  refetchWorkAccounts: () => void
  workspaces: Workspace[]
  refetchWorkspaces: () => Promise<any>
  sidebarObject: any
  setSidebarObject: (sidebarObject: any) => void
  workAccountWorkspaceConnections: WorkAccountWorkspaceConnection[]
  refetchWorkAccountWorkspaceConnections: () => void
  selectedWorkspace: string | null
  setSelectedWorkspace: (workspaceId: string) => void
  people: any[]
  refetchPeople: () => void
  organizationOverviews: any[]
  refetchOrganizationOverviews: () => void
  loading: boolean
}

export const DayContext = createContext<DayContextProps>({
  userCoreContact: null,
  setUserCoreContact: () => {},
  refetchUserCoreContact: () => {},
  workAccounts: [],
  setWorkAccounts: () => {},
  refetchWorkAccounts: () => {},
  workspaces: [],
  refetchWorkspaces: async () => {},
  workAccountWorkspaceConnections: null,
  refetchWorkAccountWorkspaceConnections: () => {},
  sidebarObject: null,
  setSidebarObject: () => {},
  selectedWorkspace: null,
  setSelectedWorkspace: () => {},
  people: [],
  refetchPeople: () => {},
  organizationOverviews: [],
  refetchOrganizationOverviews: () => {},
  loading: false,
})

interface DayProviderProps {
  children: ReactNode
}

// LocalStorage will happily stringify null/undefined values
// and then hand them back to you as strings, which messes up
// truthy/falsey checks. Let's immediately convert them back
// before using them in business logic.
const parseLocalStorageValue = (value: string) => {
  if (value === 'null' || value === 'undefined') return null

  return value
}

export const DayProvider: React.FC<DayProviderProps> = ({ children }) => {
  const { currentUser } = useAuth()
  const route = useRouteName()
  const [sidebarObject, setSidebarObject] = useState<any>(null)
  const [userCoreContact, setUserCoreContact] = useState<any>(null)
  const [workAccounts, setWorkAccounts] = useState<any[]>([])
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [workAccountWorkspaceConnections, setWorkAccountWorkspaceConnections] =
    useState<WorkAccountWorkspaceConnection[]>([])
  const [people, setPeople] = useState<any[]>([])
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>(
    null
  )

  const [meetingRecordings, setMeetingRecordings] = useState<any[]>([])
  const [meetingRecordingsPageInfo, setMeetingRecordingsPageInfo] =
    useState<any>(null)
  const [meetingRecordingsPage, setMeetingRecordingsPage] = useState<number>(1)

  const PRECACHE_MEETING_RECORDINGS = ungatedForMeetingRecordingCache.includes(
    currentUser?.email
  )
  const { refetch: refetchMeetingRecordings } = useQuery(
    MEETING_RECORDINGS_FOR_CONTEXT,
    {
      variables: {
        limit: 20,
        page: meetingRecordingsPage,
        workspaceId: selectedWorkspace,
      },
      onCompleted: (data) => {
        setMeetingRecordings((prev) => [
          ...prev,
          ...data.workspaceMeetingRecordings.recordings,
        ])
        setMeetingRecordingsPageInfo(data.workspaceMeetingRecordings.pageInfo)
        setMeetingRecordingsPage(
          data.workspaceMeetingRecordings.pageInfo.page + 1
        )
      },
      skip:
        !selectedWorkspace ||
        !PRECACHE_MEETING_RECORDINGS ||
        meetingRecordingsPageInfo?.totalPages < meetingRecordingsPage,
    }
  )

  const { refetch: refetchPeople } = useQuery(
    GET_ALL_WORKSPACE_CONTACTS_FOR_CONTEXT,
    {
      variables: { workspaceId: selectedWorkspace },
      skip: !selectedWorkspace,
      onCompleted: (data) => {
        logger.dev({ people: data.getAllContactsForUserForWorkspace })
        setPeople(data.getAllContactsForUserForWorkspace)
      },
    }
  )

  const {
    data: organizationOverviewsData,
    refetch: refetchOrganizationOverviews,
  } = useQuery(GET_ORGANIZATION_OVERVIEWS, {
    variables: { workspaceId: selectedWorkspace, excludeOpps: false },
    skip: !selectedWorkspace,
    pollInterval: 1000 * 60 * 3, // 3 minutes
  })

  const {
    data: userCoreContactData,
    refetch: refetchUserCoreContact,
    loading: userCoreContactLoading,
  } = useQuery(USER_CORE_CONTACT_QUERY, {
    variables: { email: currentUser?.email },
    skip: !currentUser || userCoreContact,
    onCompleted: (data) => {
      setUserCoreContact(data.coreContact)
    },
  })

  const {
    data: workAccountsData,
    refetch: refetchWorkAccounts,
    loading: workAccountsLoading,
  } = useQuery(WORK_ACCOUNTS_QUERY, {
    variables: {
      ownerEmail: currentUser?.email,
    },
    skip: !currentUser,
    onCompleted: (data) => {
      setWorkAccounts(data.workAccounts)
    },
  })

  const {
    data: workspacesData,
    refetch: refetchWorkspaces,
    loading: workspacesLoading,
  } = useQuery(FETCH_WORKSPACES_AND_ROLES, {
    variables: { userEmail: currentUser?.email },
    skip: !currentUser,
    onCompleted: (data) => {
      const workspacesFromQuery = data.workspaces || []

      setWorkspaces(workspacesFromQuery)
    },
  })

  const peopleByDomain = useMemo(() => {
    return people.reduce((acc, person) => {
      const domain = extractEmailDomain(person.objectId)
      acc[domain] = acc[domain] || []
      acc[domain].push(person)
      return acc
    }, {})
  }, [people])

  const organizationOverviews = useMemo(() => {
    if (!organizationOverviewsData?.organizationOverviews || !people) {
      return []
    }

    return organizationOverviewsData.organizationOverviews.map((overview) => {
      const orgPeople = peopleByDomain[overview.domain] || []

      return {
        ...overview,
        people: orgPeople,
      }
    })
  }, [organizationOverviewsData, peopleByDomain])

  const selectedWorkspaceFromLocalStorage = parseLocalStorageValue(
    localStorage.getItem('selectedWorkspaceV2')
  )

  const defaultWorkspaceId = workspaces[0]?.id

  // The defaultWorkspaceId check ensures we don't try to operate on loading/missing data (i.e. empty array)
  // The !selectedWorkspaceFromLocalStorage check catches the case where no workspace is stored yet
  // The includes() check catches the case where the user previous had access to a workspace and it
  // was stored as their selection, but they no longer have access to it so it's stored in storage but
  // not in the list of available workspaces to choose from.
  if (
    defaultWorkspaceId &&
    (!selectedWorkspaceFromLocalStorage ||
      !workspaces
        .map(({ id }) => id)
        .includes(selectedWorkspaceFromLocalStorage))
  ) {
    localStorage.setItem('selectedWorkspaceV2', defaultWorkspaceId)
  }

  if (!selectedWorkspace && defaultWorkspaceId) {
    setSelectedWorkspace(
      selectedWorkspaceFromLocalStorage &&
        workspaces
          .map(({ id }) => id)
          .includes(selectedWorkspaceFromLocalStorage)
        ? selectedWorkspaceFromLocalStorage
        : defaultWorkspaceId
    )
  }

  /*
  TODO: discuss if we want this fallback or not

  if (!selectedWorkspace && selectedWorkspaceFromLocalStorage)
    setSelectedWorkspace(selectedWorkspaceFromLocalStorage)
  */

  useEffect(() => {
    if (selectedWorkspace) {
      localStorage.setItem('selectedWorkspaceV2', selectedWorkspace)
    }
  }, [selectedWorkspace])

  // TODO: discuss if we want to CLEAR the selected workspace from localStorage if the user no longer has access to it

  const {
    data: workAccountWorkspaceConnectionsData,
    refetch: refetchWorkAccountWorkspaceConnections,
    loading: workAccountWorkspaceConnectionsLoading,
  } = useQuery<GlobalWorkAccountWorkspaceConnectionsQuery>(
    WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY,
    {
      onCompleted: (data) => {
        setWorkAccountWorkspaceConnections(data.workAccountWorkspaceConnections)
      },
    }
  )

  useEffect(() => {
    if (
      userCoreContact &&
      workspacesData &&
      workAccountsData &&
      selectedWorkspace &&
      workAccountWorkspaceConnectionsData &&
      !(
        workAccountWorkspaceConnectionsLoading ||
        userCoreContactLoading ||
        workAccountsLoading ||
        workspacesLoading
      )
    ) {
      const targetWorkspace = workspacesData.workspaces.find(
        (workspace) => workspace.id === selectedWorkspace
      )

      const { isComplete } = onboardingStatus({
        coreContact: userCoreContact,
        workAccounts: workAccountsData.workAccounts,
        workspace: targetWorkspace,
        workAccountWorkspaceConnections:
          workAccountWorkspaceConnectionsData.workAccountWorkspaceConnections,
      })

      const needsOnboarding = !isComplete

      const excludedRoutesFromForcedOnboarding = ['revokeAlert'] // Add more if necessary

      if (
        needsOnboarding &&
        !excludedRoutesFromForcedOnboarding.includes(route)
      )
        navigate(routes.welcome())
    }
  }, [
    userCoreContact,
    workAccountsData,
    workspacesData,
    selectedWorkspace,
    workAccountWorkspaceConnectionsData,
  ])

  const value = useMemo(() => {
    return {
      userCoreContact,
      setUserCoreContact,
      refetchUserCoreContact,
      workAccounts,
      setWorkAccounts,
      refetchWorkAccounts,
      workspaces,
      setWorkspaces,
      refetchWorkspaces,
      workAccountWorkspaceConnections,
      refetchWorkAccountWorkspaceConnections,
      sidebarObject,
      setSidebarObject,
      selectedWorkspace,
      setSelectedWorkspace,
      people,
      refetchPeople,
      organizationOverviews,
      refetchOrganizationOverviews,
      loading:
        workAccountWorkspaceConnectionsLoading ||
        userCoreContactLoading ||
        workAccountsLoading ||
        workspacesLoading,
    }
  }, [
    userCoreContact,
    workAccounts,
    workspaces,
    workAccountWorkspaceConnections,
    sidebarObject,
    selectedWorkspace,
    people,
    refetchPeople,
    organizationOverviews,
    refetchOrganizationOverviews,
  ])

  return <DayContext.Provider value={value}>{children}</DayContext.Provider>
}
