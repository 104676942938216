import { useState } from 'react'

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material'
import { RiCloseLine } from '@remixicon/react'

import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'

import OrganizationTile from '../../Organizations/OrganizationTile/OrganizationTile'

const OpportunityFinder = ({
  selectedOrg,
  onSelect,
  loading,
  domains,
  readOnly = false,
  fromBlank = false,
}) => {
  const [openDomainSetter, setOpenDomainSetter] = useState(fromBlank)
  const [selectedDomain, setSelectedDomain] = useState(
    selectedOrg?.domain || ''
  )

  const isValidDomain =
    /^(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6})|(?:\d{1,3}(?:\.\d{1,3}){3}))$/.test(
      selectedDomain
    )

  const handleDomainUpdate = (e, newValue) => {
    // Detect if the input is a paste event
    logger.dev({ e })
    if (e.nativeEvent.inputType === 'insertFromPaste') {
      // Clean up pasted content
      const pastedValue = e.target.value.trim().toLowerCase()
      logger.dev({ pastedValue })
      // Remove common prefixes
      const cleanedValue = pastedValue
        .replace(/^(https?:\/\/)?(www\.)?/, '')
        .replace(/\/.*$/, '') // Remove anything after the domain
        .toLowerCase() // Ensure the domain is lowercase
      logger.dev({ cleanedValue })
      setSelectedDomain(cleanedValue)
    } else {
      // For regular typing, just update as normal
      setSelectedDomain(newValue || e.target.value)
    }
  }

  return selectedOrg ? (
    <Box>
      <OrganizationTile
        domain={selectedOrg.domain}
        openSidebar={false}
      />
    </Box>
  ) : loading ? (
    <>
      <CircularProgress
        color="secondary"
        size={24}
      />
    </>
  ) : (
    !readOnly && (
      <>
        {openDomainSetter ? (
          <Row
            sx={{ height: '96px', alignItems: 'top' }}
            gap={2}
          >
            {domains?.length > 0 ? (
              <Autocomplete
                options={domains}
                value={selectedDomain}
                freeSolo={true}
                onChange={handleDomainUpdate}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Website"
                    onChange={(e) => handleDomainUpdate(e, null)}
                    value={selectedDomain}
                  />
                )}
              />
            ) : (
              <TextField
                label="Website"
                value={selectedDomain}
                onChange={(e) => handleDomainUpdate(e, null)}
                fullWidth={true}
                error={selectedDomain !== '' && !isValidDomain}
                helperText={
                  selectedDomain !== '' && !isValidDomain
                    ? 'Please enter a valid web domain'
                    : ''
                }
              />
            )}
            <Button
              variant="outlined"
              color="secondary"
              disabled={!isValidDomain}
              disableElevation={true}
              onClick={() => {
                setOpenDomainSetter(false)
                onSelect(selectedDomain)
              }}
              sx={{ height: '52px', width: '212px' }}
            >
              Select
            </Button>
            {!fromBlank && (
              <IconButton
                onClick={() => setOpenDomainSetter(false)}
                sx={{ height: '52px' }}
              >
                <RiCloseLine />
              </IconButton>
            )}
          </Row>
        ) : (
          <Alert
            severity="info"
            action={
              <Button onClick={() => setOpenDomainSetter(true)}>
                Select organization
              </Button>
            }
          >
            No organization selected
          </Alert>
        )}
      </>
    )
  )
}

export default OpportunityFinder
