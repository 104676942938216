const PrivacyPolicy = () => {
  return (
    <>
      <p>Effective as of August 14, 2023&nbsp;</p>
      <p>
        This Privacy Policy describes how Day AI ("<strong>Day AI</strong>," the
        &ldquo;<strong>Company</strong>&rdquo; "<strong>we</strong>") handles
        personal information that we collect, receive, use, store, share,
        transfer and process through our Services, as well as through our social
        media, marketing activities, and other activities described in this
        Privacy Policy and when you use any other sites or services that link to
        this Privacy Policy. In addition, this statement describes the rights
        you may have with respect to your personal information under applicable
        law. Defined terms used but not otherwise defined herein have the
        meanings ascribed to them in our{' '}
        <a
          href="https://day.ai/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
        .&nbsp;
      </p>
      <p>
        <strong>California, Virginia and Nevada notice at collection. </strong>
        See the State law privacy rights section for important information about
        the personal information we collect about California, Virginia and
        Nevada residents and their privacy rights.&nbsp;
      </p>
      <p>
        <strong>Personal information we collect</strong>
      </p>
      <p>
        <strong>Information you provide to us. </strong>Personal information you
        may provide to us through the Services or otherwise includes:
      </p>
      <ul>
        <li>
          <strong>Contact data</strong>, such as your first and last name,
          salutation, email addresses, phone number, billing and mailing
          addresses, and your professional titles and organizational
          affiliations.&nbsp;
        </li>
        <li>
          <strong>Profile data</strong>, such as the username and any password
          that you may set to establish an online account on the Services,
          profile photos, and any other information that you add to your account
          profile.&nbsp;
        </li>
        <li>
          <strong>Transaction data</strong>, such as transactions you undertake
          through the Services and your transaction history.
        </li>
        <li>
          <strong>Communications </strong>that we exchange with you, including
          when you contact us through the Services, social media, or
          otherwise.&nbsp;
        </li>
        <li>
          <strong>Marketing data</strong>, such information about your
          participation in any promotions and your preferences for receiving our
          marketing communications.&nbsp;
        </li>
        <li>
          <strong>Financial data</strong>, such as your payment card
          information, which is handled by our payment processors.&nbsp;
        </li>
        <li>
          <strong>User-generated content</strong>, such as profile pictures,
          photos, images, videos, audio recordings, emails, comments, questions,
          messages, information you provide or collect upon scheduling of a
          meeting, calendar information, and other content or information that
          you generate, transmit, or otherwise make available on the Services,
          as well as associated metadata. Metadata includes information on how,
          when, where and by whom a piece of content was collected and how that
          content has been formatted or edited. Metadata also includes
          information that users can add or can have added to their content,
          such as keywords, geographical or location information, and other
          similar data.&nbsp;
        </li>
        <li>
          <strong>Invitee data. </strong>We offer features that help users
          invite their contacts to use the Services and may collect contact
          details about these invitees so that we can deliver their invitations.
          Please do not refer someone to us or share their contact details with
          us unless you have their permission to do so.&nbsp;
        </li>
        <li>
          <strong>Customer Data.</strong> Our Services allow our customers to
          create and share marketing, sales and customer service content.&nbsp;
          When our customers use our Services, they may collect personal
          information about you. We do not control the types of personal
          information that our customers may choose to collect or manage using
          the Services.&nbsp; We store our customers&rsquo; information on our
          service providers&rsquo; servers but process it as a processor under
          our customers&rsquo; instructions and in accordance with our{' '}
          <a
            href="https://day.ai/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
          , which prohibit us from using such information except as necessary to
          provide and improve our Services and as required by law. Our customers
          control and are responsible for correcting, deleting or updating the
          information they process using the Services and for complying with any
          regulations or laws that require providing notice, disclosure, and/or
          obtaining consent prior to transferring personal information to us for
          processing purposes.
        </li>
        <li>
          <strong>Other data </strong>not specifically listed here, which we
          will use as described in this Privacy Policy or as otherwise described
          at the time of collection.&nbsp;
        </li>
      </ul>
      <p>
        In most cases, providing your data is voluntary (e.g., providing
        user-generated content, contact information to receive marketing
        communications, invitee data and Your User-Accessed Data). In other
        cases providing the information is required in order for Day AI to
        provide our Services, complete our contract or comply with our legal
        obligations (e.g., contact data, transaction data, financial
        data).&nbsp;
      </p>
      <p>
        <strong>Third-party sources. </strong>We may combine personal
        information we receive from you with personal information we obtain from
        other sources, such as:&nbsp;
      </p>
      <ul>
        <li>
          <strong>Third-party accounts </strong>to which you choose to connect
          your Day AI account. For example, when you connect an email account to
          the Services, we will collect and process the email body information,
          headers (e.g., date, to, from, cc, bcc fields), metadata and settings
          associated with the emails in the account, including contact details
          in email signatures (collectively, &ldquo;
          <strong>email metadata</strong>&rdquo;).&nbsp;
        </li>
        <li>
          <strong>Enterprise customers </strong>that give us access to the email
          accounts of their personnel.&nbsp;
        </li>
        <li>
          <strong>Other users of the Services </strong>who choose to grant you
          access to information in their Day AI accounts.&nbsp;
        </li>
        <li>
          <strong>Public data</strong>, including information in government
          records and that individuals have made publicly accessible on the
          web.&nbsp;
        </li>
        <li>
          <strong>Licensed data </strong>that we obtain from data licensors to
          help us update and enrich information, e.g., about Day AI users&rsquo;
          contacts.&nbsp;
        </li>
        <li>
          <strong>Third party services</strong>, such as social media services,
          that you use to log into, or otherwise link to, your Day AI account.
          This data may include your username, profile picture and other
          information associated with your account on that third-party service
          that is made available to us based on your account settings on that
          service.&nbsp;
        </li>
      </ul>
      <p>
        <strong>Automatic data collection. </strong>We, our service providers,
        and our business partners may automatically log information about you,
        your computer or mobile device, and your interaction over time with the
        Services, our communications and other online services, such as:&nbsp;
      </p>
      <ul>
        <li>
          <strong>Device data</strong>, such as your computer&rsquo;s or mobile
          device&rsquo;s operating system type and version, manufacturer and
          model, browser type, screen resolution, RAM and disk size, CPU usage,
          device type (e.g., phone, tablet), IP address, unique identifiers,
          language settings, and general location information such as city,
          state or geographic area.&nbsp;
        </li>
        <li>
          <strong>Online activity data</strong>, such as pages you viewed, how
          long you spent on a page, the website you visited before browsing to
          the Services, navigation paths between pages, information about your
          activity on a page, access times and duration of access, and whether
          you have opened our emails or clicked links within them.&nbsp;
        </li>
      </ul>
      <p>
        <strong>Cookies and other tracking technologies</strong>. Like many
        online services, we use the following technologies:&nbsp;
      </p>
      <ul>
        <li>
          <strong>Cookies</strong>, which are small text files that websites
          store on user devices and that allow web servers to record
          users&rsquo; web browsing activities and remember their submissions,
          preferences and login status as they navigate a site. Cookies used on
          our sites include both "session cookies" that are deleted when a
          session ends, "persistent cookies" that remain longer, &ldquo;first
          party&rdquo; cookies that we place and &ldquo;third party&rdquo;
          cookies that our third party business partners and service providers
          place.&nbsp;
        </li>
        <li>
          <strong>Web beacons</strong>, also known as pixel tags or clear GIFs,
          which are clear images placed in web content or HTML emails to record
          when a user visits a web page or interacts with an email.&nbsp;
        </li>
        <li>
          <strong>Local storage technologies</strong>, like HTML5 , that provide
          cookie-equivalent functionality but can store larger amounts of data
          on your device outside of your browser in connection with specific
          applications.&nbsp;
        </li>
      </ul>
      <p>
        <strong>Chat. </strong>We may use chat technologies provided by one or
        more third-party vendors that employ cookies and software code to
        operate the chat features you can use to communicate with us through the
        Services. We and such third-party vendors may access and use information
        about webpages visited on our website, your IP address, your general
        geographic information (e.g., city, state), and other personal
        information you share through online chats for the purposes described in
        this Privacy Policy<em>.&nbsp;</em>
      </p>
      <p>
        These cookies and other technologies may be employed for the following
        purposes:&nbsp;
      </p>
      <ul>
        <li>
          <strong>Technical operation</strong>. To allow the technical operation
          of the Services, such as by remembering your selections and
          preferences as you navigate the site, and whether you are logged in
          when you visit password protected areas of the Services.&nbsp;
        </li>
        <li>
          <strong>Functionality. </strong>To provide enhanced functionality and
          personalization on the Services.&nbsp;
        </li>
        <li>
          <strong>Analytics</strong>. These technologies help us understand how
          our services are performing and being used.&nbsp;
        </li>
      </ul>
      <p>
        <strong>Google user data&nbsp;</strong>
      </p>
      <p>
        If you connect the Services to a Gmail account or if you use the Gmail
        integration or choose to integrate any other Google Workspace
        application with our Services, you will be asked to give Day AI access
        to information from your Gmail or Google account. This Privacy Policy
        will apply to Google user data collected as well as to other categories
        of personal information. You can learn more about how we access this
        data via in-app notice when you connect to Gmail.&nbsp; By using the
        Gmail integration with the Services, you grant the Services access to
        information associated with your account, including contacts, emails,
        calendar, distribution lists, and subject lines. In addition, the
        Services will be able to read, modify, create, and send emails from your
        connected Gmail account. The Services will store replies, outgoing mail,
        incoming mail, email headers, subject line, distribution lists, aliases,
        time sent, and email bodies. Your email may contain sensitive
        information, such as names of your contacts, your private
        communications, or financial or medical information.&nbsp;
      </p>
      <p>
        <strong>Additional Limits on Use of Your Google User Data</strong>
      </p>
      <p>
        Our use and transfer to any other app of information received from
        Google APIs will adhere to the{' '}
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </p>

      <p>
        <strong>Deletion & Removal of Google API Data</strong>
      </p>
      <p>
        You can disconnect and delete the connection from Day AI to the Google
        APIs from your account settings. This will remove the connection and all
        Google API object data from your account. Deletion will be completed
        within 24 hours of your action. If you have any questions about the
        deletion or removal of your Google API data, please contact us at
        privacy@day.ai.
      </p>
      <p>
        <strong>Google Calendar Integration</strong>
      </p>
      <p>
        The Services will have access to both your Google Calendar and any other
        calendar you access via Google in order to power the meetings tool, and
        allow you to associate events with contacts in your Day AI account. The
        Services will have the ability to: create or change your calendars, and
        update individual calendar events.
      </p>
      <p>
        <strong>Google reCAPTCHA Enterprise</strong>
      </p>
      <p>
        We may implement Google reCAPTCHA Enterprise on certain products and
        services to prevent malicious software from engaging in abusive
        activities on our website.&nbsp; You can learn more about data collected
        by Google and what this data is used for in Google&rsquo;s Privacy
        Policy.&nbsp;
      </p>
      <p>
        Additional information about Google services and products is available
        in Google Terms of Service.&nbsp;
      </p>
      <p>
        Notwithstanding anything else in this Privacy Policy, if you provide the
        Services access to the following types of your Google data, the use of
        that data in connection with the Services will be subject to these
        additional restrictions:&nbsp;
      </p>
      <ul>
        <li>
          The Services will not use Gmail message bodies (including
          attachments), metadata, headers, and settings for serving
          advertisements.&nbsp;
        </li>
        <li>
          The Services will not allow humans to read Gmail message bodies
          (including attachments), metadata, headers, and settings unless we
          have your affirmative agreement to view specific messages, files, or
          other data, doing so is necessary for security purposes such as
          investigating a bug or abuse, doing so is necessary to comply with
          applicable law, or for the Services&rsquo; internal operations in
          accordance with applicable privacy and other jurisdictional
          requirements and even then only when the data have been
          aggregated.&nbsp;
        </li>
      </ul>
      <p>
        <strong>How we use your personal information&nbsp;</strong>
      </p>
      <p>
        When you use the Services, we process your personal information as a
        processor under the direction of our customers. Our customers, as data
        controllers, are responsible for determining the categories of personal
        information we process and the purpose of the processing and for
        complying with any regulations or laws that require providing notice,
        disclosure, and/or obtaining consent prior to collecting their
        customers&rsquo; personal information using the Services. We may use
        your personal information for the following purposes or as otherwise
        described at the time of collection:&nbsp;
      </p>
      <p>
        <strong>Service delivery. </strong>We may use your personal information
        to:&nbsp;
      </p>
      <ul>
        <li>
          provide, operate and improve the Services and our business.&nbsp;
        </li>
        <li>
          facilitate your invitations to contacts who you want to invite to join
          the Services.&nbsp;
        </li>
        <li>
          communicate with you about the Services, including by sending
          announcements, updates, security alerts, and support and
          administrative messages.&nbsp;
        </li>
        <li>
          understand your needs and interests, and personalize your experience
          with the Services and our communications;&nbsp;
        </li>
        <li>link or combine user information with personal information;</li>
        <li>
          provide automated decision making and/or profiling for some of the
          Services, for example by providing you with feature suggestions based
          on your email information or behavior inside our Services. You can
          limit or object to such automated decision making and/or profiling by
          contacting us at{' '}
          <a
            href="mailto:privacy@day.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy@day.ai
          </a>
          ; and
        </li>
        <li>
          provide support for the Services, and respond to your requests,
          questions and feedback.&nbsp;
        </li>
      </ul>
      <p>
        <strong>Research and development. </strong>We may use your personal
        information for research and development purposes, including to analyze
        and improve the Services and our business. As part of these activities,
        we may create aggregated or de-identified data from personal information
        we collect. We de-identify personal information by removing information
        that makes the data personally identifiable to you. We may use this data
        and share it with third parties for our lawful business purposes,
        including to analyze, improve and promote the Services and our business.
        We also may use your personal information for machine learning that
        supports certain product features and functionality with the
        Services.&nbsp; We train our proprietary artificial intelligence
        technology on de-identified personal information. We also train our
        technology on transcriptions to provide more accurate Services, which
        may contain personal information. Use of your personal information in
        connection with machine learning will be in accordance with our{' '}
        <a
          href="https://day.ai/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
        .
      </p>
      <p>
        <strong>Compliance and protection. </strong>We may use your personal
        information to:&nbsp;
      </p>
      <ul>
        <li>
          comply with applicable laws, lawful requests, and legal process, such
          as to respond to subpoenas or requests from government
          authorities.&nbsp;
        </li>
        <li>
          protect our, your or others&rsquo; rights, privacy, safety or property
          (including by making and defending legal claims).&nbsp;
        </li>
        <li>
          audit our internal processes for compliance with legal and contractual
          requirements and internal policies.&nbsp;
        </li>
        <li>
          enforce the terms and conditions that govern the Services; and&nbsp;
        </li>
        <li>
          prevent, identify, investigate and deter fraudulent, harmful,
          unauthorized, unethical or illegal activity, including cyberattacks
          and identity theft.&nbsp;
        </li>
      </ul>
      <p>
        <strong>How we share your personal information&nbsp;</strong>
      </p>
      <p>
        We do not share your personal information, unless this is necessary for
        the performance of our Services, for the compliance with a legal
        obligation, for the purpose of our legitimate interest or if you have
        given your consent. We may share your personal information with the
        following recipients:&nbsp;
      </p>
      <p>
        <strong>Service providers. </strong>Third parties that provide services
        on our behalf or help us operate the Services or our business (such as
        hosting, information technology, customer support, email delivery,
        marketing, payment processing, consumer research, website analytics,
        cloud service providers, platform support providers, data labeling
        service providers and artificial intelligence service providers that
        provide backend support for certain Services).&nbsp;
      </p>
      <p>
        <strong>Payment processors</strong>. Any payment card information you
        use to make a purchase on the Services is collected and processed
        directly by our payment processors. Payment processors will use your
        personal information as described in their own privacy policies, so we
        encourage you to read them.&nbsp;
      </p>
      <p>
        <strong>Third-party platforms and social media networks. </strong>If you
        have enabled features or functionality that connect the Services to a
        third-party platform (such as by logging in to the Services using your
        account with the third-party, providing your API key or similar access
        token for the Services to a third party, or otherwise linking your
        account with the Services to a third party&rsquo;s services), we may
        disclose the personal information that you authorized us to share. We do
        not control the third party&rsquo;s use of your personal
        information.&nbsp;
      </p>
      <p>
        <strong>Professional advisors. </strong>Professional advisors, such as
        lawyers, auditors, bankers and insurers, where necessary in the course
        of the professional services that they render to us.&nbsp;
      </p>
      <p>
        <strong>Authorities and others. </strong>Law enforcement, government
        authorities, and private parties, as we believe in good faith to be
        necessary or appropriate for the compliance and protection purposes
        described above.&nbsp;
      </p>
      <p>
        <strong>Business transferees. </strong>Acquirers and other relevant
        participants in business transactions (or negotiations and due diligence
        for such transactions) involving a corporate divestiture, merger,
        consolidation, acquisition, reorganization, sale or other disposition of
        all or any portion of the business or assets of, or equity interests in
        Day AI (including in connection with a bankruptcy or similar
        proceedings).&nbsp;
      </p>
      <p>
        <strong>Other users. </strong>
      </p>
      <p>
        You can choose to give other users of the Services access to your
        profile, contact network, strength and existence of your relationships,
        communication history and other user-generated content. We are not
        responsible for other users&rsquo; use of this information.&nbsp;
      </p>
      <p>
        <strong>Security&nbsp;</strong>
      </p>
      <p>
        We employ a number of technical, organizational and physical safeguards
        designed to protect the personal information we collect. However,
        security risk is inherent in all internet and information technologies
        and we cannot guarantee the security of your personal
        information.&nbsp;&nbsp;
      </p>
      <p>
        <strong>European Data Protection Rights&nbsp;</strong>
      </p>
      <p>
        We will only process your personal information if and to the extent we
        have a legal basis for doing so. The following are the legal bases that
        we rely upon to justify our processing:&nbsp;
      </p>
      <ul>
        <li>
          <strong>Your consent </strong>&ndash; We will process your personal
          information with your prior consent, which you may give directly to
          us, or to other organizations (e.g., our third party data
          providers).&nbsp;
        </li>
        <li>
          <strong>Performance of a contract </strong>&ndash; We will process
          your personal information where necessary to enter into, or for the
          performance of a contract. For example, we process your personal
          information to:&nbsp;
        </li>
        <ul>
          <li>
            provide, operate and improve the Services and our business;&nbsp;
          </li>
          <li>
            facilitate your invitations to contacts who you want to invite to
            join the Services;&nbsp;
          </li>
          <li>
            communicate with you about the Services, including by sending
            announcements, updates, security alerts, and support and
            administrative messages;&nbsp;
          </li>
          <li>
            provide support for the Services, and respond to your requests,
            questions and feedback;&nbsp;
          </li>
          <li>
            enforce the terms and conditions that govern the Services.&nbsp;
          </li>
        </ul>
        <li>
          <strong>Legitimate interests </strong>&ndash; We will process your
          personal information for the purposes of our legitimate interests, or
          the legitimate interests of third parties we work with, as long as
          these interests are not overridden by your interest or fundamental
          rights and freedoms. For example, we may process your personal
          information for the following legitimate interests:&nbsp;
        </li>
        <ul>
          <li>
            providing and operating our Services (e.g., processing your contact
            details that are included in the header or message body of an email
            because you exchanged emails with someone that is using our Services
            or because your employer is using our service);&nbsp;
          </li>
          <li>
            carrying out direct marketing activities, unless consent is required
            in which case, we would obtain consent;&nbsp;
          </li>
          <li>analyzing and improving the Services and our business;&nbsp;</li>
          <li>
            creating aggregated, de-identified or other anonymous data from
            personal information we collect;&nbsp;
          </li>
          <li>
            preventing, identifying, investigating and detecting fraudulent,
            harmful, unauthorized, unethical or illegal activity, including
            cyberattacks and identity theft;&nbsp;
          </li>
          <li>
            auditing our internal processes for compliance with legal and
            contractual requirements and internal policies;&nbsp;
          </li>
          <li>
            disclose or transfer personal information in the event of a business
            transactions (or negotiations and due diligence for such
            transactions) involving a corporate, merger, consolidation,
            acquisition, reorganization, sale or other disposition of all or any
            portion of the business or assets of, or equity interest).&nbsp;
          </li>
        </ul>
      </ul>
      <p>
        In all of the above cases, we carefully strike a balance between our
        legitimate business interests and your interests or fundamental rights.
        For more information in the individual case, you can contact us at{' '}
        <a
          href="mailto:privacy@day.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy@day.ai
        </a>
        . You have the right to complain to a data protection authority about
        our collection and use of your personal information. For more
        information, if you are in the European Economic Area (EEA), please
        contact your local data protection authority in the EEA.
      </p>
      <ul>
        <li>
          <strong>Compliance with legal obligations </strong>&ndash; We will
          process your personal information if it is necessary to comply with
          legal obligations. For example, we may process your personal
          information to:&nbsp;
        </li>
        <ul>
          <li>
            comply with applicable laws, lawful requests, and legal process,
            such as to respond to subpoenas or requests from government
            authorities;&nbsp;
          </li>
          <li>
            protect our, your or others&rsquo; rights, privacy, safety or
            property (including by making and defending legal claims).&nbsp;
          </li>
        </ul>
      </ul>
      <p>
        When Day AI processes your personal data and such processing is subject
        to European Union (EU GDPR) or United Kingdom (UK GDPR, UK Data
        Protection Act 2018) data protection law, depending on the applicable
        law, you may have certain rights with respect to that data,
        namely:&nbsp;
      </p>
      <p>
        <strong>Right of Access. </strong>You have the right to request
        confirmation as to whether or not we process your personal data. If so,
        you also have the right to request access to the personal and further
        information as specified under the applicable data protection law.
        <strong>Right to Rectification. </strong>You have the right to request
        that we correct inaccurate or incomplete personal data that we store
        about you.<strong>Right to Erasure. </strong>Under certain conditions,
        you have the right to request that we erase the personal data we may
        hold about you.<strong>Right to Data Portability. </strong>Under certain
        conditions, you have the right to receive the personal data, which you
        have provided us in a structured, commonly used, and machine-readable
        format and to transfer those data to another controller.
        <strong>Right to Withdraw Your Consent. </strong>If the processing of
        personal data is based on your consent, you can withdraw consent at any
        time. The withdrawal of your consent does not affect the legitimacy of
        the processing that occurred based on your consent until the withdrawal.
        <strong>Right to Object</strong>. Under certain conditions, you have the
        right to object to the processing ofyour personal data, in case the
        processing is based on legitimate interests or in case your personal
        data are processed for direct marketing purposes.
        <strong>Right to Restriction of Processing. </strong>Under certain
        conditions, you have the right to request the restriction of the
        processing of your personal data.&nbsp;
      </p>
      <p>
        To exercise any of these rights (if they are available to you under the
        applicable law), please contact us at privacy@day.ai. When another
        entity is the data controller (such as your employer when you use the
        Services), we will refer your request to the third-party data controller
        and/or provide you with the information you need to contact the data
        controller directly.&nbsp;
      </p>
      <p>
        You can also lodge a complaint with a competent data protection
        supervisory authority if you consider that the processing of your
        personal data infringes the applicable data protection law.&nbsp;
      </p>
      <p>
        To the extent permitted by applicable law, we reserve the right to
        charge a fee or decline requests: (i) that are unreasonable or
        excessive; (ii) where providing the data would be prohibited by law or
        could adversely affect the privacy or other rights of another person; or
        (iii) where we are unable to authenticate you as the person to whom the
        data relates. Also, please note that we often need to retain certain
        data for reasons such as recordkeeping and/or to complete any
        transaction that you began prior to requesting a change or deletion of
        your data.&nbsp;
      </p>
      <p>
        When you contact us about exercising any of the rights available to you
        under the applicable data protection law, we will ask you for
        information to verify your identity. In your request, please clearly
        identify the personal data that is the subject of your inquiry. We will
        comply with your request as soon as reasonably practicable and within
        any time frames prescribed by law.&nbsp;
      </p>
      <p>
        <strong>International data transfer&nbsp;</strong>
      </p>
      <p>
        We are headquartered in the United States and may use service providers
        that also operate in the US or in other countries outside the country in
        which you reside. Therefore, your personal information may be
        transferred to countries which may not provide for the same level of
        data protection as guaranteed under the applicable data protection laws
        of the country in which you reside (e.g., the EU GDPR). For all such
        transfers we ensure that these countries are either considered adequate
        according to the applicable data protection law (e.g., by way of an
        adequacy decision of the EU commission) or that appropriate safeguards
        are in place such as standard contractual clauses. If you want to obtain
        a copy of these safeguards, you can contact us at privacy@day.ai.&nbsp;
      </p>
      <p>
        <strong>Storage period&nbsp;</strong>
      </p>
      <p>
        Personal information shall be processed and stored for as long as
        required by the purpose they have been collected for. Therefore:
      </p>
      <ul>
        <li>
          Personal information collected for purposes related to the performance
          of a contract between you and the Company shall be retained until such
          contract has been fully performed.
        </li>
        <li>
          Personal information collected for the purposes of the Company&rsquo;s
          legitimate interests shall be retained as long as needed to fulfill
          such purposes. You may find specific information regarding the
          legitimate interests pursued by the Company within the relevant
          section of this Privacy Policy or by contacting the Company.
        </li>
        <li>
          The Company may be allowed to retain personal information for a longer
          period whenever you have given consent to such processing, as long as
          such consent is not withdrawn.
        </li>
        <li>
          We may retain the data for a longer period if required for compliance
          with a legal obligation according to applicable law (e.g., to meet tax
          or commercial law retention obligations) or to the extent required for
          establishment, exercise, or defense of legal claims.&nbsp;
        </li>
        <li>
          Once the retention period expires, the applicable personal information
          shall be deleted.&nbsp; Therefore, the right of access, the right to
          erasure, the right to rectification and the right to data portability
          cannot be enforced after expiration of the retention period.
        </li>
      </ul>
      <p>
        <strong>Access or update your information. </strong>If you have
        registered for an account with us, you may review and update certain
        account information by logging into the account.&nbsp;
      </p>
      <p>
        <strong>Opt-out of marketing communications. </strong>You may opt-out of
        marketing-related emails by following the opt-out or unsubscribe
        instructions at the bottom of the email, or by contacting us at{' '}
        <a
          href="mailto:privacy@day.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy@day.ai
        </a>
        .&nbsp; Please note that if you choose to opt-out of marketing-related
        emails, you may continue to receive service-related and other
        non-marketing emails.&nbsp;
      </p>
      <p>
        <strong>Cookies. </strong>Most browsers let you remove or reject
        cookies. To do this, follow the instructions in your browser settings.
        Many browsers accept cookies by default until you change your settings.
        Please note that if you set your browser to disable cookies, the
        Services may not work properly. For more information about cookies,
        including how to see what cookies have been set on your browser and how
        to manage and delete them, visit www.allaboutcookies.org.&nbsp;
      </p>
      <p>
        <strong>Do Not Track. </strong>Some Internet browsers may be configured
        to send &ldquo;Do Not Track&rdquo; signals to the online services that
        you visit. We currently do not respond to &ldquo;Do Not Track&rdquo;
        signals. To find out more about &ldquo;Do Not Track,&rdquo; please visit
        http://www.allaboutdnt.com.&nbsp;
      </p>
      <p>
        <strong>Linked third-party platforms</strong>. If you choose to connect
        to the Services through your social media account or other third-party
        platform, you may be able to use your settings in your account with that
        platform to limit the information we receive from it. If you revoke our
        ability to access information from a third-party platform, that choice
        will not apply to information that we have already received from that
        third party.&nbsp;
      </p>
      <p>
        <strong>Other sites and services&nbsp;</strong>
      </p>
      <p>
        The Services may contain links to websites and other online services
        operated by third parties and our content may be integrated into other
        online services that are not associated with us. These links and
        integrations are not an endorsement of, or representation that we are
        affiliated with, any third party. We do not control any online services
        operated by third parties, and we are not responsible for their actions.
        We encourage you to read the privacy policies of the other online
        services you use.&nbsp;
      </p>
      <p>
        <strong>Children&nbsp;</strong>
      </p>
      <p>
        The Services are not intended for use by anyone under 18 years of age.
        If you are a parent or guardian of a child from whom you believe we have
        collected personal information in a manner prohibited by law, please
        contact us at privacy@day.ai. If we learn that we have collected
        personal information through the Services from a child without the
        consent of the child&rsquo;s parent or guardian as required by law, we
        will delete it.&nbsp;
      </p>
      <p>
        <strong>Changes to this Privacy Policy&nbsp;</strong>
      </p>
      <p>
        We reserve the right to modify this Privacy Policy at any time. If we
        make material changes to this Privacy Policy, we will notify you by
        updating the date of this Privacy Policy and posting it on our website.
        If required by law we will also provide notification of changes in
        another way that we believe is reasonably likely to reach you, such as
        via email or another manner through the Services. Any modifications to
        this Privacy Policy will be effective upon our posting the modified
        version (or as otherwise indicated at the time of posting).&nbsp;
      </p>
      <p>
        <strong>How to contact us&nbsp;</strong>
      </p>
      <p>
        For inquiries or complaints regarding this Privacy Policy, please first
        contact us at{' '}
        <a
          href="mailto:privacy@day.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy@day.ai
        </a>{' '}
        and we will endeavor to deal with your complaint as soon as possible.
        This is without prejudice to your right to launch a claim with a data
        protection authority.
      </p>
      <p>
        If you are based in the EEA or the UK, you may also make a complaint to
        either the Irish Data Protection Commission (on +353 578 684 800 or
        via&nbsp;
        <a
          href="https://forms.dataprotection.ie/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://forms.dataprotection.ie/contact
        </a>
        ) or the UK&rsquo;s ICO (on +44 303 123 1113 or via&nbsp;
        <a
          href="https://ico.org.uk/make-a-complaint/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ico.org.uk/make-a-complaint/
        </a>
        ), or to the supervisory authority where you are located.
      </p>
      <p>
        <strong>State law privacy rights&nbsp;</strong>
      </p>
      <p>
        <strong>Scope. </strong>This section applies to residents of California
        and Virginia, except that provisions identifying a specific state apply
        only to that state. If you are not a resident of the state in which
        these rights are granted, you may not be able to exercise these
        rights.&nbsp;
      </p>
      <p>
        For purposes of this section, &ldquo;personal information&rdquo; has the
        meaning given to &ldquo;personal data&rdquo;, &ldquo;personal
        information&rdquo; or similar terms under the applicable privacy laws of
        the state in which you reside, but does not include information exempted
        from such laws.&nbsp;
      </p>
      <p>
        In some cases, we may provide a different privacy notice to certain
        categories of residents of these states, in which case that notice will
        apply with respect to the activities it describes instead of this
        section.&nbsp;
      </p>
      <p>
        <strong>Your privacy rights. </strong>You have the rights listed
        below.&nbsp;
      </p>
      <ul>
        <li>
          <strong>Right to know. </strong>You can request confirmation about
          whether or not we have collected your personal information, and
          information about the categories of personal information that we have
          collected, the categories of sources from which we collected personal
          information, the business or commercial purpose for collecting,
          sharing and/or selling personal information, the categories of third
          parties with whom we share personal information, the categories of
          personal information that we sold, shared or disclosed for a business
          purpose, and the categories of third parties with whom the personal
          information was sold, shared or disclosed for a business
          purpose.&nbsp;
        </li>
        <li>
          <strong>Right to access. </strong>You can request a copy of certain
          personal information that we have collected about you.&nbsp;
        </li>
        <li>
          <strong>Right to delete. </strong>You can ask us to delete certain
          personal information that we maintain about you.&nbsp;
        </li>
        <li>
          <strong>Right to correct</strong>. You can ask us to correct
          inaccurate personal information that we have collected about
          you.&nbsp;
        </li>
        <li>
          <strong>Right to nondiscrimination</strong>. You are entitled to
          exercise the rights described above free from discrimination as
          prohibited by the privacy laws of the state where you reside.&nbsp;
        </li>
      </ul>
      <p>
        <strong>How to exercise your rights</strong>. You can exercise the
        privacy rights described above by submitting a request to
        privacy@day.ai. The rights described above are subject to limits and
        exemptions and in certain cases we may decline your request as permitted
        by law. We cannot process your request if you do not provide us with
        sufficient detail to allow us to understand and respond to it. You can
        ask to appeal any denial of your request in the same manner that you may
        submit the request.&nbsp;
      </p>
      <p>
        <strong>Verification of identity. </strong>We may need to verify your
        identity to process your requests to exercise your rights to know,
        access, deletion, and correction, and we reserve the right to confirm
        your residency. To verify your identity, we may require you to log into
        your Day AI account if you have one, provide information we can match
        against information we may have collected from you previously, confirm
        your request using the email address we have on file, provide government
        identification, or provide a declaration under penalty of perjury, where
        permitted by law.&nbsp;
      </p>
      <p>
        <strong>Authorized agents. </strong>If you have not provided your agent
        with such a power of attorney, we may ask you and/or your agent to take
        steps permitted by law to verify that your request is authorized, such
        as information required to verify your identity and that you have given
        the authorized agent permission to submit the request. If you are a
        California resident, your authorized agent may make a request on your
        behalf upon our verification of the agent&rsquo;s identity and our
        receipt of a copy of a valid power of attorney given to your authorized
        agent pursuant to applicable California law.&nbsp;
      </p>
      <p>
        <strong>Additional information for California residents. </strong>The
        following describes our practices currently and during the past 12
        months:&nbsp;
      </p>
      <ul>
        <li>
          <strong>Sensitive personal information</strong>. We do not use or
          disclose sensitive personal information for restricted purposes that
          California residents have a right to limit under the CCPA.&nbsp;
        </li>
        <li>
          <strong>Deidentified data</strong>. We do not to attempt to reidentify
          deidentified information that we derive from personal information,
          except that we may do so to test whether our deidentification
          processes comply with applicable law.&nbsp;
        </li>
        <li>
          <strong>Sources and purposes</strong>. We collect all categories of
          personal information from the sources and use them for the business
          purposes described in the How we use your personal information section
          above, and we disclose them for the business purposes described in the
          How we share your personal information section above.&nbsp;
        </li>
        <li>
          <strong>Categories and disclosure</strong>. The chart below describes
          the personal information we collect by reference to the categories of
          personal information specified in the CCPA (Cal. Civ. Code
          &sect;1798.140), and the categories of third parties to whom we
          disclose it. The terms in the chart refer to the data types and third
          parties described above in the Personal information we collect section
          in more detail. Information you voluntarily provide to us, such as in
          emails or free-form webforms, may contain other categories of personal
          information not described below. We may also disclose personal
          information to professional advisors, authorities and others, and
          business transferees as described above in the How we share your
          personal information section of this Privacy Policy.&nbsp;
        </li>
      </ul>
      <p>
        The business and commercial purposes for which we collect this
        information are described in How we use your personal information in
        this Privacy Policy. The categories of third parties to whom we disclose
        this information for a business purpose are described under How we share
        your personal information in this Privacy Policy. The period of time for
        which we retain this information is described under Storage period in
        this Privacy Policy.&nbsp;
      </p>
      <p>
        <strong>Additional information for Virginia residents. </strong>You have
        the right to opt-out of profiling in furtherance of decisions that
        produce legal or similarly significant effects.&nbsp;
      </p>
      <p>
        <strong>Additional information for Nevada residents. </strong>You may
        submit a request to privacy@day.ai directing us not to sell your
        personal information that we have collected or will collect for money.
        We reserve the right to verify the authenticity of the request and your
        identity.&nbsp;
      </p>
    </>
  )
}

export default PrivacyPolicy
