import * as React from 'react'
const DayLogoEmblem = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.09 86.24" {...props}>
    <defs>
      <style>{'.cls-1{fill:currentColor}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="_Radial_Repeat_" data-name="&lt;Radial Repeat&gt;">
          <path
            d="M2.93 63.09a3.51 3.51 0 0 0 3.51 3.51h19a20.07 20.07 0 0 1 19.88 17.81 2 2 0 0 0 2 1.83h16.4a2.05 2.05 0 0 0 2-2 24.62 24.62 0 0 0-24.54-24.67H6.44a3.51 3.51 0 0 0-3.51 3.52Z"
            className="cls-1"
          />
          <path
            d="M9.28 74.63a17.12 17.12 0 0 1 16 11.19.64.64 0 0 0 .6.42H41a.64.64 0 0 0 .63-.67 16.32 16.32 0 0 0-16.22-15.23H2.14A2.14 2.14 0 0 0 0 72.48a2.14 2.14 0 0 0 2.14 2.15Z"
            className="cls-1"
          />
          <circle cx={9.82} cy={81.95} r={4.29} className="cls-1" />
        </g>
        <g id="_Radial_Repeat_2" data-name="&lt;Radial Repeat&gt;">
          <path
            d="M59.06 7.75A3.52 3.52 0 0 0 54.26 9l-9.48 16.46A20.06 20.06 0 0 1 19.4 33.8a2.06 2.06 0 0 0-2.61.85L8.6 48.85a2 2 0 0 0 .75 2.8 24.61 24.61 0 0 0 33.63-9l17.37-30.09a3.53 3.53 0 0 0-1.29-4.81Z"
            className="cls-1"
          />
          <path
            d="M45.89 7.48a17.12 17.12 0 0 1-17.72 8.3.65.65 0 0 0-.66.3L20 29.18a.63.63 0 0 0 .27.88 16.31 16.31 0 0 0 21.27-6.46L53.17 3.45a2.15 2.15 0 0 0-.78-2.93 2.15 2.15 0 0 0-2.93.78Z"
            className="cls-1"
          />
          <circle cx={39.28} cy={4.29} r={4.29} className="cls-1" />
        </g>
        <g id="_Radial_Repeat_3" data-name="&lt;Radial Repeat&gt;">
          <path
            d="M78.91 84a3.52 3.52 0 0 0 1.29-4.8l-9.48-16.39a20.07 20.07 0 0 1 5.46-26.15 2 2 0 0 0 .57-2.66l-8.2-14.2a2 2 0 0 0-2.8-.75 24.62 24.62 0 0 0-9 33.63l17.36 30.06a3.51 3.51 0 0 0 4.8 1.26Z"
            className="cls-1"
          />
          <path
            d="M85.73 72.76a17.15 17.15 0 0 1 1.67-19.49.64.64 0 0 0 .07-.72l-7.56-13.1a.63.63 0 0 0-.9-.21A16.33 16.33 0 0 0 74 60.94l11.59 20.14a2.13 2.13 0 0 0 2.92.79 2.15 2.15 0 0 0 .79-2.93Z"
            className="cls-1"
          />
          <circle cx={91.8} cy={68.63} r={4.29} className="cls-1" />
        </g>
      </g>
    </g>
  </svg>
)
export default DayLogoEmblem
