import { extractEmailDomain } from 'src/lib/contactFormatting'
import { NativeSuggestedPipelineTypes } from 'src/lib/relationshipSummary'

const makeProspectTypeStorageKey = (selectedWorkspace) => {
  return `selectedProspectType-${selectedWorkspace}`
}

export const mapRelTypeToPipelineType = (relType) => {
  const pipelineType = Object.keys(NativeSuggestedPipelineTypes).find((key) =>
    NativeSuggestedPipelineTypes?.[key]?.relationshipTypes?.includes(relType)
  )
  if (pipelineType) {
    return pipelineType
  } else {
    return 'OTHER'
  }
}

export const getCurrentProspectType = (
  organizationOverviews,
  selectedWorkspace
) => {
  const key = makeProspectTypeStorageKey(selectedWorkspace)
  const storedType = localStorage.getItem(key)
  if (
    storedType &&
    Object.keys(NativeSuggestedPipelineTypes).includes(storedType)
  ) {
    return storedType
  }

  const pipelineTypeCounts = organizationOverviews.reduce((acc, overview) => {
    const key = overview?.['status/recommendedStage']?.pipelineType
    if (key) {
      const pipelineType = key
      if (pipelineType) {
        acc[pipelineType] = (acc[pipelineType] || 0) + 1
      }
    }
    return acc
  }, {})

  if (pipelineTypeCounts['NEW_CUSTOMER'] > 10) {
    return 'NEW_CUSTOMER'
  } else if (pipelineTypeCounts['FINANCING_INVESTMENT'] > 10) {
    return 'FINANCING_INVESTMENT'
  } else if (pipelineTypeCounts['EXISTING_CUSTOMER'] > 10) {
    return 'EXISTING_CUSTOMER'
  }

  const highestCountType = Object.entries(pipelineTypeCounts).reduce(
    (max, [type, count]) => (count > max[1] ? [type, count] : max),
    ['', 0]
  )[0]

  const selectedType = highestCountType || 'NEW_CUSTOMER'
  localStorage.setItem(key, selectedType)
  return selectedType
}

export const updateCurrentProspectType = (selectedWorkspace, type) => {
  const key = makeProspectTypeStorageKey(selectedWorkspace)
  localStorage.setItem(key, type)
}

export const getOrgProspects = ({ people, prospectType }) => {
  const matchingPeople = people.filter((person) => {
    const relType = person.properties['relationshipSummary/type']
    const pipelineType = mapRelTypeToPipelineType(relType)
    return pipelineType === prospectType
  })

  const domains = matchingPeople.map((person) =>
    extractEmailDomain(person.objectId || person.properties.email)
  )
  return [...new Set<string>(domains)].filter(Boolean)
}

export const getOrgPeople = ({ people, domain }) => {
  return people.filter(
    (person) =>
      extractEmailDomain(person.objectId || person.properties.email) === domain
  )
}

export const tableButtonSx = {
  flexShrink: 0,
  width: '136px',
  height: '30px',
  justifyContent: 'flex-start',
  '& .MuiButton-startIcon': {
    marginRight: '6px',
  },
}
