import { Box, Typography } from '@mui/material'
import { Opportunity, Organization } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import OrganizationChallenges from '../Organizations/OrganizationChallenges/OrganizationChallenges'
import OrganizationNextSteps from '../Organizations/OrganizationNextSteps/OrganizationNextSteps'
import OrganizationOneSentence from '../Organizations/OrganizationOneSentence/OrganizationOneSentence'
import OrganizationQuotes from '../Organizations/OrganizationQuotes/OrganizationQuotes'
import OrganizationSummary from '../Organizations/OrganizationSummary/OrganizationSummary'
import OrganizationTile from '../Organizations/OrganizationTile/OrganizationTile'
import OrganizationTimeline from '../Organizations/OrganizationTimeline/OrganizationTimeline'
import OpportunityRolesList from '../Pipeline/OpportunityRolesList/OpportunityRolesList'

const SidebarOrganizationOverview = ({
  organization,
  opportunity,
  showAbout = true,
  refetchKey,
}: {
  organization: Organization
  opportunity?: Opportunity
  refetchKey?: string
  showAbout?: boolean
}) => {
  return (
    <Box key={refetchKey}>
      <Box sx={{ mt: 3 }}>
        <OrganizationOneSentence org={organization} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <OrganizationNextSteps org={organization} />
      </Box>
      {opportunity?.id && (
        <Box sx={{ mt: 3 }}>
          <OpportunityRolesList
            id={opportunity?.id}
            showHeader={true}
          />
        </Box>
      )}
      <Box sx={{ mt: 3 }}>
        <OrganizationSummary org={organization} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <OrganizationChallenges org={organization} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <OrganizationQuotes org={organization} />
      </Box>
      {showAbout && (
        <Box sx={{ mt: 3, mb: 6 }}>
          <Typography variant="h2">About</Typography>
          <Box
            sx={{ cursor: 'pointer', mt: 3 }}
            onClick={() => {
              navigate(
                routes.objectDetail({
                  objectId: opportunity.domain,
                  workspaceId: opportunity.workspaceId,
                  objectTypeSlug: 'organizations',
                })
              )
            }}
          >
            <OrganizationTile
              domain={opportunity?.domain || organization?.domain}
              openSidebar={false}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 3 }}>
        <OrganizationTimeline
          org={organization}
          showHeader={true}
        />
      </Box>
    </Box>
  )
}

export default SidebarOrganizationOverview
