import { logger } from 'src/lib/logger'

function trimPipelineData(data, xAxisLabels, timePeriod) {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentQuarter = Math.floor(currentMonth / 3)

  // Determine the starting index based on time period ('month' or 'quarter')
  const startIndex = timePeriod === 'quarter' ? currentQuarter : currentMonth

  // Find the last index where any series has a non-zero value
  let lastIndex = startIndex // Start with current period to ensure it includes at least the current period data.

  const series = [
    'opportunity-forecast-series-won',
    'opportunity-forecast-series-forecast',
    'opportunity-forecast-series-pipeline',
  ]

  series.forEach((series) => {
    data[series].forEach((value, index) => {
      if (value !== 0 && index > lastIndex) {
        lastIndex = index
      }
    })
  })

  // Ensure that the lastIndex is at least the startIndex
  lastIndex = Math.max(lastIndex, startIndex)
  logger.dev({ startIndex, lastIndex })

  // Slice the data arrays from startIndex to lastIndex + 1
  const trimmedData = {
    'opportunity-forecast-series-won': data[
      'opportunity-forecast-series-won'
    ].slice(startIndex, lastIndex + 1),
    'opportunity-forecast-series-forecast': data[
      'opportunity-forecast-series-forecast'
    ].slice(startIndex, lastIndex + 1),
    'opportunity-forecast-series-pipeline': data[
      'opportunity-forecast-series-pipeline'
    ].slice(startIndex, lastIndex + 1),
  }

  // Slice the xAxisLabels accordingly
  const trimmedXAxisLabels = xAxisLabels.slice(startIndex, lastIndex + 1)

  return { data: trimmedData, xAxisLabels: trimmedXAxisLabels }
}

export const preparePipelineChartData = ({ stages, timePeriod }) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const result = {
    'opportunity-forecast-series-won': [] as number[],
    'opportunity-forecast-series-forecast': [] as number[],
    'opportunity-forecast-series-pipeline': [] as number[],
  }
  const xAxisLabels = []

  // Initialize arrays based on the number of months/quarters.
  const months = timePeriod === 'quarter' ? 4 : 12
  for (let i = 0; i < months; i++) {
    result['opportunity-forecast-series-won'].push(0)
    result['opportunity-forecast-series-forecast'].push(0)
    result['opportunity-forecast-series-pipeline'].push(0)
    xAxisLabels.push(timePeriod === 'quarter' ? `Q${i + 1}` : monthNames[i])
  }

  stages.forEach((stage) => {
    stage.opportunities.forEach((opportunity) => {
      const date = new Date(opportunity.expectedCloseDate)
      const month = date.getMonth()
      const quarter = Math.floor(month / 3)
      const index = timePeriod === 'quarter' ? quarter : month

      const revenue = opportunity.expectedRevenue
      const likelihood = stage.likelihoodToClose

      if (likelihood === 1) {
        result['opportunity-forecast-series-won'][index] += revenue
      } else {
        const forecastedRevenue = revenue * likelihood
        result['opportunity-forecast-series-forecast'][index] +=
          forecastedRevenue
        result['opportunity-forecast-series-pipeline'][index] +=
          revenue - forecastedRevenue
      }
    })
  })

  const trimmedResult = trimPipelineData(result, xAxisLabels, timePeriod)

  logger.dev({ trimmedResult })
  return trimmedResult
}
