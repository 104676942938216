import { useMutation } from '@apollo/client'
import { Box, Button } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const UPDATE_CALENDAR_AUTO_RECORD_SETTINGS = gql`
  mutation UpdateCalendarAutoRecordSettings(
    $input: UpdateCalendarAutoRecordSettingsInput!
  ) {
    updateCalendarAutoRecordSettings(input: $input) {
      mode
      availableModes
    }
  }
`

const labels = {
  ALL: 'All meetings by default',
  INTERNAL_ONLY: 'Internal meetings by default',
  EXTERNAL_ONLY: 'External meetings by default',
  NONE: 'No meetings (unless specifically requested)',
}

const OnboardingCalendarRecordingSettings = ({ workAccount, refetch }) => {
  const { refetchWorkAccounts } = useContext(DayContext)
  const [settings, setSettings] = useState(
    workAccount?.calendarAutoRecordSettings
  )
  useEffect(() => {
    setSettings(workAccount?.calendarAutoRecordSettings)
  }, [workAccount?.calendarAutoRecordSettings])

  const [updateCalendarAutoRecordSettings] = useMutation(
    UPDATE_CALENDAR_AUTO_RECORD_SETTINGS,
    {
      onCompleted: () => {
        refetchWorkAccounts()
      },
    }
  )

  const handleModeChange = (mode) => {
    setSettings((prev) => ({ ...prev, mode }))

    const promise = updateCalendarAutoRecordSettings({
      variables: {
        input: {
          workAccountId: workAccount.id,
          mode,
        },
      },
    })

    toast.promise(promise, {
      loading: 'Updating settings...',
      success: () => {
        if (typeof refetch === 'function') {
          setTimeout(refetch, 1500)
        }
        return 'Settings updated'
      },
      error: (error) => {
        logger.dev({ error })
        return 'Failed to update settings'
      },
    })
  }

  return (
    workAccount &&
    settings && (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Box component="span">{`For your `}</Box>
          <Box
            component="span"
            sx={{ fontWeight: 600 }}
          >{`${workAccount.email}`}</Box>
          <Box component="span">{` calendar, Day.ai should record:`}</Box>
        </Box>
        {settings.availableModes.map(
          (mode) =>
            mode != 'NONE' && (
              <Button
                key={mode}
                variant={settings.mode === mode ? 'contained' : 'outlined'}
                onClick={() => handleModeChange(mode)}
                sx={{ textTransform: 'none' }}
              >
                {labels[mode]}
              </Button>
            )
        )}
      </Box>
    )
  )
}

export default OnboardingCalendarRecordingSettings
