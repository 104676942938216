import { lighten } from '@mui/material/styles'
import {
  RiCheckFill,
  RiFlagLine,
  RiPencilLine,
  RiQuestionFill,
  RiVerifiedBadgeLine,
} from '@remixicon/react'

import DayLogoEmblem from 'src/components/DayLogoEmblem/DayLogoEmblem'

export const getCoreContactVerificationStates = ({ theme }) => {
  const verificationStates = {
    FLAGGED: {
      label: 'Flagged as incorrect',
      tooltipText: 'Flagged as incorrect. Click to correct.',
      icon: <RiFlagLine />,
      color: theme.palette.warning.light,
      hoverColor: theme.palette.warning.main,
      explanation:
        'The Day.ai team is investigating and correcting the data for this person presently.',
      filter: 'grayscale(100%) blur(4px)',
      alertSeverity: 'warning',
      alertIcon: <RiFlagLine />,
      overlayColor: lighten(theme.palette.warning.light, 0.8),
      opacity: 0.8,
      verifiable: true,
    },
    MANUALLY_VERIFIED: {
      label: 'Verified',
      tooltipText: "Click to flag/edit this person's information",
      icon: <RiVerifiedBadgeLine />,
      color: theme.palette.success.light,
      hoverColor: theme.palette.success.main,
      explanation:
        'This person has been manually verified by a human. Click below if it does not appear correct, and we will be notified (and you can fix it yourself if you like).',
      filter: 'none',
      alertSeverity: 'success',
      alertIcon: <RiVerifiedBadgeLine />,
      overlayColor: 'transparent',
      opacity: 1,
      verifiable: false,
    },
    ALMOST_VERIFIED: {
      label: 'Almost verified',
      tooltipText: 'Please click to verify this persons information',
      icon: <RiPencilLine />,
      color: theme.palette.info.light,
      hoverColor: theme.palette.info.main,
      explanation:
        'This person has been partially verified by the Day.ai team.',
      filter: 'grayscale(100%) blur(1px)',
      alertSeverity: 'info',
      alertIcon: <RiQuestionFill />,
      overlayColor: 'white', //lighten(theme.palette.info.light, 0.8),
      opacity: 0.8,
      verifiable: true,
    },
    UNVERIFIED: {
      label: 'Unverified',
      tooltipText: 'Click to identify this person',
      icon: <RiQuestionFill />,
      color: theme.palette.info.light,
      hoverColor: theme.palette.info.main,
      explanation: 'This person has not been verified by the Day.ai team.',
      filter: 'grayscale(100%) blur(1px)',
      alertSeverity: 'info',
      alertIcon: <RiQuestionFill />,
      overlayColor: 'white', //lighten(theme.palette.info.light, 0.8),
      opacity: 0.8,
      verifiable: true,
    },
    DAY_AI_USER: {
      label: 'Day.ai user',
      tooltipText: 'Confirmed Day.ai user',
      icon: <RiVerifiedBadgeLine />,
      color: theme.palette.text.primary,
      hoverColor: theme.palette.text.secondary,
      explanation:
        'This person is a Day.ai user and has filled out their own information.',
      filter: 'none',
      alertSeverity: 'success',
      alertIcon: <RiCheckFill />,
      overlayColor: 'transparent',
      opacity: 1,
      verifiable: false,
    },
    IS_DAY_AI: {
      label: 'Day.ai Assistant',
      tooltipText: 'Day.ai Assistant',
      icon: <DayLogoEmblem style={{ height: '14px' }} />,
      color: theme.palette.text.primary,
      hoverColor: theme.palette.text.secondary,
      explanation:
        'This contact is a Day.ai user and has filled out their own information.',
      filter: 'none',
      alertSeverity: 'success',
      alertIcon: <RiCheckFill />,
      overlayColor: 'transparent',
      opacity: 1,
      verifiable: false,
    },
  }

  return verificationStates
}

export const getCoreContactVerificationState = ({
  coreContact,
  crmObject = {},
  verificationStates,
}) => {
  const email = coreContact?.email || crmObject?.properties?.email
  const linkedInUrl =
    coreContact?.linkedInUrl || crmObject?.properties?.linkedInUrl
  const timezone = coreContact?.timezone || crmObject?.properties?.timezone
  const manuallyVerified =
    coreContact?.manuallyVerified || crmObject?.properties?.manuallyVerified
  const flaggedBy = coreContact?.flaggedBy || crmObject?.properties?.flaggedBy

  let state
  if (email === 'assistant@day.ai') state = verificationStates.IS_DAY_AI
  else if (timezone) state = verificationStates.DAY_AI_USER
  else if (linkedInUrl && !manuallyVerified)
    state = verificationStates.ALMOST_VERIFIED
  else if (flaggedBy?.length > 0) state = verificationStates.FLAGGED
  else if (manuallyVerified) state = verificationStates.MANUALLY_VERIFIED
  else state = verificationStates.UNVERIFIED

  return state
}
