import { useContext, useMemo, useState } from 'react'

import {
  EventNote,
  Google,
  MarkEmailRead,
  RecentActors,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import {
  ConnectWorkAccountToWorkspace,
  WorkAccount,
  WorkAccountDataAuthorizationType,
  Workspace,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { WorkspaceConnectionForm } from 'src/components/ConnectedWorkspacesDialog'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import CoreContactSelf from 'src/components/Enrichment/CoreContactSelf/CoreContactSelf'
import OnboardingCalendarRecordingSettings from 'src/components/OnboardingCalendarRecordingSettings/OnboardingCalendarRecordingSettings'
import Row from 'src/components/Row/Row'
import SignInWithGoogle from 'src/components/SignInWithGoogle/SignInWithGoogle'
import { isWorkAccountHealthy } from 'src/components/WorkAccount/workAccountUtil'
import { DayContext, DayProvider } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const WORK_ACCOUNTS_QUERY_FOR_REFRESH = gql`
  query DayContextFindWorkAccountByOwnerEmailForRefresh($ownerEmail: String!) {
    workAccounts(ownerEmail: $ownerEmail) {
      id
      createdAt
      uuid
      email
      name
      tokenError
      scopes
      crm3WorkspaceId
      googleSyncStatuses {
        type
        status
        reason
        statusChangedAt
        lastSeenSyncToken
        lastSyncedAt
      }
      calendarAutoRecordSettings {
        mode
        availableModes
      }
      notificationSubscriptionOverrides {
        notificationKey
        enabled
      }
      workspaces {
        id
        name
        domains {
          domain
        }
      }
    }
  }
`

const RESPOND_TO_INVITE_WELCOME_PAGE = gql`
  mutation RespondToWorkspaceInviteWelcome(
    $input: RespondToWorkspaceInviteInput!
  ) {
    respondToWorkspaceInvite(input: $input)
  }
`

const UPDATE_SHARING_SETTINGS_WELCOME = gql`
  mutation UpdateWorkAccountWorkspaceSettingsWelcome(
    $input: UpdateWorkAccountSharingInput!
  ) {
    updateWorkAccountSharingRules(input: $input)
  }
`

const CREATE_WORKSPACE_WELCOME = gql`
  mutation CreateWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      id
    }
  }
`

const CONNECT_TO_WORKSPACE_WELCOME = gql`
  mutation ConnectWorkAccountToWorkspaceWelcome(
    $input: ConnectWorkAccountToWorkspaceInput!
  ) {
    connectWorkAccountToWorkspace(input: $input)
  }
`

const GET_CORE_COMPANY_WELCOME = gql`
  query GetCoreCompanyWelcome($domain: String!) {
    coreCompany(domain: $domain) {
      id
      name
    }
  }
`

const isCoreContactComplete = (coreContact) => {
  return (
    coreContact.firstName && coreContact.lastName && coreContact.linkedInUrl
  )
}

const isWorkAccountReady = ({ workAccount }) => {
  if (!workAccount) return false
  return isWorkAccountHealthy({ workAccount })
}

const isWorkspaceReady = (workspace) => {
  logger.dev({ checking: workspace })
  logger.info(
    `workspace?.status: ${workspace?.status} // workspace?.name: ${workspace?.name} // workspace?.isDefault: ${workspace?.isDefault}`
  )
  return workspace?.status === 'ACTIVE'
}

const hasEmailSharingSettings = (
  workAccountWorkspaceConnections,
  workspace
) => {
  logger.info(
    `workAccountWorkspaceConnections length: ${workAccountWorkspaceConnections?.length}`
  )
  if (
    !workAccountWorkspaceConnections ||
    workAccountWorkspaceConnections?.length === 0 ||
    !workspace
  ) {
    logger.info('no workAccountWorkspaceConnections')
    return false
  }

  return workAccountWorkspaceConnections?.some((connectedWorkAccount) => {
    return connectedWorkAccount.connectedWorkspaces?.find(
      (connectedWorkspace) => {
        // todo: check the workspace id matches target workspace and the rules are sufficient
        logger.dev({ rulesLength: connectedWorkspace.sharingRules?.length })
        logger.info(
          `connectedWorkspace.workspaceId: ${connectedWorkspace.workspaceId}`
        )
        logger.info(
          `connectedWorkspace.sharingRules?.length: ${connectedWorkspace.sharingRules?.length}`
        )
        return (
          connectedWorkspace.workspaceId === workspace.id &&
          connectedWorkspace.sharingRules?.length >= 6
        )
      }
    )
  })
}

const hasRecordingSettings = (workAccounts) => {
  const enabled = workAccounts.filter((workAccount) => {
    return workAccount?.calendarAutoRecordSettings?.mode != 'NONE'
  })
  logger.info(
    `enabled.length: ${enabled.length} // workAccounts.length: ${workAccounts.length}`
  )

  return enabled.length === workAccounts.length
}

export const onboardingStatus = ({
  coreContact,
  workAccounts,
  workspace,
  workAccountWorkspaceConnections,
}) => {
  logger.info('onboardingStatus')
  const workAccountsToSkip = workAccounts.filter((workAccount) => {
    return (
      new Date(workAccount.createdAt).getTime() <
      new Date('2024-08-21').getTime()
    )
  })

  if (workAccountsToSkip.length > 0) {
    logger.info('workAccountsToSkip.length > 0')
    return {
      onboardingStep: 5,
      isComplete: true,
    }
  }

  let farthestStep = 0
  if (coreContact && isCoreContactComplete(coreContact)) {
    logger.info(
      `${coreContact.email} // checked coreContact && isCoreContactComplete(coreContact), result: true`
    )
    farthestStep = 1
    if (hasEmailSharingSettings(workAccountWorkspaceConnections, workspace)) {
      logger.info(
        'hasEmailSharingSettings(workAccountWorkspaceConnections, workspace)'
      )
      farthestStep = 4

      if (hasRecordingSettings(workAccounts)) {
        logger.info(`hasRecordingSettings(workAccounts), result: true`)
        farthestStep = 5
      }
    } else {
      if (isWorkspaceReady(workspace)) {
        logger.info(
          `${workspace.name} // isWorkspaceReady(workspace), result: true`
        )
        farthestStep = 3
      } else if (
        isWorkAccountReady({
          workAccount: workAccounts?.[0],
        })
      ) {
        logger.info(
          `${workAccounts?.[0].email} // workspace is not ready. checked isWorkAccountReady({ workAccount: workAccounts?.[0] }): result: true`
        )
        farthestStep = 2
      }
    }
  } else {
    logger.info('no complete coreContact')
    farthestStep = 0
  }
  logger.dev({ farthestStep })
  logger.info(`returning farthestStep: ${farthestStep}`)
  return {
    onboardingStep: farthestStep,
    isComplete: farthestStep === 5,
  }
}

const SharingRules = ({
  workAccount,
  workspace,
  callback,
}: {
  workAccount: WorkAccount
  workspace: Workspace
  callback: () => void
}) => {
  const { currentUser } = useAuth()
  const confirm = useConfirm()
  const { refetchWorkAccountWorkspaceConnections } = useContext(DayContext)
  const [connectToWorkspace] = useMutation<ConnectWorkAccountToWorkspace>(
    CONNECT_TO_WORKSPACE_WELCOME,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: WORK_ACCOUNTS_QUERY_FOR_REFRESH,
          variables: { ownerEmail: currentUser.email as string },
        },
      ],
    }
  )

  const [savingConnection, setSavingConnection] = useState(false)
  const [initialEmailSharingRule, setInitialEmailSharingRule] =
    useState<WorkAccountDataAuthorizationType>('WORKSPACE')
  const [initialRulesByMeetingType, setInitialRulesByMeetingType] = useState<
    Record<string, string>
  >({
    INTERNAL_ONE_ON_ONE: 'PRIVATE',
    INTERNAL_GROUP: 'WORKSPACE',
    EXTERNAL_ONE_ON_ONE: 'WORKSPACE',
    EXTERNAL_GROUP: 'WORKSPACE',
  })
  return (
    <>
      <WorkspaceConnectionForm
        emailSharingRule={initialEmailSharingRule}
        setEmailSharingRule={(rule) => setInitialEmailSharingRule(rule)}
        rulesByMeetingType={initialRulesByMeetingType}
        setRulesByMeetingType={(rules) => setInitialRulesByMeetingType(rules)}
        mode="CONNECT"
        disabled={savingConnection}
      />

      <Box sx={{ pt: 2 }}>
        <Button
          onClick={async () => {
            setSavingConnection(true)
            try {
              if (initialEmailSharingRule === 'WORKSPACE') {
                await confirm({
                  title: 'Sharing email with workspace',
                  description:
                    'This will connect your workspace to your email activity. Day.ai will automatically create and update CRM records and is the recommended setting. You will be able to add fine-graind controls to include/exclude contacts and organizations easily at any point after onboarding.',
                })
              }
              await toast.promise(
                connectToWorkspace({
                  variables: {
                    input: {
                      workAccountUuid: workAccount.uuid,
                      workspaceId: workspace.id,
                      sharingRules: Object.entries(initialRulesByMeetingType)
                        .map(([meetingType, rule]) => ({
                          category: 'MEETINGS',
                          selector: {
                            type: 'MEETING_TYPE',
                            expression: meetingType,
                          },
                          authorization:
                            rule === 'PRIVATE'
                              ? { type: 'PRIVATE' }
                              : { type: 'WORKSPACE' },
                        }))
                        .concat({
                          category: 'EMAILS',
                          selector: { type: 'ALL', expression: '*' },
                          authorization: { type: initialEmailSharingRule },
                        }),
                    },
                  },
                }).then(() => refetchWorkAccountWorkspaceConnections()),
                {
                  loading: 'Connecting...',
                  success: 'Connected',
                  error: 'Error connecting to workspace',
                }
              )
            } catch (_) {
              setSavingConnection(false)
            }

            callback()
          }}
          color="primary"
          variant="contained"
          fullWidth={true}
          disableElevation={true}
          disabled={savingConnection}
        >
          Set sharing rules
        </Button>
      </Box>
    </>
  )
}

const WelcomePageInner = () => {
  const { currentUser } = useAuth()
  const {
    workspaces,
    refetchWorkAccounts,
    workAccountWorkspaceConnections,
    refetchWorkspaces,
    userCoreContact,
    refetchUserCoreContact,
    refetchWorkAccountWorkspaceConnections,
    workAccounts,
    selectedWorkspace,
    setSelectedWorkspace,
    loading,
  } = useContext(DayContext)
  const [savingResponse, setSavingResponse] = useState(false)

  const [createWorkspaceData, setCreateWorkspaceData] = useState({})

  const { data: coreCompanyData } = useQuery(GET_CORE_COMPANY_WELCOME, {
    skip: !createWorkspaceData?.domain,
    variables: {
      domain: createWorkspaceData?.domain,
    },
    onCompleted: (data) => {
      const name = data.coreCompany.name
      setCreateWorkspaceData((prev) => ({
        ...prev,
        name,
      }))
    },
  })

  const [respondToInvite] = useMutation(RESPOND_TO_INVITE_WELCOME_PAGE)
  const [createWorkspace] = useMutation(CREATE_WORKSPACE_WELCOME)

  const refetchAll = () => {
    refetchWorkspaces()
    refetchWorkAccounts()
    refetchUserCoreContact()
    refetchWorkAccountWorkspaceConnections()
  }

  const targetWorkspace = useMemo(() => {
    if (workspaces?.length > 0) {
      for (const workspace of workspaces || []) {
        if (workspace?.status === 'INVITED') {
          return workspace
        } else if (workspace.status === 'ACTIVE') {
          if (!workspace.isDefault || workspace.domains?.length > 0) {
            return workspace
          }
        }
      }
    }

    return null
  }, [workspaces])

  if (workspaces && !targetWorkspace && !createWorkspaceData.domain) {
    const domain = workspaces?.find((ws) => ws.isDefault)?.claimableDomains?.[0]
    if (domain) {
      setCreateWorkspaceData({
        domain,
        autoInvite: true,
        name: userCoreContact.companyName,
      })
    }
  }

  const activeStep = useMemo(() => {
    logger.info('activeState useMemo running')
    logger.dev({
      targetWorkspace,
      workspaces,
      workAccountWorkspaceConnections,
      workAccounts,
      userCoreContact,
    })
    if (
      workspaces &&
      workAccountWorkspaceConnections &&
      workAccounts &&
      userCoreContact
    ) {
      const { onboardingStep } = onboardingStatus({
        coreContact: userCoreContact,
        workAccounts,
        workspace: targetWorkspace,
        workAccountWorkspaceConnections,
      })
      if (targetWorkspace && targetWorkspace.id != selectedWorkspace) {
        logger.info(`setting selectedWorkspace to ${targetWorkspace.id}`)
        setSelectedWorkspace(targetWorkspace.id)
      }
      return onboardingStep
    }
  }, [
    workspaces,
    targetWorkspace,
    workAccountWorkspaceConnections,
    workAccounts,
    userCoreContact,
    selectedWorkspace,
  ])

  const onboardingSteps = [
    {
      label: 'About You',
      description:
        'Accurate professional information helps Day.ai analyze your conversations and relationships more effectively.',
      title: "Let's get to know you",
      image: null,
      component: () => (
        <Box sx={{ mt: 3 }}>
          <CoreContactSelf
            email={currentUser.email}
            callback={refetchAll}
          />
        </Box>
      ),
    },
    {
      label: 'Authorize Google',
      description:
        'Day.ai connects to your Google Calendar and Gmail to help you prepare for, record and review meetings and the human relationships within them.',
      title: 'Connect your Calendar & Email',
      image: null,
      component: () => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {(workAccounts ?? []).length === 0 ? (
            <SignInWithGoogle />
          ) : !isWorkAccountReady({
              workAccount: workAccounts[0],
            }) ? (
            <Box>
              <Typography
                variant="h2"
                sx={{ textAlign: 'center' }}
              >
                Missing permissions
              </Typography>
              <Typography sx={{ mt: 3 }}>
                To use Day.ai, you need to approve all scopes when you authorize
                Google. Make sure all the checkboxes are checked. These are
                powerful scopes and Day.ai gives you fine-grained control over
                how the data is used, including what email content can be seen
                by the Workspace & CRM.
              </Typography>
              <Button
                component="a"
                href={'/api/authRedirectGoogle/authRedirectGoogle'}
                variant="outlined"
                startIcon={<Google />}
                size="large"
                fullWidth={true}
                sx={{ mt: 3 }}
                color="primary"
              >
                Try again
              </Button>
            </Box>
          ) : (
            <>
              {/* this state is theoretically unreachable, but it's here to prevent crashes */}
              Please contact us on Slack (or email support@day.ai), we apologize
              for the inconvenience.
            </>
          )}
        </Box>
      ),
    },
    {
      label: 'Choose a Workspace',
      description:
        'Collaborate with your team, share appropriate conversations automatically and better serve your prospects and customers with Day.ai CRM',
      image: null,
      component: () => (
        <Box>
          {targetWorkspace ? (
            <>
              {targetWorkspace.domains?.[0]?.domain && (
                <Row sx={{ justifyContent: 'center', mb: 1 }}>
                  <DomainAvatar
                    domain={targetWorkspace.domains?.[0]?.domain}
                    size={64}
                  />
                </Row>
              )}
              <Typography
                variant="h2"
                sx={{ textAlign: 'center', mb: 3 }}
              >
                {targetWorkspace.name}
              </Typography>
              <Box>
                {targetWorkspace.status === 'INVITED' && (
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      color="info"
                      sx={{ mr: 1 }}
                      disabled={savingResponse}
                      onClick={async () => {
                        setSavingResponse(true)

                        try {
                          await toast.promise(
                            respondToInvite({
                              variables: {
                                input: {
                                  workspaceId: targetWorkspace.id,
                                  response: 'DECLINED',
                                },
                              },
                            }).then(() => refetchWorkspaces()),
                            {
                              loading: `Declining...`,
                              success: `Declined`,
                              error: `Error declining invitation`,
                            }
                          )
                        } catch (error) {
                          logger.warn(`Error declining invitation`)
                        }

                        setSavingResponse(false)
                      }}
                    >
                      Decline
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="info"
                      sx={{ mr: 1 }}
                      disabled={savingResponse}
                      onClick={async () => {
                        setSavingResponse(true)

                        try {
                          await toast.promise(
                            respondToInvite({
                              variables: {
                                input: {
                                  workspaceId: targetWorkspace.id,
                                  response: 'ACCEPTED',
                                },
                              },
                            }).then(() => refetchAll()),
                            {
                              loading: `Joining...`,
                              success: `Joined ${targetWorkspace.name}`,
                              error: `Error joining ${targetWorkspace.name}`,
                            }
                          )
                        } catch (error) {
                          logger.warn(`Error joining ${targetWorkspace.name}`)
                        }

                        setSavingResponse(false)
                      }}
                    >
                      Join
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <>
              <Typography
                variant="h3"
                sx={{ mb: 3 }}
              >
                Create a new workspace for your team
              </Typography>
              <Box>
                <TextField
                  value={createWorkspaceData?.name}
                  fullWidth={true}
                  onChange={(e) =>
                    setCreateWorkspaceData({
                      ...createWorkspaceData,
                      name: e.target.value,
                    })
                  }
                />
                <Box sx={{ mt: 2 }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={createWorkspaceData.domain || ''}
                      onChange={(e) =>
                        setCreateWorkspaceData({
                          ...createWorkspaceData,
                          domain: e.target.value,
                        })
                      }
                    >
                      {workspaces
                        ?.find((workspace) => workspace.isDefault)
                        ?.claimableDomains?.map((domain) => (
                          <FormControlLabel
                            key={domain}
                            value={domain}
                            control={<Radio />}
                            label={`Claim ${domain} for this workspace`}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  {createWorkspaceData.domain && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={createWorkspaceData.autoInvite || false}
                          onChange={(e) =>
                            setCreateWorkspaceData({
                              ...createWorkspaceData,
                              autoInvite: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Automatically invite users with this domain"
                    />
                  )}
                </Box>
              </Box>
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth={true}
                disableElevation={true}
                sx={{ mt: 3 }}
                disabled={savingResponse}
                onClick={async () => {
                  setSavingResponse(true)

                  try {
                    await toast.promise(
                      createWorkspace({
                        variables: {
                          input: {
                            name: createWorkspaceData.name,
                            claimDomains: [
                              {
                                domain: createWorkspaceData.domain,
                                autoInvite: createWorkspaceData.autoInvite,
                              },
                            ],
                          },
                        },
                      }).then(() => refetchAll()),
                      {
                        loading: `Creating workspace...`,
                        success: `Created workspace`,
                        error: `Error creating workspace`,
                      }
                    )
                  } catch (error) {
                    logger.warn(`Error creating workspace`)
                    setSavingResponse(false)
                  }

                  setSavingResponse(false)
                }}
              >
                Create workspace
              </Button>
            </>
          )}
        </Box>
      ),
    },
    {
      label: 'Data Sharing',
      description:
        'Share the emails & meetings with your team that you allow, so you can collaborate on your relationships.',
      title: 'Share your email & meetings',
      image: null,
      component: () => (
        <Box>
          <Typography
            variant="h2"
            sx={{ textAlign: 'center', mb: 1 }}
          >
            Email & meeting sharing
          </Typography>
          {/* There should only be one WorkAccount at this point, but we're iterating over them to be safe */}
          {workAccounts.map((workAccount, index) => (
            <SharingRules
              key={`sharingRules-${index}`}
              workAccount={workAccount}
              workspace={targetWorkspace}
              callback={refetchAll}
            />
          ))}
        </Box>
      ),
    },
    {
      label: 'Meeting Recording',
      description:
        'Day.ai is your new meeting recorder, notetaker and follow-up email writer.',
      title: 'Choose your recording settings',
      image: null,
      component: () => (
        <Row
          sx={{
            height: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h2"
            sx={{ textAlign: 'center', mb: 3 }}
          >
            Recording settings
          </Typography>
          {workAccounts.map((workAccount) => (
            <>
              {workAccount.calendarAutoRecordSettings.mode === 'NONE' && (
                <Box
                  key={`meeting-recording-${workAccount.id}`}
                  sx={{ height: '212px' }}
                >
                  <OnboardingCalendarRecordingSettings
                    workAccount={workAccount}
                    refetch={refetchWorkAccounts}
                  />
                </Box>
              )}
            </>
          ))}
        </Row>
      ),
    },
    {
      label: 'Complete',
      description:
        "Day.ai is at your service. You're ready to go about your business as usual, and you will start to hear from Day.ai as we process your conversations into CRM data. Also as you record meetings, Day.ai will write you follow-up emails and keep track of your relationships, sending you notes and next steps along the way.",
      title: `Nice to meet you ${userCoreContact?.firstName}!`,
      image: null,
      component: () => (
        <Box>
          <Typography
            sx={{
              mt: 2,
              fontWeight: 600,
              fontSize: '0.8rem',
              textAlign: 'center',
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            Your Day.ai Assistant will ...
          </Typography>
          <Divider sx={{ my: 1 }} />
          <List
            sx={{
              mb: 4,
              '& .MuiListItem-root': {
                py: 2,
              },
              '& .MuiListItemText-root p': {
                fontWeight: 400,
                fontSize: '0.9rem',
              },
            }}
          >
            <ListItem>
              <ListItemIcon>
                <EventNote />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Join the meetings you've configured on an ongoing basis,
                  record them and send you the notes.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MarkEmailRead />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Write you follow-up emails for your meetings, which will
                  appear magically in your Gmail Drafts.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <RecentActors />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Build an entire automatically-updated CRM for you and your
                  team to manage all your deal-based business.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Button
            onClick={() => {
              navigate(routes.today())
            }}
            size="large"
            variant="contained"
            color="primary"
            fullWidth={true}
          >
            Let's go!
          </Button>
        </Box>
      ),
    },
  ]

  return (
    activeStep != null && (
      <>
        <Metadata
          title="Welcome to Day.ai"
          description="Welcome to Day.ai"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            maxHeight: 'calc(100vh)',
            width: '90vw',
            m: 'auto',
            p: 0,
          }}
        >
          <Box sx={{ maxWidth: '360px', mr: 3 }}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
            >
              {onboardingSteps.map((step, index) => {
                return (
                  <Step key={`onboardingStep_${index}`}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.9rem',
                        }}
                      >
                        {onboardingSteps[activeStep].description}
                      </Typography>
                    </StepContent>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
          <Card sx={{ width: '620px', minHeight: '480px' }}>
            <Box
              sx={{
                height: '100%',
                minHeight: '480px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
              }}
            >
              {loading ? (
                <Box sx={{ height: '48px' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>{onboardingSteps[activeStep].component()}</>
              )}
            </Box>
          </Card>
        </Box>
      </>
    )
  )
}

const WelcomePage = () => {
  return (
    <DayProvider>
      <WelcomePageInner />
    </DayProvider>
  )
}

export default WelcomePage
