import { useContext, useEffect, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  RiArrowDownSLine,
  RiNodeTree,
  RiSparklingFill,
  RiStickyNoteAddFill,
  RiStickyNoteAddLine,
  RiStickyNoteLine,
} from '@remixicon/react'
import toast from 'react-hot-toast'
import { getDomain } from 'tldts'
import { Opportunity } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { tableButtonSx } from 'src/components/Prospects/prospects'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import {
  buildOrgPageBlocks,
  buildTimelineItems,
  getNextStepMetadata,
  getOrgPeople,
  orgBlockMetadata,
} from '../organizations'

const GET_ORGANIZATION_FOR_PAGE_CREATE = gql`
  query GetOrganizationForPageCreate($workspaceId: String!, $orgId: String!) {
    organizationForPageCreate(id: $orgId, workspaceId: $workspaceId) {
      organization {
        name
        domain
        subCompanyTag
        description
        aiDescription
        promises
        naicsCodes
        sicCodes
        industry
        employeeCount
        annualRevenue
        funding
        address
        city
        state
        country
        postalCode
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorDarkMuted
        colorLightMuted
        photoSquare
        photoIcon
        photoBanner
        stockTicker
        socialTwitter
        socialLinkedIn
        socialFacebook
        socialYouTube
        markdown
        createdAt
        updatedAt
        edgarCik
        crunchbaseEntityId
        similarDomains
        resolvedUrl
        overview
      }
      actionContent
    }
  }
`

const CREATE_PAGE_MUTATION_FOR_ORG_PAGE = gql`
  mutation CreatePageForOrgPageMutation($input: CreatePageInput!) {
    createPage(input: $input) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
    }
  }
`

const CREATE_NEXT_STEPS_PAGE_TEXT = gql`
  mutation CreateNextStepsPageText($input: CreateNextStepsPageInput!) {
    createNextStepsPageText(input: $input) {
      contentJson
    }
  }
`

const showManual = false

const OrganizationPageCreate = ({
  component,
  orgId,
  suggestedActionKey,
  opportunity,
}: {
  component?: React.ReactElement
  orgId: string
  suggestedActionKey?: string
  opportunity?: Opportunity
}) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace, people, setSidebarObject } = useContext(DayContext)
  const [open, setOpen] = useState(false)

  const [selectedNextStepPageTemplate, setSelectedNextStepPageTemplate] =
    useState(null)

  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [useModel, setUseModel] = useState(true)

  const [explanationExpanded, setExplanationExpanded] = useState(false)

  const [pageTitle, setPageTitle] = useState('')
  const domain = getDomain(`http://${orgId}`)
  const { data, loading } = useQuery(GET_ORGANIZATION_FOR_PAGE_CREATE, {
    variables: {
      workspaceId: selectedWorkspace,
      orgId: domain,
    },
    skip: !selectedWorkspace || !domain,
  })

  const [createPage] = useMutation(CREATE_PAGE_MUTATION_FOR_ORG_PAGE)
  const [createNextStepsPageText] = useMutation(CREATE_NEXT_STEPS_PAGE_TEXT)

  const org = data?.organizationForPageCreate.organization
  const timelineItems = buildTimelineItems(
    data?.organizationForPageCreate.history || []
  )
  const nextStep = org
    ? getNextStepMetadata(
        org,
        data?.organizationForPageCreate.actionContent,
        opportunity
      )
    : null

  if (!selectedNextStepPageTemplate && nextStep?.label) {
    setSelectedNextStepPageTemplate(nextStep)
    setSelectedBlocks(nextStep.blockOrder)
  }

  useEffect(() => {
    if (!pageTitle && selectedNextStepPageTemplate?.label) {
      setPageTitle(
        selectedNextStepPageTemplate?.label
          ? `${selectedNextStepPageTemplate?.label} - ${org?.name}`
          : org?.name
      )
    }
  }, [selectedNextStepPageTemplate])

  useEffect(() => {
    if (suggestedActionKey) {
      handleTemplateChange({ target: { value: suggestedActionKey } })
      setOpen(true)
    }
  }, [suggestedActionKey])

  const handleOpen = () => {
    if (!selectedNextStepPageTemplate) {
      handleTemplateChange({ target: { value: 'outreachEmail' } })
    }
    setOpen(true)
  }

  const handleTemplateChange = (event) => {
    const templateKey = event.target.value
    logger.dev({ templateKey })
    const selectedTemplate = Object.values(
      data?.organizationForPageCreate.actionContent || {}
    )
      .flatMap((group) => group.templates)
      .find((template) => template.key === templateKey)
    setSelectedNextStepPageTemplate(selectedTemplate)
    setSelectedBlocks(selectedTemplate?.blockOrder || [])
    setPageTitle(
      selectedTemplate?.label
        ? `${selectedTemplate?.label} - ${org?.name}`
        : org?.name
    )
  }

  const handleBlockToggle = (blockKey) => {
    setSelectedBlocks((prevBlocks) => {
      if (prevBlocks.includes(blockKey)) {
        return prevBlocks.filter((key) => key !== blockKey)
      } else {
        return [...prevBlocks, blockKey]
      }
    })
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleCreatePage = async () => {
    let modelContentJson

    const toastId = toast.loading(
      <Box>
        <Typography>Doing some gruntwork for you ...</Typography>
      </Box>
    )

    const input = {
      key: suggestedActionKey || selectedNextStepPageTemplate?.key,
      orgId,
      workspaceId: selectedWorkspace,
    }

    try {
      const content = await createNextStepsPageText({
        variables: {
          input,
        },
      })

      modelContentJson =
        content?.data?.createNextStepsPageText?.contentJson?.content
      toast.success('Gruntwork complete!', { id: toastId })
    } catch (error) {
      toast.error('Failed to create page', { id: toastId })
      return
    }

    const content = buildOrgPageBlocks({
      org,
      timelineItems,
      orgPeople: getOrgPeople({ domain: org.domain, people }),
      summaryItems: org?.overview?.['status/highLevelSummary'] || [],
      challenges: org?.overview?.['objective/challengesAndSolutions'] || [],
      actions: org?.overview?.['status/nextSteps']?.immediateNextSteps || [],
      quotes: org?.overview?.['quotes'] || [],
      prompt: modelContentJson,
      order: selectedBlocks?.length > 0 ? selectedBlocks : null,
    })

    let titleBlock = null
    if (
      content.length > 0 &&
      content[0].type === 'heading' &&
      content[0].attrs.level === 1
    ) {
      titleBlock = content.shift()
    }

    const page = await toast.promise(
      createPage({
        variables: {
          input: {
            workspaceId: selectedWorkspace,
            ownerEmail: currentUser.email,
            domains: [org.domain],
            title: titleBlock ? titleBlock.content[0].text : pageTitle,
            publishedForUserAt: new Date().toISOString(),
            contentJson: {
              type: 'doc',
              content: [
                {
                  type: 'title',
                  attrs: {
                    level: 1,
                  },
                  content: [
                    {
                      text: titleBlock ? titleBlock.content[0].text : pageTitle,
                      type: 'text',
                    },
                  ],
                },
                ...content,
              ],
            },
            contentHtml: '',
          },
        },
      }),
      {
        loading: 'Creating page...',
        success: () => {
          setSidebarObject(null)
          return 'Page created successfully!'
        },
        error: 'Failed to create page',
      }
    )
    setOpen(false)
    navigate(routes.pageDetail({ id: page.data.createPage.id }))
  }

  return (
    domain && (
      <>
        {component ? (
          React.cloneElement(component, {
            onClick: handleOpen,
            label:
              selectedNextStepPageTemplate?.label ||
              component.props.label ||
              'Create page',
          })
        ) : (
          <>
            <Button
              className="next-steps-button"
              size="small"
              variant="outlined"
              disableElevation={true}
              startIcon={<RiStickyNoteLine size={16} />}
              sx={{
                ...tableButtonSx,
                borderTopRightRadius: '0px !important',
                borderBottomRightRadius: '0px !important',
              }}
              onClick={handleOpen}
            >
              {selectedNextStepPageTemplate?.label || ''}
            </Button>
          </>
        )}
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle>
            <Row sx={{ mb: 2 }}>
              <Typography
                variant="h4"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontWeight: 400,
                }}
              >{`Create `}</Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  mx: '3px',
                  color: (theme) => theme.palette.text.primary,
                }}
              >{`${selectedNextStepPageTemplate?.label}`}</Typography>
              {org?.name && (
                <Typography
                  variant="h4"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    fontWeight: 400,
                  }}
                >{` page for ${org?.name}`}</Typography>
              )}
            </Row>
          </DialogTitle>
          <DialogContent
            sx={{
              '& .MuiAccordion-root': {
                border: '1px solid #e0e0e0',
                boxShadow: 'none',
                mb: 2,
              },
              '& .pageCustomValue': {
                fontWeight: 400,
                fontStyle: 'italic',
                pr: 2,
                opacity: 1,
                transition: 'opacity 0.18s ease-in-out',
              },
              '& .Mui-expanded .pageCustomValue': {
                opacity: 0,
              },
            }}
          >
            {!useModel && (
              <Accordion>
                <AccordionSummary expandIcon={<RiArrowDownSLine size={20} />}>
                  <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                    <Typography sx={{ fontWeight: 500 }}>Page Title</Typography>
                    <Typography className="pageCustomValue">{`${pageTitle}`}</Typography>
                  </Row>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Page Name"
                    fullWidth={true}
                    variant="outlined"
                    value={pageTitle}
                    onChange={(e) => setPageTitle(e.target.value)}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion>
              <AccordionSummary expandIcon={<RiArrowDownSLine size={20} />}>
                <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                  <Typography sx={{ fontWeight: 500 }}>Purpose</Typography>
                  <Typography className="pageCustomValue">{`${selectedNextStepPageTemplate?.label}`}</Typography>
                </Row>
              </AccordionSummary>
              <AccordionDetails>
                {data?.organizationForPageCreate.actionContent &&
                  selectedNextStepPageTemplate?.key && (
                    <FormControl
                      fullWidth={true}
                      sx={{ mb: 2 }}
                    >
                      <InputLabel id="template-select-label">
                        Template
                      </InputLabel>
                      <Select
                        labelId="template-select-label"
                        id="template-select"
                        value={selectedNextStepPageTemplate.key}
                        onChange={handleTemplateChange}
                        label="Template"
                        renderValue={() => {
                          return (
                            <Typography sx={{ fontWeight: 500 }}>
                              {selectedNextStepPageTemplate.label}
                            </Typography>
                          )
                        }}
                      >
                        {Object.entries(
                          data?.organizationForPageCreate.actionContent || {}
                        ).map(([groupKey, groupValue]) => [
                          ...groupValue.templates.map((template, index) => (
                            <MenuItem
                              key={`${groupKey}-${index}`}
                              value={template.key}
                              sx={{ py: '12px' }}
                              selected={
                                selectedNextStepPageTemplate.key ===
                                template.key
                              }
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  sx: {
                                    fontSize: '0.9rem',
                                    fontWeight: 500,
                                    letterSpacing: '-0.8px',
                                    lineHeight: '16px',
                                  },
                                }}
                                secondaryTypographyProps={{
                                  sx: {
                                    fontSize: '0.7rem',
                                    fontWeight: 400,
                                    letterSpacing: '-0.8px',
                                    lineHeight: '16px',
                                  },
                                }}
                                primary={template.label}
                                secondary={groupValue.label}
                              />
                              <ListItemIcon
                                sx={{ minWidth: '24px !important' }}
                              >
                                {selectedNextStepPageTemplate.key ===
                                template.key ? (
                                  <RiStickyNoteAddFill size={20} />
                                ) : (
                                  <RiStickyNoteAddLine size={20} />
                                )}
                              </ListItemIcon>
                            </MenuItem>
                          )),
                        ])}
                      </Select>
                    </FormControl>
                  )}
                {suggestedActionKey &&
                  org?.overview?.['status/recommendedStage'] && (
                    <Alert
                      severity="info"
                      icon={<RiSparklingFill size={18} />}
                      sx={{ mb: 2 }}
                      action={
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() =>
                            setExplanationExpanded((prev) => !prev)
                          }
                          sx={{
                            py: '2px',
                            fontSize: '0.7rem',
                          }}
                        >
                          {explanationExpanded ? 'Less' : 'More'}
                        </Button>
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: '0.8rem',
                          lineHeight: explanationExpanded ? 'inherit' : '120%',
                          whiteSpace: explanationExpanded ? 'normal' : 'nowrap',
                          overflow: 'hidden',
                          textOverflow: explanationExpanded
                            ? 'clip'
                            : 'ellipsis',
                          transition: 'all 0.3s ease-in-out',
                        }}
                      >
                        {`Day.ai Assistant suggests using the "${
                          selectedNextStepPageTemplate?.label
                        }" template for this page because it seems to be in the "${
                          data?.organizationForPageCreate.actionContent[
                            org?.overview?.['status/recommendedStage']?.stage
                          ]?.label
                        }" stage of progression.

                ${org?.overview['status/recommendedStage']?.reasoningForStage}
                `}
                      </Typography>
                    </Alert>
                  )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<RiArrowDownSLine size={20} />}>
                <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Content Included
                  </Typography>
                  <Typography className="pageCustomValue">{`${selectedBlocks.length} blocks`}</Typography>
                </Row>
              </AccordionSummary>
              <AccordionDetails>
                {selectedBlocks && (
                  <>
                    {' '}
                    <Typography
                      sx={{ mb: 1, fontSize: '0.9rem', fontWeight: 500 }}
                    >
                      Select Page Blocks
                    </Typography>
                    <Typography sx={{ mb: 2, fontSize: '0.7rem' }}>
                      Choose the blocks you want to include in your page. You
                      can edit & reorder them once the Page is created.
                    </Typography>
                    <FormGroup>
                      <Grid
                        container
                        spacing={2}
                      >
                        {Object.entries(orgBlockMetadata).map(
                          ([key, block]) => (
                            <Grid
                              item
                              xs={6}
                              key={key}
                            >
                              {block?.label && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedBlocks.includes(key)}
                                      onChange={() => handleBlockToggle(key)}
                                      disabled={useModel && key === 'prompt'}
                                    />
                                  }
                                  label={
                                    useModel && key === 'prompt'
                                      ? "Day.ai Assistant's work"
                                      : block.label
                                  }
                                />
                              )}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </FormGroup>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                sx={{
                  '& .remixicon': {
                    color: (theme) => theme.palette.action.disabled,
                  },
                }}
                expandIcon={<RiArrowDownSLine size={20} />}
              >
                <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: (theme) => theme.palette.action.disabled,
                    }}
                  >
                    Folder
                  </Typography>
                  <Typography
                    className="pageCustomValue"
                    sx={{
                      color: (theme) => theme.palette.action.disabled,
                    }}
                  >
                    {org?.name || org?.domain || ''}
                  </Typography>
                </Row>
              </AccordionSummary>
              <AccordionDetails>
                <Alert
                  sx={{ fontSize: '0.8rem', mb: 1, mt: -1 }}
                  icon={<RiNodeTree size={18} />}
                >
                  This new page will live in the {org?.name || 'company name'}{' '}
                  folder in your left-hand navigation. You can drag it elsewhere
                  if you like once it has been created.
                </Alert>
              </AccordionDetails>
            </Accordion>

            {showManual && (
              <Row sx={{ justifyContent: 'space-between' }}>
                {[true, false].map((action, index) => (
                  <Row
                    key={`${index}-${action}`}
                    sx={{
                      height: '64px',
                      border: (theme) =>
                        `1px solid ${
                          useModel === action
                            ? theme.palette.secondary.main
                            : theme.palette.divider
                        }`,
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      flexGrow: 0,
                      flexShrink: 0,
                      width: 'calc(50% - 8px)',
                      color: (theme) =>
                        useModel === action
                          ? theme.palette.secondary.dark
                          : theme.palette.text.primary,
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.action.hover,
                      },
                    }}
                    onClick={() => setUseModel(action)}
                  >
                    <Checkbox
                      checked={useModel === action}
                      sx={{
                        width: '24px',
                        height: '24px',
                        '& .MuiSvgIcon-root': {
                          color: (theme) =>
                            useModel === action
                              ? theme.palette.secondary.main
                              : theme.palette.text.primary,
                        },
                      }}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>
                        {action
                          ? 'Day.ai does some work for you'
                          : 'Do it yourself'}
                      </Typography>
                      <Typography sx={{ fontSize: '0.7rem' }}>
                        {action
                          ? 'Takes about 30 seconds'
                          : 'Page available right away'}
                      </Typography>
                    </Box>
                  </Row>
                ))}
              </Row>
            )}
          </DialogContent>
          <DialogActions sx={{ p: 3, pt: 0 }}>
            <Button
              fullWidth={true}
              startIcon={
                loading ? (
                  <RiSparklingFill size={14} />
                ) : (
                  <RiStickyNoteAddLine size={16} />
                )
              }
              variant="contained"
              color="secondary"
              onClick={handleCreatePage}
              disabled={loading}
              disableElevation={true}
            >
              {loading ? 'Researching...' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

export default OrganizationPageCreate
