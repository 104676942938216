import { useContext, useState } from 'react'

import { Avatar, Box, CircularProgress, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { NativeObjectFormatters } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import ContactAvatar from '../ContactAvatar/ContactAvatar'

const GET_CONTACT_BY_EMAIL = gql`
  query GetContactByEmail($contactEmail: String!, $ownerEmail: String!) {
    getContactByEmail(contactEmail: $contactEmail, ownerEmail: $ownerEmail) {
      objectId
      objectType
      properties
    }
  }
`

const ContactTile = ({
  email,
  displayName = '',
  crmObject = null,
  size = 32,
  showSidebar = true,
  missingInfoExplanation = 'No other contact info available',
  subtitle = true,
}: {
  email: string
  displayName?: string
  crmObject?: any
  size?: number
  showSidebar?: boolean
  missingInfoExplanation?: string
  subtitle?: boolean | string
}) => {
  const { setSidebarObject: setSidebar } = useContext(DayContext)
  const { currentUser: user } = useAuth()

  const [contact, setContact] = useState(crmObject || null)
  const contactEmail = email
  const ownerEmail = user?.email

  useQuery(GET_CONTACT_BY_EMAIL, {
    variables: { contactEmail, ownerEmail },
    onCompleted: ({ getContactByEmail }) => {
      setContact(getContactByEmail)
    },
  })

  return contact ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        cursor: email && showSidebar ? 'pointer' : 'inherit',
      }}
      onClick={() => {
        if (showSidebar) setSidebar(contact)
      }}
    >
      {email ? (
        <ContactAvatar
          email={email}
          size={size}
          borderRadius={100}
        />
      ) : (
        <Avatar sx={{ width: `${size}px`, height: `${size}px` }} />
      )}
      <Box
        sx={{
          ml: size > 30 ? 2 : 1,
          width: `calc(100% - ${size}px - ${size > 30 ? 16 : 8}px)`,
        }}
      >
        <Typography
          sx={{
            width: '100%',
            fontWeight: 600,
            fontSize: size > 30 ? '0.8rem' : '0.7rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {contact?.objectId
            ? NativeObjectFormatters[NativeObjectTypes.Contact].label(contact)
            : displayName}
        </Typography>
        {subtitle && (contact?.objectId || missingInfoExplanation) && (
          <Typography
            sx={{
              width: '100%',
              fontWeight: 400,
              fontSize: size > 30 ? '0.6rem' : '0.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {typeof subtitle === 'string'
              ? subtitle
              : contact?.objectId
              ? NativeObjectFormatters[NativeObjectTypes.Contact].subtitle(
                  contact
                )
              : missingInfoExplanation}
          </Typography>
        )}
      </Box>
    </Box>
  ) : (
    <CircularProgress
      color="secondary"
      size={`calc(${size}px - 4px)`}
    />
  )
}

export default ContactTile
