import { Box, styled } from '@mui/system'
import LogoLoader2 from '../LogoLoader2/LogoLoader2'

const ThrobbingLogo = styled(LogoLoader2)(({ color }) => ({
  height: '64px',
  color: color || 'lightgray',
  animation: 'throb 4s infinite ease-out',
  '@keyframes throb': {
    '0%': {
      transform: 'scale(.55)',
    },
    '70%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(.55)',
    },
  },
}))

const LogoLoader = ({ color }: { color?: string }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ThrobbingLogo color={color} />
    </Box>
  )
}

export default LogoLoader
