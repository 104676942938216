// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set, Private } from '@redwoodjs/router'

import { useAuth } from './auth'
import ModernLayout from './layouts/ModernLayout/ModernLayout'
import OnboardingLayout from './layouts/OnboardingLayout/OnboardingLayout'
import PublicSiteLayout from './layouts/PublicSiteLayout/PublicSiteLayout'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route
        path="/"
        page={HomepageSpringLaunch2024Page}
        name="homepageSpringLaunch2024"
      />
      <Route
        path="/airplane-mode"
        page={AirplaneModePage}
        name="airplaneMode"
      />
      <Set wrap={PublicSiteLayout}>
        <Route
          path="/sequoia-backs-ai-native-crm"
          page={FundingAnnouncementPage}
          name="funding"
        />
        <Route
          path="/pricing"
          page={PricingPage}
          name="pricing"
        />
        <Route
          path="/terms"
          page={PublicSiteTermsPage}
          name="terms"
        />
        <Route
          path="/privacy"
          page={PublicSitePrivacyPage}
          name="privacy"
        />
        <Route
          path="/support"
          page={PublicSiteSupportPage}
          name="support"
        />
        <Route
          path="/zoom"
          page={PublicSiteZoomHelpPage}
          name="zoomHelp"
        />
        <Route
          path="/data"
          page={PublicSiteDataRetentionPage}
          name="dataRetention"
        />
      </Set>
      <Private unauthenticated="login">
        <Set wrap={OnboardingLayout}>
          <Route
            path="/finish-login"
            page={FinishLoginPage}
            name="finishLogin"
          />
          <Route
            path="/welcome"
            page={WelcomePage}
            name="welcome"
          />
          <Route
            path="/add-work-account"
            page={AddWorkAccountPage}
            name="addWorkAccount"
          />
        </Set>
        <Set wrap={ModernLayout}>
          <Route
            path="/data/debug"
            page={DataPage}
            name="data"
          />
          <Route
            path="/admin"
            page={AdminPage}
            name="admin"
          />
          <Route
            path="/admin/{email}/edit"
            page={DayAdminUserPage}
            name="dayAdminUser"
          />
          <Route
            path="/admin/contacts/{email}/edit"
            page={AdminCoreContactPage}
            name="adminCoreContact"
          />
          <Route
            path="/admin/prospects/{workspaceId}"
            page={AdminProspectsPage}
            name="adminProspects"
          />
          <Route
            path="/admin/orgs/{workspaceId}"
            page={AdminOrganizationsPage}
            name="adminOrganizations"
          />

          <Route
            path="/admin/slack/{workspaceId}"
            page={AdminSlackPage}
            name="adminSlack"
          />
          <Route
            path="/admin/stats"
            page={AdminEnrichmentStatsPage}
            name="adminEnrichmentStats"
          />
          <Route
            path="/test"
            page={TestPage}
            name="test"
          />
          <Route
            path="/add-zoom-account"
            page={AddZoomAccountPage}
            name="addZoomAccount"
          />
          <Route
            path="/add-linkedin-account"
            page={AddLinkedInAccountPage}
            name="addLinkedInAccount"
          />
          <Route
            path="/search"
            page={SearchPage}
            name="search"
          />
          <Route
            path="/today"
            page={TodayPage}
            name="today"
          />
          <Route
            path="/library"
            page={LibraryPage}
            name="library"
          />
          <Route
            path="/workspace/{workspaceId:String}/library"
            page={WorkspaceLibraryPage}
            name="workspaceLibrary"
          />
          <Route
            path="/review/{id}"
            page={ReviewMeetingPage}
            name="reviewMeeting"
          />
          <Route
            path="/workspace/{workspaceId:String}/recordings/{recordingId:String}"
            page={WorkspaceReviewMeetingPage}
            name="workspaceMeetingRecording"
          />
          <Route
            path="/recording/{id}/audit"
            page={RecordingAuditPage}
            name="recordingAudit"
          />
          <Route
            path="/workspace/{workspaceId:String}/recordings/{recordingId:String}/audit"
            page={WorkspaceAuditPage}
            name="workspaceMeetingRecordingAudit"
          />
          <Route
            path="/pages"
            page={PagesPagesPage}
            name="pages"
          />
          <Route
            path="/page/{id}/audit"
            page={PageAuditPage}
            name="pageAudit"
          />
          <Route
            path="/page/{id}"
            page={PagesPageDetailPage}
            name="pageDetail"
          />
          <Route
            path="/pipelines"
            page={PipelinesPage}
            name="pipelines"
          />
          <Route
            path="/prospects"
            page={ProspectsPage}
            name="prospects"
          />
          <Route
            path="/opportunities"
            page={OpportunitiesPage}
            name="opportunities"
          />
          <Route
            path="/{workspaceId}/{objectTypeSlug}/{objectId}/view"
            page={ObjectDetailPage}
            name="objectDetail"
          />
          <Route
            path="/clips"
            page={ClipsPage}
            name="clips"
          />
          <Route
            path="/workspaces/sharing"
            page={WorkspaceSharingPage}
            name="workspaceSharing"
          />
          <Route
            path="/settings/{id:String}"
            page={SettingsPage}
            name="settings"
          />
          <Route
            path="/people"
            page={PeoplePage}
            name="people"
          />
          <Route
            path="/organizations"
            page={OrganizationsPage}
            name="organizations"
          />
          <Route
            path="/pipeline"
            page={PipelinePage}
            name="pipeline"
          />

          <Route
            path="/workspace/create"
            page={WorkspaceAdminWorkspaceCreatePage}
            name="workspaceCreate"
          />
          <Route
            path="/workspace/{id:String}/settings"
            page={WorkspaceAdminWorkspaceSettingsPage}
            name="workspaceSettings"
          />

          <Route
            path="/core-companies/{domain}"
            page={CoreCompanyCoreCompanyPage}
            name="coreCompany"
          />
          <Route
            path="/core-companies"
            page={CoreCompanyCoreCompaniesPage}
            name="coreCompanies"
          />
          <Route
            path="/core-contacts/new"
            page={CoreContactNewCoreContactPage}
            name="newCoreContact"
          />

          <Route
            path="/core-companies/new"
            page={CoreCompanyNewCoreCompanyPage}
            name="newCoreCompany"
          />
          <Route
            path="/core-companies/{domain}/edit"
            page={CoreCompanyEditCoreCompanyPage}
            name="editCoreCompany"
          />
          <Route
            path="/core-contacts/{email:String}/edit"
            page={CoreContactEditCoreContactPage}
            name="editCoreContact"
          />
          <Route
            path="/core-contacts"
            page={CoreContactCoreContactsPage}
            name="coreContacts"
          />
          <Route
            path="/core-contacts-verified"
            page={CoreContactsVerifiedStatsPage}
            name="coreContactsVerifiedStats"
          />
          <Route
            path="/core-contacts/{email}/match"
            page={CoreContactAlmostVerifiedPage}
            name="coreContactAlmostVerified"
          />
          <Route
            path="/test-blocks"
            page={TestBlockRenderingPage}
            name="testBlockRendering"
          />
          <Route
            path="/revoke-alert"
            page={RevokeAlertPage}
            name="revokeAlert"
          />
        </Set>
      </Private>
      <Route
        path="/homepageWinter2024"
        page={PublicSiteHomepageWinter2024Page}
        name="homepageWinter2024"
      />
      <Route
        path="/login"
        page={LoginPage}
        name="login"
      />
      <Route
        path="/p/{shortLinkHash}"
        page={PagesPageShortLinkPage}
        name="pageShortLink"
      />
      <Route
        path="/page/{id}/share"
        page={PagePublicPage}
        name="pagePublic"
      />
      <Route
        path="/share/clip/{id}"
        page={ShareClipPage}
        name="shareClip"
      />
      <Route
        notfound
        page={NotFoundPage}
      />
    </Router>
  )
}

export default Routes
