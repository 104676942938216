import { useContext } from 'react'

import { Chip, darken, lighten } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { isFreemailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import DomainAvatar from '../../DomainAvatar/DomainAvatar'

// get coreCompany from domain
const GET_CORE_COMPANY_ORG_CHIP = gql`
  query GetCoreCompanyOrgChip($domain: String!) {
    coreCompany(domain: $domain) {
      id
      name
      domain
      description
      aiDescription
      promises
      industry
      employeeCount
      annualRevenue
      funding
      address
      city
      state
      country
      postalCode
      colorVibrant
      colorDarkVibrant
      colorLightVibrant
      colorMuted
      colorDarkMuted
      colorLightMuted
      photoSquare
      photoIcon
      photoBanner
      stockTicker
      socialTwitter
      socialLinkedIn
      socialFacebook
      socialYouTube
      createdAt
      updatedAt
      edgarCik
      crunchbaseEntityId
      similarDomains
    }
  }
`

const OrganizationChip = ({
  domain,
  name = null,
  variant = 'outlined',
  size = 'medium',
  sx = {},
}: {
  domain: string
  name?: string
  variant?: 'filled' | 'outlined'
  size?: 'medium' | 'small'
  sx?: any
}) => {
  const isFreemail = isFreemailDomain(domain)

  const { setSidebarObject } = useContext(DayContext)
  const { data } = useQuery(GET_CORE_COMPANY_ORG_CHIP, {
    variables: { domain },
    skip: isFreemail || !domain,
  })

  const iconSize = size === 'small' ? 16 : 20

  return (
    !isFreemail &&
    domain && (
      <Chip
        variant={variant}
        icon={
          <DomainAvatar
            domain={domain}
            photoUrl={data ? data?.coreCompany?.photoSquare : null}
            size={iconSize}
            companyColor={data ? data?.coreCompany?.colorVibrant : null}
            borderRadius={50}
            sx={{
              mr: '-4px',
            }}
          />
        }
        label={data?.coreCompany?.name || name || domain}
        clickable={true}
        size={size}
        onClick={(e) => {
          e.stopPropagation()
          setSidebarObject({
            objectType: NativeObjectTypes.Organization,
            objectId: domain,
            properties: data?.coreCompany,
          })
        }}
        sx={{
          px: '4px',
          justifyContent: 'start',
          borderRadius: '50px',
          background: 'transparent',
          fontSize: size === 'small' ? '0.75rem' : '0.8rem',
          '&:hover': {
            background: (theme) =>
              `${
                data?.coreCompany?.colorVibrant
                  ? lighten(data?.coreCompany?.colorVibrant, 0.8)
                  : theme.palette.secondary.light
              } !important`,
            color: (theme) =>
              data?.coreCompany?.colorVibrant
                ? darken(data?.coreCompany?.colorVibrant, 0.4)
                : theme.palette.secondary.contrastText,
          },
          ...sx,
        }}
      />
    )
  )
}

export default OrganizationChip
