export const GlobalWorkspace = 'global'

export const NativeObjectTypes = {
  Contact: 'native_contact',
  Organization: 'native_organization',
  Opportunity: 'native_opportunity',
  Pipeline: 'native_pipeline',
  Stage: 'native_stage',
  MeetingRecording: 'native_meetingrecording',
  MeetingRecordingClip: 'native_meetingrecordingclip',
  Page: 'native_page',
  Action: 'native_action',
} as const

export const PropertyTypes = {
  Address: 'address',
  Calculated: 'calculated',
  Combobox: 'combobox',
  Currency: 'currency',
  Email: 'email',
  MultiPicklist: 'multipicklist',
  Percent: 'percent',
  Picklist: 'picklist',
  Phone: 'phone',
  ObjectReference: 'reference',
  TextArea: 'textarea',
  Url: 'url',
  DateTime: 'datetime',
} as const

export const ObjectAccessLevels = {
  Owner: 'owner',
  FullAccess: 'fullAccess',
  Editor: 'editor',
  Collaborator: 'collaborator',
  Viewer: 'viewer',
} as const
