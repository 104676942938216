import { useContext, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import toast from 'react-hot-toast'

import { useMutation } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const CREATE_NOTE_FOR_OPPORTUNITY = gql`
  mutation CreateNoteForOpportunity($input: CreateNoteForOpportunityInput!) {
    createNoteForOpportunity(input: $input) {
      id
    }
  }
`

const CREATE_NOTE_FOR_PERSON = gql`
  mutation CreateNoteForPerson($input: CreateNoteForPersonInput!) {
    createNoteForPerson(input: $input) {
      id
    }
  }
`

const CREATE_NOTE_FOR_ORGANIZATION = gql`
  mutation CreateNoteForOrganization($input: CreateNoteForOrganizationInput!) {
    createNoteForOrganization(input: $input) {
      id
    }
  }
`

const WorkspaceUserContextDialog = ({
  onClose,
  title,
  opportunityId = null,
  organizationId = null,
  people = [],
}) => {
  const { selectedWorkspace } = useContext(DayContext)

  const [plainTextValue, setPlainTextValue] = useState('')

  const [createNoteForOpportunity] = useMutation(CREATE_NOTE_FOR_OPPORTUNITY)
  const [createNoteForPerson] = useMutation(CREATE_NOTE_FOR_PERSON)
  const [createNoteForOrganization] = useMutation(CREATE_NOTE_FOR_ORGANIZATION)

  const handleSave = () => {
    let createPromise
    if (opportunityId) {
      createPromise = createNoteForOpportunity({
        variables: {
          input: {
            opportunityId,
            organizationId,
            plainTextValue,
            workspaceId: selectedWorkspace,
          },
        },
      })
    } else if (organizationId) {
      createPromise = createNoteForOrganization({
        variables: {
          input: {
            organizationId,
            plainTextValue,
            workspaceId: selectedWorkspace,
          },
        },
      })
    } else if (people.length > 0) {
      createPromise = createNoteForPerson({
        variables: {
          input: {
            email: people[0],
            plainTextValue,
            workspaceId: selectedWorkspace,
          },
        },
      })
    }

    toast.promise(createPromise, {
      loading: 'Adding context...',
      success: () => {
        try {
          onClose()
        } catch (e) {
          logger.info('Failed to close dialog')
        }
        setPlainTextValue('')
        return 'Context added!'
      },
      error: 'Failed to add context',
    })
  }

  const handleClose = () => {
    setPlainTextValue('')
    onClose()
  }

  const open = !!opportunityId || !!organizationId || people.length > 0

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          value={plainTextValue}
          onChange={(e) => setPlainTextValue(e.target.value)}
          fullWidth={true}
          multiline={true}
          rows={10}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button
          fullWidth={true}
          disableElevation={true}
          onClick={handleSave}
          variant="outlined"
          color="secondary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WorkspaceUserContextDialog
